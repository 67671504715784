import React from "react";
import { useField, FieldArray, useFormikContext } from "formik";

const SelectedItems = ({ values, items, arrayHelpers }) => (
  <div className="influencer-form--search-container">
    {values &&
      values.map((value) => (
        <div className="influencer-form--search-select">
          {items.find((item) => item.id === value).name}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => arrayHelpers.remove(values.indexOf(value))}
          >
            x
          </span>
        </div>
      ))}
  </div>
);

const FindAndSelect = ({ ...props }) => {
  const [field, meta] = useField(props);
  const { values } = useFormikContext();

  return (
    <FieldArray
      name={props.name}
      render={(arrayHelpers) => (
        <div className="influencer-form--section-row">
          <SelectedItems
            values={values[props.name]}
            items={props.items}
            arrayHelpers={arrayHelpers}
          />
          <div className="influencer-form--search-container">
            <input
              type="text"
              onChange={(e) => props.handleSearchResult(e.target.value)}
              className="influencer-form--search"
            />
            <div className="influencer-form--search-list-container">
              {props.items
                .filter((item) =>
                  item.name
                    .toLowerCase()
                    .includes(props.searchResult.toLowerCase())
                )
                .map((item, index) => (
                  <label
                    key={index}
                    htmlFor={`${item}${index}`}
                    className="influencer-form--search-checkbox-container"
                  >
                    <span className="influencer-form--search-checkbox-label">
                      {item.name}
                    </span>
                    <input
                      {...field}
                      {...props}
                      id={index}
                      key={index}
                      type="checkbox"
                      value={item.id}
                      checked={values[props.name].includes(item.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          arrayHelpers.push(item.id);
                        } else {
                          arrayHelpers.remove(
                            values[props.name].indexOf(item.id)
                          );
                        }
                      }}
                    />
                    <span className="influencer-form--search-checkmark" />
                  </label>
                ))}
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default FindAndSelect;
