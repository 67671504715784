import React from "react";
import { Field, useFormikContext } from "formik";
import Plus from "icons/plus.svg";

const Upload = ({ form, field }) => {
  const handleChange = (e) => {
    const file = e.currentTarget.files[0];
    const reader = new FileReader();
    const imgTag = document.getElementById("media");
    imgTag.title = file.name;
    reader.onload = function (evt) {
      imgTag.src = evt.target.result;
      form.setFieldValue(field.name, {
        filename: file.name,
        data: evt.target.result,
      });
    };
    reader.readAsDataURL(file);
  };

  return <input type="file" onChange={(e) => handleChange(e)} />;
};

const UploadImage = ({ name, label, ...props }) => {
  const { values, errors } = useFormikContext();

  console.log(values?.media?.data)

  return (
    <div className="edit-post--upload-image-container">
      {values?.media ? (
        <div className="edit-post--image">
          <Field name={name} component={Upload} />
          <img id="media" src={values.media.data} />
        </div>
      ) : (
        <div className="edit-post--upload-image-inner-container">
          <img id="media" />
          <Field name={name} component={Upload} />
          <div className="edit-post--upload-image-icon">
            <img src={Plus} />
          </div>
          <p className="edit-post--upload-image-context">
            Drop your image here or <span>browse</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default UploadImage;
