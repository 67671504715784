import React, { useState } from "react";
import ReportGallery from "./ReportGallery";
import List from "icons/list.svg";
import Gallery from "icons/gallery.svg";
import { getMetricLabels } from "../../util";
/** Tables */
import TablePost from "./Table/TablePost";
import TableStories from "./Table/TableStories";
import TableIGTVReelPosts from "./Table/TableIGTVReelPosts";
import TableYoutubePosts from "./Table/TableYoutubePosts";

const Report = (props) => {
  const postsTypes = {
    instagramPosts: "list",
    instagramStories: "list",
    youtubePosts: "list",
    igtvPosts: "list",
    igReelPosts: "list",
    facebookPosts: "list",
    amplifiedFacebookPosts: "list",
    amplifiedInstagramPosts: "list",
    tiktokPosts: "list",
  };

  const [postsViewType, setPostsViewType] = useState(postsTypes);

  /** Change the view in gallery or list */
  const togglePostView = (postType, viewType) => {
    setPostsViewType({
      ...postsViewType,
      [postType]: viewType,
    });
  };

  /** Returns the table or the list of posts */
  const renderReport = (postType, posts) => {
    return postsViewType[postType] === "gallery" ? (
      <ReportGallery posts={posts} postType={postType} />
    ) : (
      // The table is unique for each Post Type
      getPostsData(postType, "table", posts)
    );
  };

  /** Those are the collapse rows of Post */
  const getSubRows = (options, post) => {
    if (!options || !post) return null;

    const subRows = [];
    options.forEach((option) => {
      if (post.hasOwnProperty(option) && post[option] > 0) {
        subRows.push({
          key: getMetricLabels(option),
          value: post[option],
        });
      }
    });

    return Object.keys(subRows).length > 0 ? subRows : null;
  };

  const getPostsData = (postType, field, posts) => {
    const options = {
      instagramPosts: {
        title: () => "Instagram Posts",
        table: () => <TablePost post={posts} />,
        render: () => renderPosts(postType, ["saves", "shares"]),
      },
      instagramStories: {
        title: () => "Instagram Stories",
        table: () => <TableStories posts={posts} />,
        render: () => renderStories(postType),
      },
      youtubePosts: {
        title: () => "YouTube Posts",
        table: () => <TableYoutubePosts post={posts} />,
        render: () => renderPosts(postType),
      },
      igtvPosts: {
        title: () => "Instagram TV Posts",
        table: () => <TableIGTVReelPosts post={posts} />,
        render: () => renderPosts(postType),
      },
      igReelPosts: {
        title: () => "Instagram Reels Posts",
        table: () => <TableIGTVReelPosts post={posts} />,
        render: () => renderPosts(postType),
      },
      facebookPosts: {
        title: () => "Facebook Posts",
        table: () => <TablePost post={posts} />,
        render: () =>
          renderPosts(postType, [
            "shares",
            "links_clicks",
            "photo_clicks",
            "other_clicks",
          ]),
      },
      amplifiedFacebookPosts: {
        title: () => "Amplified Facebook Posts",
        table: () => <TablePost post={posts} />,
        render: () =>
          renderPosts(postType, [
            "shares",
            "links_clicks",
            "photo_clicks",
            "other_clicks",
            "post_clicks",
          ]),
      },
      amplifiedInstagramPosts: {
        title: () => "Amplified Instagram Posts",
        table: () => <TablePost post={posts} />,
        render: () => renderPosts(postType, ["shares", "links_clicks"]),
      },
      tiktokPosts: {
        title: () => "TikTok Videos",
        table: () => <TablePost post={posts} />,
        render: () =>
          renderPosts(postType, [
            "shares",
            "total_play_time",
            "avg_watch_time",
            "total_views",
          ]),
      },
      dafault: {
        title: () => "Posts",
        table: () => <TablePost post={posts} />,
        render: () => renderPosts(postType),
      },
    };

    return (options[postType][field] || options.default[field])();
  };

  const getStickersTaps = (sticker_taps) => {
    if (!sticker_taps) return null;
    return sticker_taps.map((sticker) => ({
      key: sticker.name,
      value: sticker.amount,
    }));
  };

  /** Generic Render Method with subrows as param */
  const renderPosts = (postType, subRowsOptions) => {
    let posts = props[postType];
    if (subRowsOptions?.length > 0) {
      posts = posts.map((post) => {
        const metrics = getSubRows(subRowsOptions, post);
        const subRows = [];
        if (metrics?.length > 0) {
          subRows.push({ title: "ADDITIONAL METRICS", fields: metrics });
        }
        post.subRows = subRows || null;
        return post;
      });
    }
    return renderReport(postType, posts);
  };

  const renderStories = (postType) => {
    const options = ["taps_back", "taps_forward", "skips", "exits"];
    let stories = props[postType];
    stories = stories.map((story) => {
      const navigationRows = getSubRows(options, story);
      const stickerTaps = getStickersTaps(story.sticker_taps);
      const subRows = [];

      if (navigationRows?.length > 0) {
        subRows.push({ title: "NAVIGATION", fields: navigationRows });
      }
      if (stickerTaps?.length > 0) {
        subRows.push({ title: "STICKER TAPS", fields: stickerTaps });
      }

      story.subRows = subRows || null;
      return story;
    });

    return renderReport(postType, stories);
  };

  const getViewTypePostHeader = (postType) => {
    let buttonLabel = "List View";
    let icon = List;
    let toggleToView = "list";
    if (postsViewType[postType] === "list") {
      buttonLabel = "Gallery View";
      icon = Gallery;
      toggleToView = "gallery";
    }
    return (
      <div
        className="view-button"
        onClick={() => togglePostView(postType, toggleToView)}
      >
        <div className="view-button-container">
          <div className="view-image-container">
            <img src={icon} />
          </div>
          <span>{buttonLabel}</span>
        </div>
      </div>
    );
  };

  console.log({ props });

  return (
    <div>
      {Object.keys(postsTypes).map((postType) => {
        const posts = props[postType];
        return posts?.length > 0 ? (
          <div key={`${postType}-report-posts`} className="report-posts">
            <h2>{getPostsData(postType, "title")}</h2>
            <div className="view-header">
              <div className="view-content">
                {posts.length} {posts.length > 1 ? "Posts" : "Post"}
              </div>
              {getViewTypePostHeader(postType)}
            </div>
            {getPostsData(postType, "render")}
          </div>
        ) : null;
      })}
    </div>
  );
};

export default (props) => <Report {...props} />;
