import React from "react";
const STYLES = ["btn--primary", "btn--secondary", "btn--borderless"];
const SIZE = ["btn--medium", "btn--small"];
const ICON_SIZE = ["icon--medium", "icon--small"];

const Button = ({
  children,
  icon,
  iconSize,
  type,
  className,
  onClick,
  path,
  buttonStyle,
  buttonSize,
  props,
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZE.includes(buttonSize) ? buttonSize : SIZE[0];

  const checkIconSize =
    icon && ICON_SIZE.includes(iconSize) ? iconSize : ICON_SIZE[0];

  return (
    <button
      className={`btn ${checkButtonStyle} ${checkButtonSize} ${className}`}
      onClick={onClick}
      type={type}
      path={path}
      {...props}
    >
      <div className="btn--label">
        {children}
        {icon && <img className={checkIconSize} src={icon} />}
      </div>
    </button>
  );
};

export default Button;
