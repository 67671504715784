import React from "react";
import { prettifyDate } from "../../util";
import Checkbox from "../Checkbox";

class CampaignRow extends React.Component {
  constructor(props) {
    super(props);
    this.redirectToShowPage = this.redirectToShowPage.bind(this);
  }

  renderClientBrand() {
    const { campaign } = this.props;
    return (
      <div
        className="client-brand vert-center pointer"
        onClick={this.redirectToShowPage}
      >
        <p>{campaign.brand.name}</p>
        {campaign.client && campaign.client.name && (
          <span>{campaign.client.name}</span>
        )}
      </div>
    );
  }

  redirectToShowPage(e) {
    e.stopPropagation();
    window.location.href = `/campaigns/${this.props.campaign.id}`;
  }

  renderKeywords() {
    return <span>keywords</span>;
  }

  render() {
    const {
      campaign,
      status,
      handleCheck,
      checked,
      selectCampaign
    } = this.props;
    switch (status) {
      case "discovery":
        return (
          <div className="row campaign-row socialyte-row">
            <div className="flex flex-2">
              <div className="vert-center">
                <Checkbox
                  checked={checked}
                  handleCheck={handleCheck(campaign.id)}
                />
              </div>
              {this.renderClientBrand()}
            </div>
            <div
              className="vert-center flex-4 pointer"
              onClick={this.redirectToShowPage}
            >
              {campaign.name}
            </div>
            <div
              className="vert-center flex-5 pointer"
              onClick={this.redirectToShowPage}
            >
              {this.renderKeywords()}
            </div>
            <div
              className="vert-center flex-2 pointer"
              onClick={this.redirectToShowPage}
            >
              {campaign.numOfInfluencers}
            </div>
            <div
              className="vert-center flex-2 pointer"
              onClick={this.redirectToShowPage}
            >
              {campaign.assignee.name}
            </div>
          </div>
        );
      case "running":
        return (
          <div className="row campaign-row socialyte-row">
            <div className="flex flex-2">
              <div className="vert-center">
                <Checkbox
                  checked={checked}
                  handleCheck={handleCheck(campaign.id)}
                />
              </div>
              {this.renderClientBrand()}
            </div>
            <div
              className="vert-center flex-3 pointer"
              onClick={this.redirectToShowPage}
            >
              {campaign.name}
            </div>
            <div
              className="vert-center flex-2 pointer"
              onClick={this.redirectToShowPage}
            >
              {prettifyDate(campaign.startDate)}
            </div>
            <div
              className="vert-center flex-2 pointer"
              onClick={this.redirectToShowPage}
            >
              {prettifyDate(campaign.endDate)}
            </div>
            <div
              className="vert-center flex-3 pointer"
              onClick={this.redirectToShowPage}
            >
              details
            </div>
            <div
              className="vert-center flex-2 pointer"
              onClick={this.redirectToShowPage}
            >
              {campaign.assignee.name}
            </div>
          </div>
        );
      default:
        return (
          <div className="row campaign-row socialyte-row">
            <div className="flex col-2">
              <div className="vert-center">
                <Checkbox
                  checked={checked}
                  handleCheck={handleCheck(campaign.id)}
                />
              </div>
              {this.renderClientBrand()}
            </div>
            <div
              className="vert-center col-4 vert-center col-status pointer"
              onClick={this.redirectToShowPage}
            >
              <span>{campaign.name}</span>
              <div className="pencil" onClick={selectCampaign}></div>
            </div>
            <div
              className="vert-center col-2 col-status pointer"
              onClick={this.redirectToShowPage}
            >
              <span>{campaign.status}</span>
            </div>
            <div
              className="vert-center col-2 pointer"
              onClick={this.redirectToShowPage}
            >
              {campaign.owner.name}
            </div>
            <div
              className="vert-center col-2 pointer"
              onClick={this.redirectToShowPage}
            >
              {campaign.assignee.name}
            </div>
          </div>
        );
    }
  }
}

export default CampaignRow;
