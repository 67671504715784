import React from "react";

class Checkbox extends React.Component {
  constructor() {
    super();
    this.toggleCheck = this.toggleCheck.bind(this);
  }

  toggleCheck(e) {
    const { handleCheck, value } = this.props;
    e.preventDefault();
    return handleCheck(e);
  }

  renderInput() {
    const { isFilter, checked, name, handleCheck } = this.props;
    
    if (isFilter) {
      return (
        <input
          type="checkbox"
          name={name}
          checked={checked}
          onChange={handleCheck}
        />
      );
    } else {
      return (
        <div className='vert-center'>
          <input
            type="checkbox"
            name={name}
            checked={checked}
            onChange={handleCheck}
          />
        </div>
      );
    }
  }

  render() {
    const { label, customClassName } = this.props;
    return (
      <div className={`checkbox ${customClassName || ''}`}>
        <label className="label">
          {this.renderInput()}
          <span></span>
          <div>{label}</div>
        </label>
      </div>
    );
  }
}

export default Checkbox;
