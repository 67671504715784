import React from "react";
import { useField } from "formik";
import { RadioGroup, Radio } from "@palmerhq/radio-group";
import { camelize, decamelize } from "humps";

const ButtonRadioGroup = ({ label, ...props }) => {
  const [{ onChange, onBlur, ...field }] = useField(props.name);

  return (
    <RadioGroup
      {...props}
      {...field}
      labelledBy={props.name}
      onBlur={onBlur(props.name)}
      onChange={onChange(props.name)}
    />
  );
};

const ButtonGroup = ({ label, name, ...props }) => (
  <label className="influencer-form--field-label">
    <span className="label">
      {label}
      <span style={{ color: "red" }}>{props.isRequired && "*"}</span>
    </span>
    <div
      className={`influencer-form--radio-group influencer-form--radio-group-${props.name}`}
    >
      <ButtonRadioGroup name={name}>
        {props.items.map((item) => (
          <Radio
            key={item}
            value={decamelize(camelize(item))}
            style={{ width: props.items.length === 4 ? "25%" : "50%" }}
          >
            {item}
          </Radio>
        ))}
      </ButtonRadioGroup>
    </div>
  </label>
);

export default ButtonGroup;
