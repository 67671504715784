import React from 'react';
import Select from 'react-select';
import * as APIUtil from '../api_util';

class AddToGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      group: null,
      groups: [],
      errors: [],
      saved: false
    }
    this.handleAddToGroup = this.handleAddToGroup.bind(this);
  }

  componentDidMount() {
    APIUtil.fetchGroups().then(groups => {
      this.setState({ groups: Object.values(groups) });
    })
  }

  getOptions(key) {
    if(this.state[key] && this.state[key].length > 0) {
      const options = this.state[key].map(value => {
        return { value: value.id, label: value.name };
      });
      return [{ value: null, label: "SELECT" }, ...options];
    }
  }
  handleSelectChange(category) {
    return selectedOption => {
      this.setState({ [category]: selectedOption });
    }
  }

  handleAddToGroup(e) {
    const { influencer } = this.props;
    const { group } = this.state
    if(group) {
      APIUtil.createGroupInfluencer(influencer.id, group.value).then(() => {
        this.setState({saved: true, errors: []})
      },
      errors => {
        this.setState({ errors: errors.responseJSON, saved: false });
      })
    }
  }

  renderMessage() {
    if (this.state.saved) {
      return (<span className="saved">SAVED</span>);
    } else if (this.state.errors.length > 0) {
      return (<span className="error">{this.state.errors[0]}</span>);
    }
  }

  render() {
    const { closeGroupOpen } = this.props;
    return (
      <React.Fragment>
        <div className="group-container">
          <h3>ADD TO GROUP</h3>
          <div className="group-selects">
            <label>
              <p>GROUP</p>
              <Select
                className="select-container"
                classNamePrefix="select"
                value={this.state.group}
                options={this.getOptions('groups')}
                isClearable
                onChange={this.handleSelectChange('group')}
              />
            </label>
          </div>
          <div className="group-buttons">
            <button className={`add-button ${this.state.group && 'active'}`} onClick={this.handleAddToGroup}>ADD</button>
            <button className="cancel-button" onClick={closeGroupOpen}>CANCEL</button>
            { this.renderMessage() }
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default AddToGroup;
