import React from 'react';
import {
  VictoryChart, VictoryAxis, VictoryBar } from 'victory';

class InfluencerAudienceAgeGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: {
        all: true,
        female: false,
        male: false
      },
      data: {
        all: this.props.all_data,
        female: this.props.female_data,
        male: this.props.male_data
      },
      currentChartData: this.props.all_data
    }
    this.setTab = this.setTab.bind(this);
  }

  initialTabs() {
    return {
      all: false,
      female: false,
      male: false
    }
  }

  setTab(tab) {
    var tabs = this.initialTabs();
    tabs[tab] = true;
    const newData = this.state.data[tab]
    return () => this.setState({
      tabs: tabs,
      currentChartData: newData
    });
  }

  render() {
    const tabs = this.state.tabs;
    if (this.props.listView) {
      return (
        <VictoryChart width={1000} height={300} domainPadding={30}>
          <VictoryAxis
            style={{
              tickLabels: {
                fontFamily: "Cardo, sans-serif",
                fill: "#8B8B8B",
                fontSize: "24px"
              }
            }}
          />
          <VictoryBar
            barRatio={0.8}
            alignment={"middle"}
            labels={(d) => `${(d.y*100).toFixed(1)}%`}
            style={{
              data: {
                stroke: (d) => d.y === Math.max(...this.state.data.all.map(x => x.y)) ? "#8B8B8B" : "#121212",
                fill: (d) => d.y === Math.max(...this.state.data.all.map(x => x.y)) ? "#8B8B8B" : "#121212"
              },
              labels: { fontSize: "24px"}
            }}
            data={this.state.data.all}
          />
        </VictoryChart>
      )
    } else {
      return (
        <div>
          <div className={`tab ${tabs.all ? 'active' : ''}`} onClick={this.setTab('all')}><span>ALL</span></div>
          <div className={`tab ${tabs.female ? 'active' : ''}`} onClick={this.setTab('female')}><span>FEMALE</span></div>
          <div className={`tab ${tabs.male ? 'active' : ''}`} onClick={this.setTab('male')}><span>MALE</span></div>
          <VictoryChart width={700} height={200} domainPadding={30}>
            <VictoryAxis
              style={{
                tickLabels: {
                  fontFamily: "Cardo, sans-serif",
                  fill: "#8B8B8B",
                  fontSize: "11px"
                }
              }}
            />
            <VictoryBar
              barRatio={0.8}
              alignment={"middle"}
              labels={(d) => `${(d.y*100).toFixed(1)}%`}
              style={{
                data: {
                  stroke: (d) => d.y === Math.max(...this.state.currentChartData.map(x => x.y)) ? "#8B8B8B" : "#121212",
                  fill: (d) => d.y === Math.max(...this.state.currentChartData.map(x => x.y)) ? "#8B8B8B" : "#121212"
                },
              }}
              data={this.state.currentChartData}
            />
          </VictoryChart>
        </div>
      )
    }
  }
}

export default InfluencerAudienceAgeGraph;
