import React from "react";
import { prettyNum, prettifyDate, prettyCurrency, toFormatPercentage } from "../../../util";
import Table from "./Table";

class TableContractingCampaigns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  columns() {
    return [
      {
        Header: "RFP",
        accessor: "rfp",
        align: "align-left",
        width: "5%",
      },
      {
        Header: "Brand",
        accessor: "brand",
        align: "align-left",
        width: "7%",
      },
      {
        Header: "Campaign",
        accessor: "name",
        align: "align-left",
        width: "9%",
      },
      {
        Header: "Compensation",
        accessor: "compensation",
        align: "align-left",
        width: "4%",
        Cell: (props) => prettyCurrency(props.value),
      },
      {
        Header: "Commission Rate",
        accessor: "comission_rate",
        align: "align-left",
        width: "4%",
        Cell: (props) => toFormatPercentage(props.value, 0),
      },
      {
        Header: "Campaign Start Month",
        accessor: "start_month",
        align: "align-left",
        width: "7%",
        Cell: (props) => prettifyDate(props.value, { month: "short", year: "numeric"}),
      },
      {
        Header: "Closing Likelihood",
        accessor: "campaign_probability",
        align: "align-left",
        width: "5%",
      },
      {
        Header: "Type",
        accessor: "type",
        align: "align-left",
        width: "7%",
      },
      {
        Header: "Campaign Manager",
        accessor: "manager",
        align: "align-left",
        width: "4%",
      },
      {
        Header: "Tags",
        accessor: "tags",
        align: "align-left",
        width: "7%",
      },
    ];
  }

  render() {
    return (
      <div>
        {this.props.campaigns.length ? (
          <div>
            <div class="view-header">
              <div class="view-content">CONTRACTING</div>
            </div>
            <div className="report-activeCampaigns">
              <Table columns={this.columns()} data={this.props.campaigns} hideFooter={true} />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
};

export default TableContractingCampaigns;
