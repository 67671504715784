import React from "react";
import ReactPlayer from "react-player";
import { prettifyDate, prettyNum } from "../../util";

class Post extends React.Component {
  isStory() {
    const { post } = this.props;
    return (
      post.media_type === "story_video" || post.media_type === "story_image"
    );
  }

  isYoutube() {
    return this.props.post_type === "youtubePosts";
  }

  renderFollowers() {
    const { post } = this.props;
    const influencer = post.influencer || this.props.influencer;
    if (this.isYoutube()) {
      return (
        <div className="influencer-datum">
          <p>SUBSCRIBERS</p>
          <span>{prettyNum(post.subscribers)}</span>
        </div>
      );
    }
    return (
      <div className="influencer-datum">
        <p>FOLLOWERS</p>
        <span>{prettyNum(influencer.follower_count)}</span>
      </div>
    );
  }

  renderImpressions() {
    const { post } = this.props;
    if (this.isYoutube()) {
      return (
        <div className="influencer-datum">
          <p>VIEWS</p>
          <span>{prettyNum(post.views)}</span>
        </div>
      );
    }
    return (
      <div className="influencer-datum">
        <p>IMPRESSIONS</p>
        <span>{prettyNum(post.impressions)}</span>
      </div>
    );
  }

  renderEngagment() {
    const { post } = this.props;

    if (this.isStory()) {
      return (
        <div className="influencer-datum">
          <p>REPLIES</p>
          <span>{prettyNum(post.comment_count)}</span>
        </div>
      );
    }
    return (
      <div className="influencer-datum">
        <p>ENGAGEMENT</p>
        <span>{prettyNum(post.comment_count + post.like_count)}</span>
      </div>
    );
  }

  renderImage() {
    const { post, post_type } = this.props;
    return post_type === "youtubePosts" ? (
      <ReactPlayer url={post?.permalink} width="100%" />
    ) : (
      <img src={post.thumbnail_link} />
    );
  }

  render() {
    const { post, post_type } = this.props;
    const influencer = post.influencer || this.props.influencer;
    const { first_name, last_name } = this.props;

    const dateOptions = {
      month: "short",
      day: "2-digit",
      year: "numeric",
    };
    const postEngagementRate = `${(post.engagement_rate * 100).toLocaleString(
      undefined,
      {
        maximumFractionDigits: 4,
      }
    )}%`;
    const engagementRate = this.isStory()
      ? post.comment_count
      : postEngagementRate;

    return (
      <div className="gallery-post-container">
        <div className="post-img-container">
          {this.renderImage()}
          <div className="influencer-data">
            {this.renderFollowers()}
            {this.renderEngagment()}
            {!this.isYoutube() ? (
              <div className="influencer-datum">
                <p>REACH</p>
                <span>{prettyNum(post.reach)}</span>
              </div>
            ) : null}
            {this.renderImpressions()}
            {!this.isStory() && (
              <div className="influencer-datum">
                <p>ENG. RATE</p>
                <span>{engagementRate}</span>
              </div>
            )}
          </div>
        </div>
        <div className="post-details">
          <div className="influencer-details">
            <div className="influencer-profile">
              <div className="influencer-img-container">
                <img src={influencer.profile_picture_url} />
              </div>
              <div className="influencer-info vert-center">
                <p>
                  {first_name} {last_name}
                </p>
                <span>{influencer.country}</span>
              </div>
            </div>
            <a href={post.media_link} target="_blank">
              LINK
            </a>
          </div>
          <p className="caption">{post.caption}</p>
          <span className="date">
            {prettifyDate(post.timestamp, dateOptions)}
          </span>
        </div>
      </div>
    );
  }
}

export default Post;
