export const fetchClients = () => {
  return $.ajax({
    method: "GET",
    url: "/api/v1/clients"
  });
};

export const fetchBrands = (client, with_group) => {
  let clientId = null;
  if (client && client.value) {
    clientId = client.value;
  }
  return $.ajax({
    method: "GET",
    url: "/api/v1/brands",
    data: { client_id: clientId, with_group }
  });
};

export const fetchCampaigns = (
  client,
  status,
  owner_id,
  assignee_id,
  page,
  sort,
  start_date,
  end_date,
  direction = "asc"
) => {
  let clientId = null;
  if (client && client.value) {
    clientId = client.value;
  }

  let url = "/api/v1/campaigns";
  if (sort) {
    url = `/api/v1/campaigns?sort=${sort}&direction=${direction}`;
  }

  return $.ajax({
    method: "GET",
    url,
    data: {
      client_id: clientId,
      status,
      owner_id: owner_id,
      assignee_id: assignee_id,
      start_date: start_date,
      end_date: end_date,
      page
    }
  });
};

export const fetchBrandCampaigns = brand => {
  let brandId = null;
  if (brand && brand.value) {
    brandId = brand.value;
  }
  return $.ajax({
    method: "GET",
    url: "/api/v1/campaigns",
    data: { brand_id: brandId }
  });
};

export const fetchGroups = page => {
  return $.ajax({
    method: "GET",
    url: "/api/v1/groups",
    data: { page: page }
  });
};

export const fetchGroupInfluencers = group => {
  let groupId = null;
  if (group && group.id) {
    groupId = group.id;
  }
  return $.ajax({
    method: "GET",
    url: `/api/v1/groups/${groupId}/influencers`,
    data: { group_id: groupId }
  });
};

export const fetchUsers = () => {
  return $.ajax({
    method: "GET",
    url: "/api/v1/users"
  });
};

export const createCampaign = campaign => {
  return $.ajax({
    method: "POST",
    url: "/api/v1/campaigns",
    data: { campaign }
  });
};

export const updateCampaign = (campaign, id) => {
  return $.ajax({
    method: "PATCH",
    url: `/api/v1/campaigns/${id}`,
    dataType: "json",
    data: { campaign }
  });
};

export const createGroupInfluencer = (influencerId, groupId) => {
  return $.ajax({
    method: "POST",
    dataType: "json",
    url: "/api/v1/group_influencers",
    data: {
      group_influencer: {
        influencer_id: influencerId,
        group_id: groupId
      }
    }
  });
};

export const createGroupInfluencers = (influencerIds, groupId) => {
  return $.ajax({
    method: "POST",
    dataType: "json",
    url: "/api/v1/group_influencers/create_multiple",
    data: {
      group_influencer: {
        influencer_ids: influencerIds,
        group_id: groupId
      }
    }
  });
};

export const deleteGroupInfluencers = (influencerIds, groupId) => {
  return $.ajax({
    method: "DELETE",
    dataType: "json",
    url: `/api/v1/groups/${groupId}/destroy_influencers`,
    data: { influencer_ids: influencerIds }
  });
};

export const updateCampaignStatuses = (campaignIds, status) => {
  return $.ajax({
    method: "PATCH",
    url: "/api/v1/campaigns/update_statuses",
    data: { ids: campaignIds, status }
  });
};

export const searchInfluencers = query => {
  return $.ajax({
    method: "GET",
    url: "/api/v1/influencers/search",
    data: { query }
  });
};

export const searchCampaigns = query => {
  return $.ajax({
    method: "GET",
    url: "/api/v1/campaigns/search",
    data: { query }
  });
};

export const searchGroups = query => {
  return $.ajax({
    method: "GET",
    url: "/api/v1/groups/search",
    data: { query }
  });
};

export const fetchCampaignInfluencersCreators = (campaignId, page) => {
  return $.ajax({
    method: "GET",
    url: `/api/v1/campaigns/${campaignId}/creators`,
    data: { page }
  });
};

export const createCampaignInfluencer = (influencerId, campaignId) => {
  return $.ajax({
    method: "POST",
    dataType: "json",
    url: "/api/v1/campaign_influencers",
    data: {
      campaign_influencer: {
        influencer_id: influencerId,
        campaign_id: campaignId
      }
    }
  });
};

export const createCampaignInfluencers = (influencerIds, campaignId) => {
  return $.ajax({
    method: "POST",
    dataType: "json",
    url: "/api/v1/campaign_influencers/create_multiple",
    data: {
      campaign_influencer: {
        influencer_ids: influencerIds,
        campaign_id: campaignId
      }
    }
  });
};

export const createCampaignInfluencersByGroup = (groupIds, campaignId) => {
  return $.ajax({
    method: "POST",
    dataType: "json",
    url: "/api/v1/campaign_influencers/create_by_groups",
    data: {
      campaign_influencer: {
        group_ids: groupIds,
        campaign_id: campaignId
      }
    }
  });
};

export const deleteCampaignInfluencers = (influencerIds, campaignId) => {
  return $.ajax({
    method: "DELETE",
    dataType: "json",
    url: `/api/v1/campaigns/${campaignId}/destroy_influencers`,
    data: { influencer_ids: influencerIds }
  });
};

export const exportCampaignsReport = (startDate, endDate, ownerId, assigneeId) => {
  let query = {
    start_date: startDate,
    end_date: endDate,
    owner_id: ownerId,
    assignee_id: assigneeId
  }
  Object.keys(query).forEach(key => !query[key] ? delete query[key] : {});

  return $.ajax({
    method: "GET",
    url: `/campaigns/export_date_range`,
    data: query
  });
};

export const fetchCampaignInfluencers = (campaignId, page) => {
  return $.ajax({
    method: "GET",
    url: `/api/v1/campaigns/${campaignId}/influencers`,
    data: { page }
  });
};

export const refreshCampaignInfluencers = (campaignId, page) => {
  return $.ajax({
    method: "GET",
    url: `/api/v1/campaigns/${campaignId}/influencers`,
    data: { page, update_posts_and_stats: '1' }
  });
};

export const getInfluencerDashboards = (influencerId) => {
  return $.ajax({
    method: "GET",
    url: `/api/v1/influencers/${influencerId}/dashboards`,
    data: { }
  });
};

export const createClient = client => {
  return $.ajax({
    method: "POST",
    url: "/api/v1/clients",
    data: { client: client }
  });
};

export const createBrand = brand => {
  return $.ajax({
    method: "POST",
    url: "/api/v1/brands",
    data: { brand: brand }
  });
};

export const createGroup = group => {
  return $.ajax({
    method: "POST",
    url: "/api/v1/groups",
    data: { group: group }
  });
};

export const updateGroup = (group, id) => {
  return $.ajax({
    method: "PATCH",
    url: `/api/v1/groups/${id}`,
    dataType: "json",
    data: { group }
  });
};

export const deleteGroups = groupIds => {
  return $.ajax({
    method: "POST",
    url: `/api/v1/groups/destroy_bulk/`,
    data: {
      group: {
        group_ids: groupIds
      }
    }
  });
};

export const createPost = (campaignId, influencerId, post) => {
  const formData = new FormData();
  formData.append("media", post.media);
  delete post.media;
  formData.append("post", JSON.stringify(post));
  return $.ajax({
    method: "POST",
    contentType: false,
    processData: false,
    url: `/api/v1/campaigns/${campaignId}/influencers/${influencerId}/posts`,
    data: formData
  });
};

export const updatePost = (campaignId, influencerId, id, post) => {
  const formData = new FormData();
  if (post.media) {
    formData.append("media", post.media);
    delete post.media;
  }
  formData.append("post", JSON.stringify(post));
  return $.ajax({
    method: "PATCH",
    contentType: false,
    processData: false,
    url: `/api/v1/campaigns/${campaignId}/influencers/${influencerId}/posts/${id}`,
    data: formData
  });
};

export const createInfluencerInterests = (influencerId, interests) => {
  return $.ajax({
    method: "POST",
    url: `/api/v1/influencers/${influencerId}/interests`,
    data: interests
  });
};

export const updateInfluencerInterests = (influencerId, interests) => {
  return $.ajax({
    method: "PATCH",
    url: `/api/v1/influencers/${influencerId}/update_interests`,
    data: interests
  });
};

export const createInfluencerBrands = (influencerId, brands) => {
  return $.ajax({
    method: "POST",
    url: `/api/v1/influencers/${influencerId}/brands`,
    dataType: "json",
    data: brands
  });
};

export const updateInfluencerBrands = (influencerId, brands) => {
  return $.ajax({
    method: "PATCH",
    url: `/api/v1/influencers/${influencerId}/update_brands`,
    dataType: "json",
    data: brands
  });
};
