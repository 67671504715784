import React from "react";
import { Field as FormikField } from "formik";
import Field from "../../Controls/FormFields/Field";
import DatePickerField from "../../Controls/FormFields/DatePicker";
import ButtonGroup from "../../Controls/FormFields/ButtonGroup";
import UploadImage from "../components/UploadImage";
import CityDropdown from "../components/CityDropdown";

// note: the `isRequired` prop is hardcoded for now because you can't grab
// the validationSchema from `useFormikContext()` as you normally would
// there's a PR open for it: https://github.com/jaredpalmer/formik/pull/2090
// but it needs to be merged in order for that to work.
const BasicInfo = ({ influencerTypes, genders, ...props }) => (
  <div className="influencer-form--section">
    <div className="influencer-form--section-header">
      <h3 className="edit-post-form--header">Basic Information</h3>
    </div>
    <div
      className="influencer-form--section-content"
      style={{ maxWidth: "498px" }}
    >
      <div className="influencer-form--section-row">
        <ButtonGroup
          label="Type"
          items={influencerTypes}
          name="influencerType"
          style={{ width: "498px" }}
          isRequired
        />
      </div>
      <div className="influencer-form--section-row">
        <Field
          isRequired
          isEdit={props.isEdit}
          label="First Name"
          name="firstName"
        />
        <Field
          isRequired
          isEdit={props.isEdit}
          label="Last Name"
          name="lastName"
        />
      </div>
      <div className="influencer-form--section-row">
        <ButtonGroup isRequired label="Gender" items={genders} name="gender" />
        <DatePickerField isRequired label="Birthday" name="birthDate" />
      </div>
      <div className="influencer-form--section-row">
        <Field isRequired isEdit={props.isEdit} label="Email" name="email" />
        <CityDropdown
          isRequired
          name="locationName"
          label="City"
          values={props.values}
        />
      </div>
      <div className="influencer-form--section-row">
        <Field isEdit={props.isEdit} label="Website" name="website" />
      </div>
      <div className="influencer-form--section-row">
        <Field isEdit={props.isEdit} textarea label="Biography" name="bio" />
      </div>
      <div className="influencer-form--section-row">
        <UploadImage isRequired name="profilePicture" label="Profile Image" />
      </div>
    </div>
  </div>
);

export default BasicInfo;
