import React from "react";
import ProfileFeed from "./ProfileFeed";
import ProfileFilters from "./ProfileFilters";
import merge from "lodash/merge";
import Button from "../Controls/Button";
import Plus from "icons/plus-white.svg";

class ProfileDiscovery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      influencers: [],
      filters: {
        female: false,
        male: false,
        other: false,
        age: null,
        network: false,
        non_exclusive: true,
        exclusive: true,
        prestige: true,
        follower_size_min: "",
        follower_size_max: "",
        impressions_min: "",
        impressions_max: "",
        reach_rate_min: "",
        reach_rate_max: "",
        engagement_rate_min: "",
        engagement_rate_max: "",
        keywords: [],
        audience_age: null,
        audience_female: "",
        audience_male: "",
        latitude: null,
        longitude: null,
        audience_locations: [],
        audience_location_percentage: "",
        influencer_location: null,
        with_facebook: false,
        with_youtube: false,
        with_twitter: false,
        with_pinterest: false,
        with_tiktok: false,
      },
      filtersRow: {
        female: false,
        male: false,
        age: false,
        other: false,
        network: false,
        non_exclusive: true,
        exclusive: true,
        prestige: true,
        follower_size: false,
        impressions: false,
        reach_rate: false,
        engagement_rate: false,
        audience: false,
        influencer_location: false,
        keywords: false,
        with_facebook: false,
        with_youtube: false,
        with_twitter: false,
        with_pinterest: false,
        with_tiktok: false,
      },
      page: 1,
      totalCount: 0,
      isLoading: false,
      isLoadingFilteredInfluencers: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleInfluencerFilterSubmit = this.handleInfluencerFilterSubmit.bind(
      this
    );
    this.clearAllFilters = this.clearAllFilters.bind(this);
    this.fetchMorePosts = this.fetchMorePosts.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.getInfluencers().then(
      (response) => {
        this.setState({
          influencers: response.influencers,
          page: 2,
          isLoading: false,
          totalCount: response.totalCount,
        });
      },
      (errors) => {
        this.setState({ isLoading: false });
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      follower_size_min,
      follower_size_max,
      impressions_min,
      impressions_max,
      reach_rate_min,
      reach_rate_max,
      engagement_rate_min,
      engagement_rate_max,
      keywords,
      audience_age,
      audience_female,
      audience_male,
    } = this.state.filters;

    let newFiltersRow = { ...this.state.filtersRow };
    const filters = { ...this.state.filters };
    let change = false;
    if (prevState.filters.keywords.length === 0 && keywords.length > 0) {
      newFiltersRow.keywords = true;
      change = true;
    }
    if (
      (!prevState.filters.follower_size_min ||
        !prevState.filters.follower_size_max) &&
      follower_size_min &&
      follower_size_max
    ) {
      newFiltersRow.follower_size = true;
      change = true;
    }
    if (
      (!prevState.filters.impressions_min ||
        !prevState.filters.impressions_max) &&
      impressions_min &&
      impressions_max
    ) {
      newFiltersRow.impressions = true;
      change = true;
    }
    if (
      (!prevState.filters.reach_rate_min ||
        !prevState.filters.reach_rate_max) &&
      reach_rate_min &&
      reach_rate_max
    ) {
      newFiltersRow.reach_rate = true;
      change = true;
    }
    if (
      (!prevState.filters.engagement_rate_min ||
        !prevState.filters.engagement_rate_max) &&
      engagement_rate_min &&
      engagement_rate_max
    ) {
      newFiltersRow.engagement_rate = true;
      change = true;
    }
    if (
      !prevState.filters.audience_age &&
      !prevState.filters.audience_male &&
      !prevState.filters.audience_female &&
      (audience_age || audience_male || audience_female)
    ) {
      newFiltersRow.audience = true;
      change = true;
    }
    if (
      prevState.filters.keywords.length > 0 &&
      keywords &&
      keywords.length === 0
    ) {
      newFiltersRow.keywords = false;
      change = true;
    }
    if (
      prevState.filters.follower_size_min &&
      prevState.filters.follower_size_max &&
      (!follower_size_min || !follower_size_max)
    ) {
      newFiltersRow.follower_size = false;
      change = true;
    }
    if (
      prevState.filters.impressions_min &&
      prevState.filters.impressions_max &&
      (!impressions_min || !impressions_max)
    ) {
      newFiltersRow.impressions = false;
      change = true;
    }
    if (
      prevState.filters.reach_rate_min &&
      prevState.filters.reach_rate_max &&
      (!reach_rate_min || !reach_rate_max)
    ) {
      newFiltersRow.reach_rate = false;
      change = true;
    }
    if (
      prevState.filters.engagement_rate_min &&
      prevState.filters.engagement_rate_max &&
      (!engagement_rate_min || !engagement_rate_max)
    ) {
      newFiltersRow.engagement_rate = false;
      change = true;
    }
    if (
      (prevState.filters.audience_age ||
        prevState.filters.audience_male ||
        prevState.filters.audience_female) &&
      !audience_age &&
      !audience_male &&
      !audience_female
    ) {
      newFiltersRow.audience = false;
      change = true;
    }

    /** Update type row filters */
    [
      "age",
      "male",
      "female",
      "other",
      "network",
      "non_exclusive",
      "exclusive",
      "prestige",
      "influencer_location",
      "with_facebook",
      "with_youtube",
      "with_twitter",
      "with_pinterest",
      "with_tiktok",
    ].forEach((option) => {
      if (prevState.filters[option] !== filters[option]) {
        newFiltersRow[option] = filters[option];
        change = true;
      }
    });

    if (change) this.setState({ filtersRow: newFiltersRow });
  }

  getAge(age) {
    const ageStringObj = {
      "<18": { min: 0, max: 17 },
      "18-24": { min: 18, max: 24 },
      "25-34": { min: 25, max: 34 },
      "35-44": { min: 35, max: 44 },
      "45-54": { min: 45, max: 54 },
      "55-65": { min: 55, max: 65 },
      ">65": { min: 65, max: 200 },
    };
    if (age && age.value) {
      return ageStringObj[age.value];
    } else {
      return null;
    }
  }

  getFilters() {
    const filters = merge({}, this.state.filters);

    const gender = [];
    const influencer_type = [];
    const options = [
      { key: "male", filterArray: gender },
      { key: "female", filterArray: gender },
      { key: "other", filterArray: gender },
      { key: "network", filterArray: influencer_type },
      { key: "non_exclusive", filterArray: influencer_type },
      { key: "exclusive", filterArray: influencer_type },
      { key: "prestige", filterArray: influencer_type },
    ];

    for (const option of options) {
      if (this.state.filters[option.key]) {
        option.filterArray.push(option.key);
        delete filters[option.key];
      }
    }

    if (gender.length > 0) {
      filters["gender"] = gender;
    }

    if (influencer_type.length > 0) {
      filters["influencer_type"] = influencer_type;
    }

    const keys = Object.keys(this.state.filters);
    keys.forEach((key) => {
      const value = this.state.filters[key];
      if (!value || (key === "keywords" && Object.keys(value).length < 1)) {
        delete filters[key];
      }
      if (key === "age" && value !== null) {
        filters.age = this.getAge(filters.age);
      }
      if (
        (key === "follower_size_min" || key === "follower_size_max") &&
        value !== ""
      ) {
        filters[key] = value * 1000;
      }
      if (
        (key === "impressions_min" || key === "impressions_max") &&
        value !== ""
      ) {
        filters[key] = value * 1000;
      }
      if (
        (key === "reach_rate_min" || key === "reach_rate_max") &&
        value !== ""
      ) {
        filters[key] = value / 100;
      }
      if (
        (key === "engagement_rate_min" || key === "engagement_rate_max") &&
        value !== ""
      ) {
        filters[key] = value / 100;
      }
      if (
        (key === "audience_female" || key === "audience_male") &&
        value !== ""
      ) {
        filters[key] = value / 100;
      }
      if (key === "audience_location_percentage" && value !== "") {
        filters[key] = value / 100;
      }
      if (key === "influencer_location" && value !== null) {
        filters[key] = value.value;
      }
    });

    return filters;
  }

  handleInputChange(e) {
    const target = e.target;
    const name = target.name;
    const filters = merge({}, this.state.filters);
    let value = target.type === "checkbox" ? target.checked : target.value;
    filters[name] = value;
    this.setState({ filters }, this.handleInfluencerFilterSubmit);
  }

  handleSelectChange(category) {
    const filters = merge({}, this.state.filters);
    return (selectedOption) => {
      if (category === "keywords" || category === "audience_locations") {
        filters[category] = selectedOption.map((option) => option.value);
      } else {
        //category is age
        filters[category] = selectedOption;
      }
      this.setState({ filters }, this.handleInfluencerFilterSubmit);
    };
  }

  clearFilter(filterType) {
    return (e) => {
      const newFilters = { ...this.state.filters };
      switch (filterType) {
        case "female":
        case "male":
        case "other":
        case "network":
        case "exclusive":
        case "non_exclusive":
        case "prestige":
          newFilters[filterType] = false;
          break;
        case "follower_size":
          newFilters.follower_size_min = "";
          newFilters.follower_size_max = "";
          break;
        case "impressions":
          newFilters.impressions_min = "";
          newFilters.impressions_max = "";
          break;
        case "reach_rate":
          newFilters.reach_rate_min = "";
          newFilters.reach_rate_max = "";
          break;
        case "engagement_rate":
          newFilters.engagement_rate_min = "";
          newFilters.engagement_rate_max = "";
          break;
        case "audience":
          newFilters.audience_age = null;
          newFilters.audience_male = "";
          newFilters.audience_female = "";
          break;
        case "keywords":
          newFilters.keywords = [];
          break;
        default:
          newFilters[filterType] = null;
      }
      this.setState({ filters: newFilters }, this.handleInfluencerFilterSubmit);
    };
  }

  clearAllFilters() {
    const newFilters = {
      age: null,
      female: false,
      male: false,
      other: false,
      network: false,
      non_exclusive: false,
      exclusive: false,
      prestige: false,
      follower_size_min: "",
      follower_size_max: "",
      reach_rate_min: "",
      reach_rate_max: "",
      engagement_rate_min: "",
      engagement_rate_max: "",
      audience_age: null,
      audience_female: "",
      audience_male: "",
      influencer_location: null,
      latitude: null,
      longitude: null,
      audience_locations: [],
      audience_location_percentage: "",
      keywords: [],
    };
    this.setState({ filters: newFilters }, this.handleInfluencerFilterSubmit);
  }

  renderFilters() {
    const { filters, filtersRow } = this.state;
    const {
      age,
      follower_size_min,
      follower_size_max,
      impressions_min,
      impressions_max,
      reach_rate_min,
      keywords,
      reach_rate_max,
      engagement_rate_min,
      engagement_rate_max,
      influencer_location,
    } = filters;

    return (
      <div className="filters-row-container">
        <div className="filters-row">
          <ul>
            {Object.keys(filtersRow).map((key, i) => {
              if (filtersRow[key]) {
                const options = {
                  age: () => (age ? `${age.label} YEARS OLD` : key),
                  influencer_location: () =>
                    influencer_location ? `${influencer_location.label}` : key,
                  follower_size: () =>
                    `${follower_size_min}K - ${follower_size_max}K FOLLOWERS`,
                  impressions: () =>
                    `${impressions_min}K - ${impressions_max}K IMPRESSIONS`,
                  reach_rate: () =>
                    `${reach_rate_min}% - ${reach_rate_max}% REACH`,
                  engagement_rate: () =>
                    `${engagement_rate_min}% - ${engagement_rate_max}% ENGAGEMENT`,
                  keywords: () =>
                    keywords ? `${keywords.length} keywords` : key,
                  audience: () => {
                    let count = 0;
                    if (filters.audience_age) count = count + 1;
                    if (filters.audience_female) count = count + 1;
                    if (filters.audience_male) count = count + 1;
                    return `AUDIENCE (${count})`;
                  },
                  non_exclusive: () => "Non Exclusive",
                  with_facebook: () => "Facebook",
                  with_youtube: () => "Youtube",
                  with_twitter: () => "Twitter",
                  with_pinterest: () => "Pinterest",
                  with_tiktok: () => "TikTok",
                  default: () => key,
                };

                const label = (options[key] || options.default)();

                return (
                  <li key={i} className="filter-button">
                    <span className="filter-button__label">{label}</span>
                    <div
                      className="filter-button__remove"
                      onClick={this.clearFilter(key)}
                    >
                      <span>&#10005;</span>
                    </div>
                  </li>
                );
              }
            })}
          </ul>
        </div>
        <Button
          buttonStyle="btn--primary"
          buttonSize="btn--small"
          icon={Plus}
          iconSize="icon--small"
          type="button"
          style={{ alignSelf: "flex-end" }}
          onClick={() => (window.location.href = "/influencers/new")}
        >
          Add
        </Button>
        <div className="clear-all-button" onClick={this.clearAllFilters}>
          <span>CLEAR ALL</span>
        </div>
      </div>
    );
  }

  browserCheck() {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf("safari") != -1) {
      if (userAgent.indexOf("chrome") > -1) {
        return "chrome";
      } else if (
        userAgent.indexOf("opera") > -1 ||
        userAgent.indexOf("opr") > -1
      ) {
        return "opera";
      } else {
        return "safari";
      }
    }
  }

  getInfluencers(page = 1) {
    const filters = this.getFilters();
    return $.ajax({
      method: "GET",
      dataType: "json",
      contentType: "application/json",
      url: "/api/v1/influencers",
      data: { filters, page },
    });
  }

  handleInfluencerFilterSubmit(e) {
    this.setState({ isLoadingFilteredInfluencers: true });

    this.getInfluencers().then(
      (response) => {
        this.setState({
          influencers: response.influencers,
          totalCount: response.totalCount,
          page: 2,
          isLoadingFilteredInfluencers: false,
        });
      },
      (error) => {
        this.setState({ isLoadingFilteredInfluencers: false });
      }
    );
  }

  fetchMorePosts() {
    this.setState({ isLoading: true });
    const { influencers } = this.state;
    const { page } = this.state;
    this.getInfluencers(page).then(
      (response) => {
        this.setState({
          influencers: [...influencers, ...response.influencers],
          page: page + 1,
          isLoading: false,
        });
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
  }

  renderProfiles() {
    if (this.state.isLoadingFilteredInfluencers)
      return <div className="loader"></div>;
    return (
      <ProfileFeed
        influencers={this.state.influencers}
        fetchMorePosts={this.fetchMorePosts}
        totalCount={this.state.totalCount}
        isLoading={this.state.isLoading}
      />
    );
  }

  render() {
    return (
      <div className={`profile-discover ${this.browserCheck()}`}>
        <ProfileFilters
          handleInputChange={this.handleInputChange}
          handleSelectChange={this.handleSelectChange}
          filters={this.state.filters}
          handleInfluencerFilterSubmit={this.handleInfluencerFilterSubmit}
          googleApiKey={this.props.googleApiKey}
        />
        <div className="feed-container">
          {this.renderFilters()}
          {this.renderProfiles()}
        </div>
      </div>
    );
  }
}

export default ProfileDiscovery;
