import React from "react";
import { FieldArray } from "formik";
import PostField from "../PostField";
import Button from "../../Controls/Button";
import Plus from "icons/plus.svg";

const StickerTapInput = ({ attributes }) => (
  <FieldArray
    data-testid="stickertap"
    name="stickerTaps"
    render={(arrayHelpers) => (
      <div>
        {attributes.map(
          (stickerTap, i) =>
            !stickerTap._destroy && (
              <div
                key={i}
                data-testid={`stickertap-input-${i}`}
                className="stickertaps--field post-stats"
              >
                <PostField
                  key={i}
                  label="Name"
                  type="text"
                  name={`stickerTaps[${i}].name`}
                  isManual
                  span={3}
                />
                <PostField
                  key={i}
                  label="Number of Taps"
                  type="text"
                  name={`stickerTaps[${i}].amount`}
                  isManual
                  span={3}
                />
                <Button
                  data-testid="stickertap-remove"
                  type="button"
                  buttonStyle="btn--borderless"
                  buttonSize="btn--medium"
                  onClick={() => {
                    arrayHelpers.replace(i, {
                      ...stickerTap,
                      _destroy: true,
                    });
                  }}
                >
                  remove
                </Button>
              </div>
            )
        )}
        <Button
          data-testid="stickertap-add"
          type="button"
          icon={Plus}
          buttonStyle="btn--secondary"
          buttonSize="btn--medium"
          onClick={() =>
            arrayHelpers.push({
              name: "",
              amount: "",
            })
          }
        >
          Add Sticker
        </Button>
      </div>
    )}
  />
);

export default StickerTapInput;
