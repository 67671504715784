import React from "react";
import AddToGroupOrCampaign from "../AddToGroupOrCampaign";
import UserStatus from "../User/UserStatus";
import Avatar from "../User/Avatar";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupOpen: false,
    };
    this.toggleGroupOpen = this.toggleGroupOpen.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.closeGroupOpen = this.closeGroupOpen.bind(this);
    this.adjustPostImages = this.adjustPostImages.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.overlay && !this.props.overlay) {
      this.setState({ groupOpen: false });
    }
  }

  toggleGroupOpen(e) {
    e.stopPropagation();
    this.setState(
      ({ groupOpen }) => ({
        groupOpen: !groupOpen,
      }),
      () => {
        this.props.setOverlay(this.state.groupOpen, this.profileNode);
        // if(this.state.groupOpen) this.profileNode.scrollIntoView();
      }
    );
  }

  closeGroupOpen(e) {
    // e.stopPropagation();
    this.setState({ groupOpen: false }, () => {
      this.props.setOverlay(this.state.groupOpen, this.profileNode);
    });
  }

  renderToggleGroupImage() {
    if (this.props.overlay && this.state.groupOpen) {
      return <div className="minus-image"></div>;
    } else {
      // return <img src={PlusImage}/>;
      return <div className="plus-image"></div>;
    }
  }

  renderAddToGroupOrCampaign() {
    const {
      influencer,
      setCurrentCampaign,
      setCurrentGroup,
      currentCampaign,
      currentGroup,
    } = this.props;
    if (this.state.groupOpen && this.props.overlay) {
      return (
        <AddToGroupOrCampaign
          closeGroupOpen={this.closeGroupOpen}
          influencer={influencer}
          setCurrentCampaign={setCurrentCampaign}
          setCurrentGroup={setCurrentGroup}
          currentCampaign={currentCampaign}
          currentGroup={currentGroup}
        />
      );
    }
  }

  renderPosts() {
    return this.props.influencer.posts.map((post, i) => {
      return (
        <div key={i} data-index={i} className="post-container">
          <div className="post-image-container">
            <img src={post} />
          </div>
        </div>
      );
    });
  }

  handleRedirect() {
    window.open(`/influencers/${this.props.influencer.id}`, "_blank");
  }

  adjustPostImages() {
    $(".post-container").show();
    var numImages = Math.floor(
      ($(".profile-posts-container").innerWidth() - 10) / 68
    );
    $(".post-container").each(function (index) {
      if ($(this).data("index") + 1 > 2 * numImages) {
        $(this).hide();
      }
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.adjustPostImages);
    this.adjustPostImages();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustPostImages);
  }

  renderFollowersCount() {
    const { influencer } = this.props;

    if (influencer.follower_count >= 1000000000) {
      return `${(
        influencer.follower_count / 1000000000
      ).toLocaleString(undefined, { maximumFractionDigits: 2 })}B`;
    } else if (influencer.follower_count >= 1000000) {
      return `${(influencer.follower_count / 1000000).toLocaleString(
        undefined,
        { maximumFractionDigits: 2 }
      )}M`;
    } else {
      return `${(influencer.follower_count / 1000).toLocaleString(undefined, {
        maximumFractionDigits: 2,
      })}K`;
    }
  }

  render() {
    const { influencer } = this.props;

    return (
      <div
        className={`profile-container`}
        ref={(profile) => (this.profileNode = profile)}
      >
        <div className="profile" onClick={this.handleRedirect}>
          <div className="profile-details-container">
            <div className="profile-details">
              <Avatar
                influencer={influencer}
                size="large"
              ></Avatar>
              <div className="profile-info">
                <div className="plus-container" onClick={this.toggleGroupOpen}>
                  {this.renderToggleGroupImage()}
                </div>
                <div className="profile-info-name">
                  <h2>{influencer.full_name}</h2>
                  <UserStatus
                    isManual={influencer.is_manual}
                    status={influencer.onboarding_status}
                    message={influencer.onboarding_status_message}
                  ></UserStatus>
                </div>

                <p className="profile-info-username">
                  &#64;{influencer.instagram_username}
                </p>
              </div>
            </div>
            <div className="profile-data">
              <div className="profile-datum">
                <p>Followers: {this.renderFollowersCount()}</p>
              </div>
              <div className="profile-datum">
                <p>
                  Engagement:{" "}
                  {Math.round(influencer.average_engagement_rate * 100)}%
                </p>
              </div>
              <div className="profile-datum">
                <p>Reach: {Math.round(influencer.average_reach_rate * 100)}%</p>
              </div>
            </div>
          </div>
          <div className="profile-posts-container">{this.renderPosts()}</div>
        </div>
        {this.renderAddToGroupOrCampaign()}
      </div>
    );
  }
}

export default Profile;
