import React from "react";

class PostSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: {
        latest: true,
        most_engaged: false,
        stories: false,
      },
      data: {
        latest: this.props.latest_posts,
        most_engaged: this.props.most_engaged_posts,
        stories: this.props.latest_stories,
      },
      currentPosts: this.props.latest_posts,
    };
    this.setTab = this.setTab.bind(this);
  }

  initialTabs() {
    return {
      latest: false,
      most_engaged: false,
      stories: false,
    };
  }

  setTab(tab) {
    var tabs = this.initialTabs();
    tabs[tab] = true;
    const newPosts = this.state.data[tab];
    return () =>
      this.setState({
        tabs: tabs,
        currentPosts: newPosts,
      });
  }

  render() {
    const tabs = this.state.tabs;
    const posts = this.state.currentPosts;

    const isVideo = (url) => {
      if (!url) return false;
      return [".mp4", "video"].some((string) => url.includes(string));
    };

    const postDivs = posts.map(function (post, index) {
      const isPostVideo = isVideo(post.link) || isVideo(post.image_url);
      return (
        <div className="col-2" key={index}>
          <div className={`post-preview-div ${isPostVideo ? "video" : ''}`}>
            <a href={post.link} target="_blank">
              {isPostVideo ? (
                <video
                  style={{ height: "100px", width: "100%" }}
                  src={isVideo(post.link) ? post.link : post.image_url}
                  onMouseOver={(e) => e.target.play()}
                  onMouseOut={(e) => e.target.pause()}
                  muted
                ></video>
                ) : (
                  <img src={post.image_url} />
              )}
            </a>
          </div>
        </div>
      );
    });

    return (
      <div className="influencer-profile__stats-graph influencer-posts-box">
        <div
          className={`tab ${tabs.latest ? "active" : ""}`}
          onClick={this.setTab("latest")}
        >
          <span>POSTS</span>
        </div>
        <div
          className={`tab ${tabs.stories ? "active" : ""}`}
          onClick={this.setTab("stories")}
        >
          <span>STORIES</span>
        </div>
        <div
          className={`tab ${tabs.most_engaged ? "active" : ""}`}
          onClick={this.setTab("most_engaged")}
        >
          <span>MOST ENGAGED</span>
        </div>
        <div className="container">
          <div className="row">{postDivs}</div>
        </div>
      </div>
    );
  }
}

export default PostSection;
