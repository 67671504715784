import React from "react";
import Modal from "react-modal";
import Select from "react-select";
import * as APIUtil from "../api_util";

class CreateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement("body");
  }

  handleSubmit(e) {
    this.props.handleCreate(this.state);
  }

  handleInputChange(e) {
    this.setState({ name: e.target.value });
  }

  render() {
    const {
      isOpen,
      onRequestClose,
      contentLabel,
      client,
      type,
      modalError,
    } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel={contentLabel}
        className="react-modal campaign-status-modal"
      >
        <h1>CREATE</h1>
        {modalError && <p className="error-message">{modalError}</p>}
        <label>{`${type} NAME`}</label>
        <input
          value={this.state.name}
          onChange={this.handleInputChange}
        ></input>
        <button
          className="campaign-status-modal-button"
          onClick={this.handleSubmit}
        >
          CREATE
        </button>
      </Modal>
    );
  }
}

export default CreateModal;
