import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import moment from "moment";
import api from "../../../api";
import PostField from "../PostField";
import Select from 'react-select';
import StickerTapInput from "../StickerTapInput";
import UploadImage from "../ImageUpload";
import DatePickerField from "../DatePicker";
import Button from "../../Controls/Button";
import fields from "./fields";
import { isWithinInterval } from "date-fns";
import ReactPlayer from "react-player";

const PostForm = ({ campaign, post, postMediaType, influencer }) => {
  const [loading, setLoading] = useState(true);
  const [media, setMedia] = useState(null);
  const [mediaType, setMediaType] = useState("");
  const [postType, setPostType] = useState("");
  const [linksClicks, setLinksClicks] = useState("");
  const [photoClicks, setPhotoClicks] = useState("");
  const [otherClicks, setOtherClicks] = useState("");
  const [totalPlayTime, setTotalPlayTime] = useState("");
  const [avgWatchTime, setAvgWatchTime] = useState("");
  const [totalViews, setTotalViews] = useState("");
  const [caption, setCaption] = useState("");
  const [permalink, setPermalink] = useState("");
  const [source, setSource] = useState(null);
  const [timestamp, setTimestamp] = useState("");
  const [reach, setReach] = useState("");
  const [impressions, setImpressions] = useState("");
  const [likeCount, setLikeCount] = useState("");
  const [commentCount, setCommentCount] = useState("");
  const [views, setViews] = useState("");
  const [avgPercentageWatched, setAvgPercentageWatched] = useState("");
  const [tapsForward, setTapsForward] = useState("");
  const [tapsBack, setTapsBack] = useState("");
  const [skips, setSkips] = useState("");
  const [exits, setExits] = useState("");
  const [shares, setShares] = useState("");
  const [saves, setSaves] = useState("");
  const [swipeUps, setSwipeUps] = useState("");
  const [stickerTaps, setStickerTaps] = useState([]);
  const [apiErrors, setApiErrors] = useState(null);

  const isStory = mediaType && mediaType.includes("story");

  useEffect(() => {
    if (post) {
      setMedia(post.media_url);
      setMediaType(post.media_type);
      setPostType(post.post_type);
      setPhotoClicks(post.photo_clicks);
      setOtherClicks(post.other_clicks);
      setLinksClicks(post.links_clicks);
      setTotalPlayTime(post.total_play_time);
      setAvgWatchTime(post.avg_watch_time);
      setTotalViews(post.total_views);
      setCaption(post.caption);
      setPermalink(post.permalink);
      setSource(post.source);
      setTimestamp(moment(post.timestamp).toDate());
      setReach(post.reach);
      setImpressions(post.impressions);
      setLikeCount(post.like_count ?? 0);
      setCommentCount(post.comment_count);
      setViews(post.views);
      setAvgPercentageWatched(post.avg_percentage_watched ?? 0);
      setTapsForward(post.taps_forward ?? 0);
      setTapsBack(post.taps_back ?? 0);
      setSkips(post.skips ?? 0);
      setExits(post.exits ?? 0);
      setShares(post.shares ?? 0);
      setSaves(post.saves ?? 0);
      setSwipeUps(post.swipe_ups ?? 0);
      setStickerTaps(post.sticker_taps ?? []);
    } else if (postMediaType) {
      const postMediaTypeArr = postMediaType.split(",");
      setMediaType(postMediaTypeArr[1]);
      setPostType(postMediaTypeArr[0]);
      setSource("manual");
      setTimestamp(moment(campaign.end_date).toDate());
    }
    setLoading(false);
  }, []);

  const state = {
    reach,
    impressions,
    likeCount,
    commentCount,
    tapsForward,
    tapsBack,
    skips,
    exits,
    shares,
    saves,
    swipeUps,
    stickerTaps,
    caption,
    permalink,
    timestamp,
    media,
    views,
    photoClicks,
    otherClicks,
    linksClicks,
    postType,
    totalPlayTime,
    avgWatchTime,
    totalViews,
    avgPercentageWatched,
    mediaType,
    mediaTypes: [
      { value: 'image', label: 'Image' },
      { value: 'video', label: 'Video' },
      { value: 'carousel', label: 'Carousel' },
      { value: 'story_image', label: 'Story Image' },
      { value: 'story_video', label: 'Story Video' }
    ],
  };

  const influencerPage = `/campaigns/${campaign.id}/influencers/${influencer.id}`;

  const handleSubmit = (formValues) => {
    // TODO: fix the timestamp in the UI ticket
    // since it has to change anyway
    const { timestamp, ...vals } = formValues;

    // fields that aren't submitted for a given post type
    const altValues = isStory
      ? {
          saves,
          likeCount,
          views,
          avgPercentageWatched,
        }
      : {
          swipeUps,
          tapsBack,
          tapsForward,
          skips,
          exits,
        };

    // the backend expects the `post` payload to match, so we append
    // all values not coming from the form to an object for that given post
    const values = {
      ...vals,
      ...altValues,
      // timestamp is stored in server in UTC at 12:00 hours to render correctly
      timestamp: moment(formValues.timestamp)
        .utc()
        .set("hour", 12)
        .toISOString(),
      mediaType,
      source: source,
      stickerTaps: formValues.stickerTaps.map(({ show, ...rest }) => rest), // exclude the 'show' prop, since backend doesn't use it
    };

    const mediaIsString = typeof values.media === "string";

    if (post) {
      const { media, ...rest } = values;
      // omit 'media' from the payload if it's a string
      const omitMedia = mediaIsString ? rest : values;

      api
        .patch(`${influencerPage}/posts/${post.id}`, { post: omitMedia })
        // TODO: revisit this to ensure its the behavior we want here
        .then(() => (window.location.href = influencerPage))
        .catch((err) => handleApiError(err));
    } else {
      const postKeys = Object.keys(values);
      postKeys.forEach((key) => {
        if (!values[key]) {
          delete values[key];
        }
      });
      api
        .post(`${influencerPage}/posts`, { post: values })
        // TODO: revisit this to ensure its the behavior we want here
        .then(() => (window.location.href = influencerPage))
        .catch((err) => handleApiError(err));
    }
  };

  const validateTimestamp = (value) => {
    let error;

    // allow from startDate.beginning_of_day until end_date.end_of_day
    const isInRange = isWithinInterval(value, {
      start: moment(campaign.start_date).utc().set("hour", 0).toDate(),
      end: moment(campaign.end_date).utc().set("hour", 23).toDate(),
    });

    if (!isInRange) {
      error = "Date out of range.";
    }

    return error;
  };

  const validationSchema = Yup.object({
    media: Yup.mixed(),
    mediaType: Yup.string(),
    caption: Yup.string().nullable(),
    reach: Yup.number(),
    impressions: Yup.number(),
    commentCount: Yup.number(),
    views: Yup.number(),
    links_clicks: Yup.number(),
    photo_clicks: Yup.number(),
    other_clicks: Yup.number(),
    total_play_time: Yup.number(),
    avg_watch_time: Yup.number(),
    total_views: Yup.number(),
    avgPercentageWatched: Yup.number(),
    // TODO: figure this out later??
    // permalink:
    //   "https?://(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)",
    timestamp: Yup.date(),
    tapsForward: Yup.number(),
    tapsBack: Yup.number(),
    exits: Yup.number(),
    shares: Yup.number(),
    swipeUps: Yup.number(),
    stickerTaps: Yup.array().of(
      Yup.object({
        id: Yup.number(),
        name: Yup.string(),
        amount: Yup.number(),
        show: Yup.boolean(),
        _destroy: Yup.boolean(),
      })
    ),
    skips: Yup.number(),
    likeCount: Yup.number(),
    saves: Yup.number(),
  });

  const handleApiError = (err) => {
    const formLevelError = err.response.status === 422;
    const errors = err.response.data.errors.map((item, i) =>
      item.replace("Permalink", "Link")
    );

    setApiErrors(errors);
  };

  const isManual = () => source === "manual";

  const isEditable = () => source === "public_api";

  const handleSelectChange = () => {
    return selectedOption => {
      setMediaType(selectedOption.value);
    }
  }

  const getOptions = (key) => {
    if(state[key] && state[key].length > 0) {
      const options = state[key].map(value => {
        return { value: value.value, label: value.label };
      });
      return [...options];
    }
  }

  const getPostType = () => {
    if (isStory) {
      return "Story";
    }

    const options = {
      InstagramPost:
        mediaType === "video" ? "Instagram Video Post" : "Instagram Post",
      YoutubePost: "YouTube Post",
      IgtvPost: "IGTV Post",
      IgReelPost: "Ig Reel Post",
      FacebookPost: "Facebook Post",
      AmplifiedFacebookPost: "Amplified Facebook Post",
      AmplifiedInstagramPost: "Amplified Instagram Post",
      TiktokPost: "TikTok Post",
    };

    return options[postType];
  };

  return (
    <div className="post-form-container container">
      {loading ? (
        // TODO: better loading handlers
        <p>Loading...</p>
      ) : (
        <Formik
          enableReinitialize
          initialValues={state}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ values, isSubmitting, isValid }) => (
            <>
              <p>{isValid}</p>
              <div className="col-4">
                {postType === "YoutubePost" ? (
                  <ReactPlayer url={permalink} width="100%" />
                ) : (
                  <UploadImage name="media" />
                )}
              </div>
              <Form className="post-form">
                <div className="stickertaps--container post-story-info-container">
                  <h3 className="edit-post-form--header">
                    {getPostType()} Information
                  </h3>
                  <DatePickerField
                    name="timestamp"
                    campaignStartDate={campaign.start_date}
                    campaignEndDate={campaign.end_date}
                    validate={validateTimestamp}
                    isManual={isManual()}
                    isEditable={isEditable()}
                  />
                  {postType === "AmplifiedFacebookPost" || postType === "AmplifiedInstagramPost" ? (
                    <div className="edit-post-form--label group-selects">
                      <label>
                        <span className="label">Media Type</span>
                        <Select
                          name="mediaType"
                          className="select-container"
                          classNamePrefix="select"
                          options={getOptions('mediaTypes')}
                          value={getOptions('mediaTypes')?.find(option => option.value === mediaType)}
                          isClearable
                          onChange={handleSelectChange()}
                        />
                      </label>
                    </div>
                  ) : (
                    <div />
                  )}
                  <PostField
                    isTextarea
                    name="caption"
                    label="Caption"
                    isManual={isManual()}
                    isEditable={isEditable()}
                  />
                  <PostField
                    name="permalink"
                    type="text"
                    label="Link"
                    isManual={isManual()}
                    isEditable={isEditable()}
                    link={permalink}
                  />
                </div>
                <div className="stickertaps--container">
                  <h3 className="edit-post-form--header">Stats</h3>
                  <div className="post-stats">
                    {fields(isManual(), isEditable(), isStory, postType)
                      .sort((a, b) => a.orderNumber - b.orderNumber)
                      .map((field) => (
                        <PostField
                          key={field.id}
                          label={field.label}
                          name={field.name}
                          isManual={field.isManual}
                          isEditable={field.isEditable}
                          span={field.span}
                        />
                      ))}
                  </div>
                </div>
                {apiErrors?.length > 0
                  ? apiErrors.map((error, i) => (
                      <p
                        key={`error-${i}`}
                        className="influencer-form--error-text"
                      >
                        {error}
                      </p>
                    ))
                  : null}
                {isStory && (
                  <div className="stickertaps--container">
                    <h3 className="edit-post-form--header">Sticker Taps</h3>
                    <StickerTapInput attributes={values.stickerTaps} />
                  </div>
                )}
                <div className="post-btn-container">
                  <Button
                    className="save-btn"
                    buttonStyle="btn--primary"
                    buttonSize="btn--medium"
                    type="submit"
                    disabled={isSubmitting || !isValid}
                  >
                    SAVE
                  </Button>
                  <Button
                    className="cancel-btn"
                    buttonStyle="btn--secondary"
                    buttonSize="btn--medium"
                    type="button"
                    onClick={() => (window.location.href = influencerPage)}
                  >
                    CANCEL
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      )}
    </div>
  );
};

// workaround for react_on_rails not detecting function components
// https://github.com/shakacode/react_on_rails/issues/1198#issuecomment-483784251
export default (props) => <PostForm {...props} />;
