import React from "react";
import Profile from "./Profile";
import Waypoint from "react-waypoint";

class ProfileFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      overlay: false,
      currentGroup: null,
      currentCampaign: null
    };
    this.setOverlay = this.setOverlay.bind(this);
    this.closeOverlay = this.closeOverlay.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {}

  renderProfiles() {
    if (this.props.influencers.length > 0) {
      return this.props.influencers.map((influencer, i) => {
        return (
          <Profile
            key={i}
            overlay={this.state.overlay}
            setOverlay={this.setOverlay}
            influencer={influencer}
            setCurrentCampaign={this.setCurrentCampaign}
            setCurrentGroup={this.setCurrentGroup}
            currentCampaign={this.state.currentCampaign}
            currentGroup={this.state.currentGroup}
          />
        );
      });
    } else {
      return (
        <div className="profile-container">
          <p className="no-results">No influencers match your filters!</p>
        </div>
      );
    }
  }

  overlayClass() {
    if (!this.state.overlay) return "hidden";
    return "";
  }

  setClasses() {
    if (this.state.overlay) return "prevent-scroll group-opened";
    return "";
  }

  setOverlay(groupOpen, profileNode) {
    const scrollOptions = {
      behavior: "smooth",
      block: "start",
      inline: "start"
    };
    if (groupOpen) {
      this.setState({ overlay: true }, () => {
        if (profileNode) {
          profileNode.scrollIntoView(scrollOptions);
          window.profileNode = profileNode;
        }
      });
    } else {
      this.setState({ overlay: false });
    }
  }

  closeOverlay() {
    this.setState({ overlay: false });
  }

  renderWaypoint() {
    const { isLoading, fetchMorePosts, totalCount, influencers } = this.props;
    if (!isLoading && influencers && influencers.length < totalCount) {
      return (
        <Waypoint
          onEnter={({ currentPosition }) => {
            fetchMorePosts();
          }}
          threshold={2.0}
        ></Waypoint>
      );
    }
  }

  renderSpinner() {
    if (this.props.isLoading) {
      return <div className="loader"></div>;
    }
  }

  setCurrentGroup = group => {
    this.setState({ currentGroup: group });
  };
  setCurrentCampaign = campaign => {
    this.setState({ currentCampaign: campaign });
  };

  render() {
    return (
      <div className={`profile-feed`}>
        <div className={`profile-list ${this.setClasses()}`}>
          {this.renderProfiles()}
          {this.renderWaypoint()}
          {this.renderSpinner()}
        </div>
        <div
          className={`profile-feed__overlay ${this.overlayClass()}`}
          onClick={this.closeOverlay}
        ></div>
      </div>
    );
  }
}

export default ProfileFeed;
