import React from "react";
import Masonry from "react-masonry-component";
import Post from "./Post";

const masonryOptions = {};

class ReportGallery extends React.Component {
  renderPosts() {
    return this.props.posts.map((post, index) => {
      return (
        <Post
          key={index}
          post={post}
          first_name={post.influencer.first_name}
          last_name={post.influencer.last_name}
          post_type={this.props.postType}
        />
      );
    });
  }

  render() {
    return (
      <Masonry
        className="post-gallery report-gallery"
        updateOnEachImageLoad={true}
      >
        {this.renderPosts()}
      </Masonry>
    );
  }
}

export default ReportGallery;
