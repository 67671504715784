import React from "react";
import Modal from "react-modal";
import Select from "react-select";
import * as APIUtil from "../../api_util";
import AddImage from "images/add@2x.png";

class AddToCampaignModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: null,
      searchResults: [],
      campaign: null,
    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement("body");
    APIUtil.fetchCampaigns().then((campaigns) => {
      this.setState({ searchResults: Object.values(campaigns) });
    });
  }

  componentDidUpdate(prevProps, prevState) {}

  handleSubmit(e) {
    const { groupIds } = this.props;
    const { campaign } = this.state;
    APIUtil.createCampaignInfluencersByGroup(groupIds, campaign.value).then(
      (response) => {
        this.props.closeModal();
      },
      (errors) => {
        console.log(errors);
      }
    );
  }

  handleSelectChange(category) {
    return (selectedOption) => {
      this.setState({ [category]: selectedOption });
    };
  }

  getOptions(key) {
    if (this.state[key] && this.state[key].length > 0) {
      const options = this.state[key].map((value) => {
        return { value: value.id, label: value.name };
      });
      return [{ value: null, label: "SELECT" }, ...options];
    }
  }

  render() {
    const { groupIds, isOpen, onRequestClose, contentLabel } = this.props;
    const { searchResults } = this.state;
    if (groupIds) {
      return (
        <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          contentLabel={contentLabel}
          className="react-modal campaign-status-modal"
        >
          <h1>ADD TO CAMPAIGN</h1>
          <label>CAMPAIGN NAME</label>
          <Select
            className="select-container"
            classNamePrefix="select"
            options={this.getOptions("searchResults")}
            value={this.state.campaign}
            onChange={this.handleSelectChange("campaign")}
            isSearchable={true}
          />
          <button
            className="campaign-status-modal-button"
            onClick={this.handleSubmit}
          >
            ADD
          </button>
        </Modal>
      );
    } else {
      return null;
    }
  }
}

export default AddToCampaignModal;
