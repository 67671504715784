import React from "react";
import MultiSelect from "../components/MultiSelect";

const Interests = ({ interests }) => (
  <div className="influencer-form--section">
    <div className="influencer-form--section-header">
      <h3 className="edit-post-form--header">Interests</h3>
    </div>
    <div
      className="influencer-form--section-content"
      style={{ maxWidth: "498px" }}
    >
      <div className="influencer-form--section-row">
        <MultiSelect name="interestIds" items={interests} />
      </div>
    </div>
  </div>
);

export default Interests;
