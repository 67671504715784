import React from "react";
import CloseImage from "images/modal-close@2x.png";
import UploadImage from "images/upload@2x.png";
import Modal from "react-modal";
import Select from "react-select";

class UploadMediaModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      postType: { value: ["AmplifiedFacebookPost", "video"], label: "Amplified Facebook Post" },
    };
  }

  componentDidMount() {
    Modal.setAppElement("body");
  }

  toggleModal = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  };
  closeModal = () => this.setState({ modalIsOpen: false });
  handleSelect = (postType) => {
    this.setState({ postType });
  };

  redirectToForm = () => {
    const { campaign, influencer } = this.props;
    window.location.href = `/campaigns/${campaign.id}/influencers/${influencer.id}/posts/new?media_type=${this.state.postType.value}`;
  };
  render() {
    return (
      <div>
        <a className="upload-link" onClick={this.toggleModal}>
          <span className="upload-text">Upload</span>
          <img src={UploadImage} className="img-fluid upload-img" />
        </a>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Upload Media Modal"
          className="react-modal campaign-status-modal"
        >
          <h1>UPLOAD MEDIA</h1>
          <div className="close" onClick={this.closeModal}>
            <img src={CloseImage} className="img-fluid" />
          </div>
          <label>
            <p>TYPE OF MEDIA</p>
            <Select
              className="select-container"
              classNamePrefix="select"
              value={this.state.postType}
              onChange={this.handleSelect}
              options={[
                { value: ["AmplifiedFacebookPost", "video"], label: "Amplified Facebook Post" },
                { value: ["AmplifiedInstagramPost", "image"], label: "Amplified Instagram Post" },
                { value: ["FacebookPost", "video"], label: "Facebook Post" },
                { value: ["IgtvPost", "video"], label: "IGTV Post" },
                { value: ["InstagramPost", "image"], label: "Instagram Post" },
                { value: ["IgReelPost", "video"], label: "Instagram Reel Post" },
                { value: ["InstagramPost", "story_image"], label: "Instagram Story (Image)" },
                { value: ["InstagramPost", "story_video"], label: "Instagram Story (Video)" },
                { value: ["InstagramPost", "video"], label: "Instagram Video" },
                { value: ["TiktokPost", "video"], label: "TikTok Post" },
                { value: ["YoutubePost", "video"], label: "YouTube Post" },
              ]}
            />
          </label>
          <button
            className="campaign-status-modal-button"
            onClick={this.redirectToForm}
          >
            ADD DETAILS
          </button>
        </Modal>
      </div>
    );
  }
}

export default UploadMediaModal;
