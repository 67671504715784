import React from "react";
import { useField } from "formik";
import ExternalLink from "icons/external-link.svg";

const PostField = ({ label, span, ...props }) => {
  const [field, meta] = useField(props);

  const renderLink = () => (
    <a
      href={props.link}
      rel="noopener noreferrer"
      target="_blank"
      className="label-link"
    >
      Open
      <img className="icon" src={ExternalLink} />
    </a>
  );

  const renderInput = () =>
    props.isTextarea ? (
      <textarea
        className="edit-post-form--input"
        aria-labelledby={label}
        disabled={!props.isEditable && !props.isManual}
        {...field}
        {...props}
      />
    ) : (
      <input
        className="edit-post-form--input"
        aria-labelledby={label}
        aria-hidden={Boolean(span === 0)}
        type={props.type ?? "number"}
        disabled={!props.isEditable && !props.isManual}
        {...field}
        {...props}
      />
    );

  const renderLabel = () =>
    props.link && props.isManual ? (
      <div className="multi-label">
        <span className="label">{label}</span>
        {renderLink()}
      </div>
    ) : (
      <span className="label">{label}</span>
    );

  const renderStatic = () =>
    props.isTextarea ? (
      <textarea
        className="edit-post-form--input"
        aria-labelledby={label}
        disabled={!props.isEditable && !props.isManual}
        {...field}
        {...props}
      />
    ) : (
      <input
        className="edit-post-form--input"
        aria-labelledby={label}
        aria-hidden={Boolean(span === 0)}
        type={props.type ?? "number"}
        disabled={!props.isEditable && !props.isManual}
        {...field}
        {...props}
      />
    );

  return (
    <label className={`edit-post-form--label input span-${span}`}>
      {renderLabel()}
      {props.isManual || props.isEditable ? (
        <>
          {meta.touched && meta.error ? <div>{meta.error}</div> : null}
          {renderInput()}
        </>
      ) : (
        renderStatic()
      )}
    </label>
  );
};

export default PostField;
