import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import moment from "moment";
import Calendar from "icons/calendar.svg";

const DatePickerField = ({ label, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  const hasError = meta.touched && meta.error;

  return (
    <div className="edit-post--datepicker-container">
      <label className="influencer-form--field-label influencer-form--field-label-datepicker">
        <span className={hasError ? "label error" : "label"}>
          {label}
          <span style={{ color: "red" }}>{props.isRequired && "*"}</span>
        </span>
        <div className="input-group">
          <DatePicker
            {...field}
            {...props}
            startDate={moment(field.value).valueOf()}
            dateFormat="MMM d, yyyy"
            selected={(field.value && new Date(field.value)) || null}
            onChange={(val) => {
              setFieldValue(field.name, val);
            }}
            className={`edit-post-form--input form-control edit-post--datepicker ${
              hasError && "edit-post--datepicker-error"
            }`}
          />
          <div className="input-group-append edit-post--datepicker-append">
            <span
              className={`input-group-text edit-post--datepicker-icon ${
                hasError && "edit-post--datepicker-icon-error"
              }`}
            >
              <img src={Calendar} />
            </span>
          </div>
        </div>
      </label>
    </div>
  );
};

export default DatePickerField;
