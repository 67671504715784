import React from "react";
import Cross from "icons/cross.svg";
import Plus from "icons/plus.svg";
import Minus from "icons/minus.svg";
import Refresh from "icons/refresh.svg";
import Button from "../Controls/Button";
import Dropdown from "../Controls/Dropdown";
import DropdownItem from "../Controls/DropdownItem";
import MagnifyGlassImage from "images/magnify-glass-dark@2x.png";
import InstagramLogo from "images/instagram@2x.png";
import * as APIUtil from "../../api_util";
import mapValues from "lodash.mapvalues";
import Checkbox from "../Checkbox";
import { selectChecked, getSelectedCount, goBack } from "../../util";
import isEmpty from "lodash.isempty";
import merge from "lodash/merge";
import User from "../User/User";
import UserStatus from "../User/UserStatus";
import Avatar from "../User/Avatar";

class Campaign extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      influencers: {},
      isInfluencerFormOpen: false,
      isSelectedOpen: false,
      isRefreshing: false,
      isLinksOpen: false,
      influencerInput: "",
      checkBoxes: {
        all: false,
      },
      isInfluencersOpen: false,
      influencersResult: [],
      selectedInfluencers: [],
      isLoading: false,
    };

    this.toggleInfluencerForm = this.toggleInfluencerForm.bind(this);
    this.closeInfluencerForm = this.closeInfluencerForm.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleRemoveInfluencers = this.handleRemoveInfluencers.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeInfluencersResult = this.closeInfluencersResult.bind(this);
    this.focusSearchInput = this.focusSearchInput.bind(this);
    this.addInfluencersToCampaign = this.addInfluencersToCampaign.bind(this);
    this.removeSelectedInfluencer = this.removeSelectedInfluencer.bind(this);
    this.closeDropdowns = this.closeDropdowns.bind(this);
    this.refreshData = this.refreshData.bind(this);
  }

  componentDidMount() {
    if (this.props.campaign && this.props.campaign.id) {
      this.setState({ isLoading: true });
      APIUtil.fetchCampaignInfluencers(this.props.campaign.id).then(
        (influencers) => {
          const checkBoxes = {
            ...mapValues(influencers, () => false),
            all: false,
          };
          this.setState({ influencers, checkBoxes, isLoading: false });
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      checkBoxes,
      selectedInfluencer,
      influencerInput,
      isInfluencersOpen,
    } = this.state;
    //uncheck 'all'
    if (prevState.checkBoxes.all && !checkBoxes.all) {
      let newCheckBoxes = Object.assign({}, checkBoxes);
      newCheckBoxes = mapValues(newCheckBoxes, () => false);
      newCheckBoxes = Object.assign({}, newCheckBoxes);
      this.setState({ checkBoxes: newCheckBoxes });
    }
    //check 'all'
    if (!prevState.checkBoxes.all && checkBoxes.all) {
      let newCheckBoxes = Object.assign({}, checkBoxes);
      newCheckBoxes = mapValues(newCheckBoxes, () => true);
      newCheckBoxes = Object.assign({}, newCheckBoxes);
      this.setState({ checkBoxes: newCheckBoxes });
    }
    if (!isEmpty(prevState.selectedInfluencer) && isEmpty(selectedInfluencer)) {
      this.setState({ influencerInput: "" });
    }
    if (prevState.influencerInput !== influencerInput) {
      this.setState({ isInfluencersOpen: false });
    }
  }

  closeInfluencersResult(e) {
    // e.preventDefault();
    this.setState({ isInfluencersOpen: false });
  }

  handleClickOutside() {
    this.closeDropdowns();
  }

  closeDropdowns() {
    this.setState({ isSelectedOpen: false, isLinksOpen: false });
  }

  redirectToShowPage(id) {
    return () => {
      window.location.href = `/influencers/${id}`;
    };
  }

  refreshData(e) {
    e.stopPropagation();
    this.setState(({ isRefreshing }) => ({
      isRefreshing: !isRefreshing,
    }));

    APIUtil.refreshCampaignInfluencers(this.props.campaign.id).then(
      (influencers) => {
        const checkBoxes = {
          ...mapValues(influencers, () => false),
          all: false,
        };
        this.setState({ influencers, checkBoxes, isRefreshing: false });
      }
    );
  }

  closeInfluencerForm(e) {
    e.stopPropagation();
    this.setState({
      isInfluencerFormOpen: false,
      influencerInput: "",
      isInfluencersOpen: false,
      selectedInfluencers: [],
    });
  }

  toggleInfluencerForm(e) {
    e.stopPropagation();
    this.setState(({ isInfluencerFormOpen }) => ({
      isInfluencerFormOpen: !isInfluencerFormOpen,
    }));
  }
  toggleDropdown(dropdown) {
    return (e) => {
      this.setState({ [dropdown]: !this.state[dropdown] });
    };
  }
  renderAddInfluencerButton() {
    const { isInfluencerFormOpen } = this.state;

    return (
      <Button
        onClick={this.toggleInfluencerForm}
        type="button"
        icon={isInfluencerFormOpen ? Minus : Plus}
        buttonStyle="btn--secondary"
        buttonSize="btn--medium"
        className="table-menu-button"
      >
        Add an Influencer
      </Button>
    );
  }

  renderDropdownButtons() {
    const { checkBoxes } = this.state;
    const { campaign } = this.props;

    return (
      <React.Fragment>
        <Dropdown
          className="table-menu-button"
          name={"Selected (" + getSelectedCount(checkBoxes) + ")"}
        >
          <DropdownItem
            name="Delete"
            onClick={this.handleRemoveInfluencers}
          ></DropdownItem>
        </Dropdown>

        <Dropdown className="table-menu-button" name="Reports">
          <DropdownItem
            name="Generate CSV"
            onClick={() => window.open(`/campaigns/${campaign.id}/export.csv`)}
            target="_blank"
          ></DropdownItem>
          <DropdownItem
            name="One Sheet"
            onClick={() =>
              window.open(`/campaigns/${campaign.id}/generate_list`)
            }
            target="_blank"
          ></DropdownItem>
          <DropdownItem
            name="Campaign Report"
            onClick={() =>
              window.open(`/campaigns/${campaign.id}/generate_report`)
            }
            target="_blank"
          ></DropdownItem>
        </Dropdown>
      </React.Fragment>
    );
  }

  handleRemoveInfluencers(e) {
    e.stopPropagation();
    const { campaign } = this.props;
    const checkBoxes = Object.assign({}, this.state.checkBoxes);
    const ids = selectChecked(checkBoxes);
    const influencers = Object.assign({}, this.state.influencers);
    APIUtil.deleteCampaignInfluencers(ids, campaign.id).then(
      (response) => {
        ids.forEach((id) => {
          delete checkBoxes[id];
          delete influencers[id];
        });
        checkBoxes.all = false;
        this.setState({ checkBoxes, influencers });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  renderSelectDropdown() {
    if (this.state.isSelectedOpen) {
      return (
        <div className="dropdown campaign-menu__dropdown">
          <ul>
            <li onClick={this.handleRemoveInfluencers}>DELETE</li>
          </ul>
        </div>
      );
    }
  }

  handleInputChange(e) {
    this.setState({ influencerInput: e.target.value }, this.handleSubmit);
  }

  handleSubmit() {
    APIUtil.searchInfluencers(this.state.influencerInput).then((response) => {
      this.setState({
        isInfluencersOpen: true,
        influencersResult: response.influencers,
      });
    });
  }

  addInfluencersToCampaign(e) {
    const { campaign } = this.props;
    const { selectedInfluencers } = this.state;
    const ids = selectedInfluencers.map((influencer) => influencer.id);
    e.preventDefault();
    e.stopPropagation();
    if (selectedInfluencers.length > 0) {
      APIUtil.createCampaignInfluencers(ids, campaign.id).then((response) => {
        const newObj = {};
        selectedInfluencers.forEach((influencer) => {
          newObj[influencer.id] = influencer;
        });
        const newInfluencers = merge({}, this.state.influencers, newObj);
        APIUtil.fetchCampaignInfluencers(campaign.id).then((influencers) => {
          const checkBoxes = {
            ...mapValues(influencers, () => false),
            all: false,
          };
          this.setState({
            influencers,
            checkBoxes,
            influencerInput: "",
            isInfluencerFormOpen: false,
            selectedInfluencers: [],
          });
        });
      });
    }
  }

  selectInfluencer(influencer) {
    const newInfluencers = [...this.state.selectedInfluencers];
    let exists = false;
    for (let i = 0; i < newInfluencers.length; i++) {
      if (newInfluencers[i].id === influencer.id) {
        exists = true;
      }
    }
    if (!exists) {
      newInfluencers.push(influencer);
    }
    return (e) => {
      e.preventDefault();
      this.setState({
        influencersResult: [],
        isInfluencersOpen: false,
        influencerInput: "",
        selectedInfluencers: newInfluencers,
      });
    };
  }

  renderInfluencersResult() {
    const { influencersResult, isInfluencersOpen } = this.state;
    if (isInfluencersOpen) {
      if (influencersResult.length > 0) {
        return influencersResult.map((influencer, i) => {
          return (
            <li
              key={i}
              onClick={this.selectInfluencer(influencer)}
              className="flex pointer"
            >
              <div className="profile-image-container">
                <img src={influencer.profile_picture_url} />
              </div>
              <div className="vert-center">
                <p>{influencer.full_name}</p>
              </div>
            </li>
          );
        });
      } else {
        return (
          <li>
            <div className="vert-center">
              <div className="no-result">No Result</div>
            </div>
          </li>
        );
      }
    }
  }

  renderInfluencerDropdown() {
    if (this.state.isInfluencersOpen) {
      return (
        <div className="influencer-dropdown">
          <ul>{this.renderInfluencersResult()}</ul>
        </div>
      );
    }
  }

  removeSelectedInfluencer(index) {
    const newInfluencers = [...this.state.selectedInfluencers];
    newInfluencers.splice(index, 1);
    return (e) => {
      e.stopPropagation();
      this.setState({ selectedInfluencers: newInfluencers });
    };
  }

  renderMultiValues() {
    const { selectedInfluencers } = this.state;
    if (selectedInfluencers.length > 0) {
      return selectedInfluencers.map((influencer, i) => {
        return (
          <div key={i} className="multi-value-container">
            <div className="multi-value">
              <div className="multi-value__label">
                <div className="profile-image-container">
                  <img src={influencer.profile_picture_url} />
                </div>
                <div className="name-container">{influencer.full_name}</div>
              </div>
              <div
                className="multi-value__remove"
                onClick={this.removeSelectedInfluencer(i)}
              >
                <img className="small-icon" src={Cross} />
              </div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="search-input__indicator vert-center">
          <div className="search-image-container">
            <img src={MagnifyGlassImage} />
          </div>
        </div>
      );
    }
  }

  searchInfluencerPlaceholder() {
    if (!this.state.selectedInfluencers.length > 0) {
      return "Search";
    }
  }

  focusSearchInput() {
    this.searchInput.focus();
  }

  isButtonDisabled() {
    if (this.state.selectedInfluencers.length > 0) {
      return false;
    } else return true;
  }

  renderAddInfluencerForm() {
    if (this.state.isInfluencerFormOpen) {
      return (
        <div className="influencer-form">
          <h1>Add Influencer</h1>
          <div className="flex">
            <div>
              <div className="search-input" onClick={this.focusSearchInput}>
                {this.renderMultiValues()}
                <input
                  tabIndex="0"
                  ref={(input) => (this.searchInput = input)}
                  placeholder={this.searchInfluencerPlaceholder()}
                  value={this.state.influencerInput}
                  onChange={this.handleInputChange}
                ></input>
                {this.renderInfluencerDropdown()}
              </div>
            </div>
            <div className="vert-center">
              <div className="form-btns">
                <Button
                  onClick={this.addInfluencersToCampaign}
                  type="button"
                  buttonStyle="btn--primary"
                  buttonSize="btn--medium"
                  disabled={this.isButtonDisabled()}
                >
                  Save & Continue
                </Button>

                <Button
                  onClick={this.closeInfluencerForm}
                  type="button"
                  buttonStyle="btn--secondary"
                  buttonSize="btn--medium"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  redirectToPostsPage(influencer_id) {
    return () => {
      window.location.href = `/campaigns/${this.props.campaign.id}/influencers/${influencer_id}`;
    };
  }

  openInstagramPage(influencer) {
    return () => {
      window.open(
        `https://www.instagram.com/${influencer.instagram_username}`,
        "_blank"
      );
    };
  }

  renderUploadedPosts(influencer) {
    try {
      const { posts } = influencer;

      if (!posts.length) {
        return <span>NA</span>;
      } else {
        return <span>{posts.length} Items</span>;
      }
    } catch (error) {
      return <span>NA</span>;
    }
  }

  renderInfluencers() {
    const influencers = Object.values(this.state.influencers);
    if (influencers.length > 0) {
      return influencers.map((influencer, index) => {
        return (
          <div key={index} className="row influencer-row">
            <div className="vert-center cardo border--rt col-3">
              <div className="flex">
                <div className="vert-center">
                  <Checkbox
                    handleCheck={this.handleCheck(influencer.id)}
                    checked={this.state.checkBoxes[influencer.id]}
                  />
                </div>
                <div
                  className="flex pointer"
                  onClick={() =>
                    window.open(`/influencers/${influencer.id}`, "_blank")
                  }
                >
                  <Avatar influencer={influencer} size="small"></Avatar>
                  <User name={influencer.full_name}>
                    <UserStatus
                      isManual={influencer.is_manual}
                      status={influencer.onboarding_status}
                      message={influencer.onboarding_status_message}
                    ></UserStatus>
                  </User>
                </div>
              </div>
            </div>
            <div className="vert-center cardo border--rt col-4">
              <span>{influencer.email}</span>
            </div>
            <div className="vert-center cardo border--rt col-1 solo-insta-logo-container">
              <div
                className="media-logo-container"
                style={{ marginRight: "0px" }}
                onClick={this.openInstagramPage(influencer)}
              >
                <img src={InstagramLogo} />
              </div>
            </div>
            <div
              className="vert-center cardo col-4 hover"
              onClick={this.redirectToPostsPage(influencer.id)}
            >
              <div className="flex">{this.renderUploadedPosts(influencer)}</div>
            </div>
          </div>
        );
      });
    } else {
      if (this.state.isLoading) return <div className="loader"></div>;
      return (
        <div className="row no-results-row">
          <div className="vert-center col-12">
            <p>
              No influencers added yet. Add via{" "}
              <a href="/">Profile Discovery</a>, or add directly by name above.
            </p>
          </div>
        </div>
      );
    }
  }

  handleCheck(id) {
    return (e) => {
      e.stopPropagation();
      const newCheckBoxes = Object.assign({}, this.state.checkBoxes);
      newCheckBoxes[id] = !newCheckBoxes[id];
      this.setState({ checkBoxes: newCheckBoxes });
    };
  }

  goToCampaignsIndex = () => {
    window.location.href = "/campaigns";
  };

  render() {
    const { campaign } = this.props;
    const { checkBoxes } = this.state;
    const { isRefreshing } = this.state;

    return (
      <div onClick={this.closeInfluencersResult}>
        <div className="table-menu">
          {this.renderDropdownButtons()}
          {this.renderAddInfluencerButton()}

          <Button
            onClick={this.refreshData}
            type="button"
            icon={Refresh}
            buttonStyle="btn--secondary"
            buttonSize="btn--medium"
            className={`table-menu-button btn-right ${
              isRefreshing ? "btn-icon-spinning" : ""
            }`}
          >
            {!isRefreshing ? "Refresh" : "Refreshing"}
          </Button>
        </div>
        {this.renderAddInfluencerForm()}
        <div
          className="influencer-table socialyte-table"
          onClick={this.closeDropdowns}
        >
          <div className="back-link" onClick={this.goToCampaignsIndex}>
            <span className="less-than">&lt;</span>
            <span>Back</span>
          </div>
          <div className="row influencer-head-row socialyte-head-row">
            <div className="col-name col-3">
              <Checkbox
                handleCheck={this.handleCheck("all")}
                checked={checkBoxes.all}
                label="INFLUENCER"
              />
            </div>
            <div className="col-name col-4">
              <span>EMAIL</span>
            </div>
            <div className="col-name col-1 text-center">
              <span>INSTA</span>
            </div>
            <div className="col-name col-4">
              <span>UPLOADED POSTS</span>
            </div>
          </div>
          {this.renderInfluencers()}
        </div>
      </div>
    );
  }
}

export default Campaign;
