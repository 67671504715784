import React from "react";
import InstagramImage from "images/instagram@2x.png";
import { prettyNum } from "../../util";
import * as APIUtil from "../../api_util";

class CampaignCreators extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.influencers) {
      this.state = {
        influencers: this.props.influencers.slice(0, 5),
        page: 1,
        totalPage: Math.ceil(this.props.influencers.length / 5),
        totalCount: this.props.influencers.length,
      };  
    }
    else {
      this.state = {
        influencers: [],
        page: 1,
        totalPage: 0,
        totalCount: 0,
      };
    }
    this.fetchInfluencers = this.fetchInfluencers.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
  }

  componentDidMount() {
    if (!this.state.influencers) {
      APIUtil.fetchCampaignInfluencersCreators(
        this.props.campaign.id,
        this.state.page
      ).then((response) => {
        const { influencers, totalPage, totalCount } = response;
        this.setState({ influencers, totalPage, totalCount });
      })
      .catch((e) => console.log(e.message));
    }
  }

  fetchInfluencers() {
    APIUtil.fetchCampaignInfluencersCreators(
      this.props.campaign.id,
      this.state.page
    ).then((response) => {
      this.setState({ influencers: response.influencers });
    });
  }

  nextPage() {
    this.setState({ page: this.state.page + 1 }, this.fetchInfluencers);
  }
  prevPage() {
    this.setState({ page: this.state.page - 1 }, this.fetchInfluencers);
  }

  renderPrevButton() {
    if (this.state.page !== 1) {
      return (
        <div className="page-button" onClick={this.prevPage}>
          &lt; PREV&nbsp;&nbsp;
        </div>
      );
    }
  }

  renderNextButton() {
    if (this.state.page < this.state.totalPage) {
      return (
        <div className="page-button" onClick={this.nextPage}>
          &nbsp;&nbsp;NEXT &gt;
        </div>
      );
    }
  }
  getInstagramURL(influencer) {
    return `https://www.instagram.com/${influencer.instagram_username}`;
  }

  renderInfluencersTable() {
    return this.state.influencers.map((influencer, index) => {
      return (
        <div key={index} className="report-influencer__row flex">
          <div className="influencer-image-container">
            <img src={influencer.profile_picture_url} />
          </div>
          <div className="influencer-details">
            <div className="influencer-info flex s-btwn">
              <h2>{influencer.full_name}</h2>
              <div className="post-info">
                <p>{influencer.post_count} posts</p>
                <div className="media-logos flex">
                  <div className="media-logo">
                    <a href={this.getInstagramURL(influencer)} target="_blank">
                      <img src={InstagramImage} className="img-fluid" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="influencer-data flex">
              <div className="col-3 border--rt">
                <p>{prettyNum(influencer.follower_count)}</p>
                <span>Followers</span>
              </div>
              <div className="col-3 border--rt">
                <p>{prettyNum(influencer.reach)}</p>
                <span>Reach</span>
              </div>
              <div className="col-3 border--rt">
                <p>{prettyNum(influencer.impressions)}</p>
                <span>Impressions</span>
              </div>
              <div className="col-3">
                <p>{prettyNum(influencer.engagement)}</p>
                <span>Engagement</span>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  render() {
    return (
      <div className="report-influencers__table">
        {this.renderInfluencersTable()}
        <div className="page-control flex">
          {this.renderPrevButton()}
          <span>
            VIEWING {this.state.page} - {this.state.totalPage} of{" "}
            {this.state.totalCount}
          </span>
          {this.renderNextButton()}
        </div>
      </div>
    );
  }
}

export default CampaignCreators;
