import React from "react";
import Field from "../../Controls/FormFields/Field";

const SocialMedia = (props) => (
  <div className="influencer-form--section">
    <div className="influencer-form--section-header">
      <h3 className="edit-post-form--header">Social Media</h3>
    </div>
    <div
      className="influencer-form--section-content"
      style={{ maxWidth: "672px" }}
    >
      <div className="influencer-form--section-row">
        <Field
          isRequired
          isEdit={props.isEdit}
          staticText="@"
          label="Instagram Username"
          name="instagramUsername"
          icon="instagram"
          width="324px"
        />
        <Field
          isRequired
          isEdit={props.isEdit}
          label="Followers"
          name="followerCount"
          width="152px"
        />
        <Field
          isEdit={props.isEdit}
          label="Avg. Engagement Rate"
          name="averageEngagementRate"
          placeholder="10%"
          width="152px"
        />
      </div>
      <div className="influencer-form--section-row">
        <Field
          isEdit={props.isEdit}
          staticText="@"
          label="Facebook Username"
          name="facebookUsername"
          icon="facebook"
          width="324px"
        />
        <Field
          isEdit={props.isEdit}
          label="Followers"
          name="facebookFollowerCount"
          width="152px"
        />
      </div>
      <div className="influencer-form--section-row">
        <Field
          isEdit={props.isEdit}
          placeHolder="https://www.youtube.com/channel/testusername"
          label="YouTube URL"
          name="youtubeUrl"
          icon="youtube"
          width="324px"
          />
        <Field
          isEdit={props.isEdit}
          label="Followers"
          name="youtubeFollowerCount"
          width="152px"
          />
      </div>
      <div className="influencer-form--section-row">
        <Field
          isEdit={props.isEdit}
          staticText="@"
          label="Pinterest Username"
          name="pinterestUsername"
          icon="pinterest"
          width="324px"
          />
        <Field
          isEdit={props.isEdit}
          label="Followers"
          name="pinterestFollowerCount"
          width="152px"
          />
      </div>
      <div className="influencer-form--section-row">
        <Field
          isEdit={props.isEdit}
          staticText="@"
          label="Twitter Username"
          name="twitterUsername"
          icon="twitter"
          width="324px"
          />
        <Field
          isEdit={props.isEdit}
          label="Followers"
          name="twitterFollowerCount"
          width="152px"
          />
      </div>
      <div className="influencer-form--section-row">
        <Field
          isEdit={props.isEdit}
          placeHolder="https://www.tiktok.com/@InsyteApp"
          label="TikTok URL"
          name="tiktokUrl"
          icon="tiktok"
          width="324px"
          />
        <Field
          isEdit={props.isEdit}
          label="Followers"
          name="tiktokFollowerCount"
          width="152px"
        />
      </div>
    </div>
  </div>
);

export default SocialMedia;
