import React from 'react';
import { prettifyDate } from '../../util';
import Checkbox from '../Checkbox';

class GroupRow extends React.Component {
  constructor(props) {
    super(props);
    this.redirectToShowPage = this.redirectToShowPage.bind(this);
  }

  redirectToShowPage(e) {
    e.stopPropagation();
    window.location.href = `/groups/${this.props.group.id}`;
  }

  renderClientBrand() {
    const { group } = this.props;
    let clientBrand;
    if (group.client !== null && group.brand !== null) {
      clientBrand = <p><span>{group.client.name} - {group.brand.name}</span></p>
    } else if (group.brand !== null) {
      clientBrand = <p><span>{group.brand.name}</span></p>
    } else if (group.client !== null) {
      clientBrand = <p><span>{group.client.name}</span></p>
    }
    return (
      <div className="client-brand vert-center flex-5 pointer" onClick={this.redirectToShowPage}>
        <p>{group.name}</p>
        {clientBrand}
      </div>
    )
  }

  render() {
    const { group, handleCheck, checked } = this.props;
      return (
        <div className="row campaign-row socialyte-row">
          <div className="flex flex-1">
            <div className="vert-center">
              <Checkbox
                checked={checked}
                handleCheck={handleCheck(group.id)}
              />
            </div>
          </div>
          {this.renderClientBrand()}
          <div className="vert-center flex-3 pointer" onClick={this.redirectToShowPage}>{ group.influencer_count }</div>
          <div className="vert-center flex-3 pointer" onClick={this.redirectToShowPage}>{ prettifyDate(group.created_at) }</div>
          <div className="vert-center flex-3 pointer" onClick={this.redirectToShowPage}>
            { group.creator.name }
          </div>
        </div>
      )      
  }
}

export default GroupRow;
