import React from 'react';
import AsyncSelect from 'react-select/async';
import * as APIUtil from '../api_util';

class AddToCampaign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaign: null,
      campaigns: [],
      errors: [],
      saved: false,
      inputValue: ''
    }
    this.handleAddToCampaign = this.handleAddToCampaign.bind(this);
  }

  componentDidMount() {
    APIUtil.fetchCampaigns().then(campaigns => {
      this.setState({ campaigns: Object.values(campaigns) });
    })
  }

  handleSelectChange(category) {
    return selectedOption => {
      this.setState({ [category]: selectedOption });
    }
  }

  handleAddToCampaign(e) {
    const { influencer } = this.props;
    const { campaign } = this.state
    if(campaign) {
      APIUtil.createCampaignInfluencer(influencer.id, campaign.id).then(() => {
        this.setState({saved: true, errors: []})
      },
      errors => {
        this.setState({ errors: errors.responseJSON, saved: false });
      })
    }
  }

  renderMessage() {
    if (this.state.saved) {
      return (<span className="saved">SAVED</span>);
    } else if (this.state.errors.length > 0) {
      return (<span className="error">{this.state.errors[0]}</span>);
    }
  }

  loadOptions = (inputValue, callback) => {
    APIUtil.searchCampaigns(this.state.inputValue).then((response) => {
      this.setState({ campaigns: response.campaigns });
      callback(response.campaigns);
    },
    errors => {
      this.setState({ errors: errors.responseJSON });
    });
  };  
  
  handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue });
    return inputValue;
  };
  
  handleChange = value => {
    this.handleSelectChange('campaigns');
    this.setState({ campaign: value });
  }

  render() {
    const { closeCampaignOpen } = this.props;
    return (
      <React.Fragment>
        <div className="group-container">
          <h3>ADD TO CAMPAIGN</h3>
          <div className="group-selects">
            <label>
              <p>CAMPAIGN</p>
              <AsyncSelect
                className="select-container"
                classNamePrefix="select"
                isClearable
                cacheOptions
                getOptionLabel={e => e.name}
                getOptionValue={e => e.id}
                loadOptions={this.loadOptions}
                defaultOptions={this.state.campaigns}
                onInputChange={this.handleInputChange}
                onChange={this.handleChange}
              />
            </label>
          </div>
          <div className="group-buttons">
            <button className={`add-button ${this.state.campaign && 'active'}`} onClick={this.handleAddToCampaign}>ADD</button>
            <button className="cancel-button" onClick={closeCampaignOpen}>CANCEL</button>
            { this.renderMessage() }
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default AddToCampaign;
