import React from "react";
import Select from "react-select";
import * as APIUtil from "../../api_util";
import AddImage from "images/add@2x.png";
import { statusOptions } from "../../globals";
import CreateModal from "../CreateModal";
import MagnifyGlassImage from "images/magnify-glass-dark@2x.png";
import DatePicker from "react-datepicker";
import Modal from "react-modal";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import SearchInput from "../SearchInput";
import Button from "../Controls/Button";
import Close from "icons/cross.svg";

class CampaignForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brand: null,
      client: null,
      name: "",
      influencersResult: [],
      selectedInfluencers: [],
      groupsResult: [],
      selectedGroups: [],
      assignee: null,
      owner: null,
      status: null,
      brands: [],
      clients: [],
      users: [],
      errors: [],
      groupInput: "",
      start_date: "",
      end_date: "",
      keywords: [],
      keywordInput: "",
      mounted: false,
      error: "",
      rfp: "",
      modalError: "",
      notes: "",
      clientModalIsOpen: false,
      brandModalIsOpen: false,
      modalIsOpen: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleKeywordInputChange = this.handleKeywordInputChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.addKeyword = this.addKeyword.bind(this);
    this.deleteCampaign = this.deleteCampaign.bind(this);
    this.createClient = this.createClient.bind(this);
    this.createBrand = this.createBrand.bind(this);
    this.openClientModal = this.openClientModal.bind(this);
    this.closeClientModal = this.closeClientModal.bind(this);
    this.openBrandModal = this.openBrandModal.bind(this);
    this.closeBrandModal = this.closeBrandModal.bind(this);
    this.fetchClients = this.fetchClients.bind(this);
    this.fetchBrands = this.fetchBrands.bind(this);
    this.getCurrentClientId = this.getCurrentClientId.bind(this);
    this.handleGroupSearchSubmit = this.handleGroupSearchSubmit.bind(this);
    this.handleInfluencerSearchSubmit = this.handleInfluencerSearchSubmit.bind(
      this
    );
    this.handleNotesChange = this.handleNotesChange.bind(this);
  }

  fetchClients(newClient) {
    if (typeof newClient === "undefined") {
      APIUtil.fetchClients().then((clients) => {
        this.setState({ clients });
      });
    } else {
      APIUtil.fetchClients().then((clients) => {
        this.setState({
          clients: clients,
          client: {
            label: newClient.name,
            value: newClient.id,
          },
        });
      });
    }
  }

  fetchBrands(newBrand) {
    if (typeof newBrand === "undefined") {
      APIUtil.fetchBrands().then((brands) => {
        this.setState({ brands });
      });
    } else {
      APIUtil.fetchBrands(this.state.client).then((brands) => {
        this.setState({
          brands: brands,
          brand: {
            label: newBrand.name,
            value: newBrand.id,
          },
        });
      });
    }
  }

  getCurrentClientId() {
    if (this.state.client != null) {
      return this.state.client.value;
    } else {
      return null;
    }
  }

  componentDidMount() {
    const { isFormTypeEdit, campaign } = this.props;
    this.fetchClients();
    this.fetchBrands();
    APIUtil.fetchUsers().then((users) => {
      this.setState({ users });
    });
    console.log("campaign", campaign);
    if (isFormTypeEdit && campaign) {
      let brand,
        client,
        owner,
        assignee,
        start_date,
        end_date,
        status,
        name,
        groups,
        keywords,
        rfp,
        notes,
        selectedInfluencers;
      if (campaign.client) {
        client = this.getCampaignSelectObject("client");
      }
      brand = this.getCampaignSelectObject("brand");
      owner = this.getCampaignSelectObject("owner");
      assignee = this.getCampaignSelectObject("assignee");
      status = { label: campaign.status, value: campaign.status };
      name = campaign.name;
      rfp = campaign.rfp || "";
      start_date = campaign.startDate || undefined;
      end_date = campaign.endDate || undefined;
      keywords = campaign.keywords;
      selectedInfluencers = campaign.influencers;
      notes = campaign.notes;
      this.setState({
        client,
        brand,
        owner,
        assignee,
        status,
        name,
        start_date,
        end_date,
        keywords,
        rfp,
        selectedInfluencers,
        notes,
      });
    }
    this.setState({ mounted: true });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.mounted &&
        this.state.mounted &&
        ((prevState.client &&
          prevState.client.value &&
          this.state.client &&
          this.state.client.value &&
          prevState.client.value !== this.state.client.value) ||
          (!prevState.client &&
            this.state.client &&
            this.state.client.value))) ||
      (prevState.client && !this.state.client)
    ) {
      this.setState({ brand: null }, () => {
        APIUtil.fetchBrands(this.state.client).then((brands) => {
          this.setState({ brands });
        });
      });
    }
    if (
      !prevState.mounted &&
      this.state.mounted &&
      this.state.client &&
      this.state.client.value
    ) {
      APIUtil.fetchBrands(this.state.client).then((brands) => {
        this.setState({ brands });
      });
    }
  }

  getCampaignSelectObject(key) {
    const { campaign } = this.props;
    if (campaign) {
      return { label: campaign[key].name, value: campaign[key].id };
    }
  }

  getOptions(key) {
    const options = this.state[key].map((value) => {
      return { value: value.id, label: value.name };
    });
    return options;
  }

  closeModal = () => this.setState({ modalIsOpen: false });

  handleSelectChange(category) {
    return (selectedOption) => {
      this.setState({ [category]: selectedOption });
    };
  }

  handleInputChange(key) {
    return (e) => this.setState({ [key]: e.target.value });
  }

  handleDateChange(value, event, date_type) {
    this.setState({ [date_type]: moment(value).format("YYYY-MM-DD") });
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  handleNotesChange(e) {
    this.setState({ notes: e.target.value });
  }

  handleSubmit(e) {
    const {
      name,
      client,
      brand,
      assignee,
      status,
      owner,
      groups,
      start_date,
      end_date,
      keywords,
      rfp,
      selectedInfluencers,
      selectedGroups,
      notes,
    } = this.state;
    const { isFormTypeEdit } = this.props;
    let influencerIds, groupIds;
    e.preventDefault();
    e.stopPropagation();
    if (!name) {
      this.setState({ error: "Please enter campaign name." });
      return;
    }
    if (brand === null) {
      this.setState({ error: "Please select brand." });
      return;
    }
    if (owner === null) {
      this.setState({ error: "Please select salesperson." });
      return;
    }
    if (assignee === null) {
      this.setState({ error: "Please select user to assign to." });
      return;
    }
    if (status === null) {
      this.setState({ error: "Please select status." });
      return;
    }
    if ((!start_date || !end_date) && ["running", "complete"].includes(status?.value)) {
      this.setState({ error: "Please set campaign dates." });
      return;
    }
    const campaign = {
      name,
      brand_id: brand.value,
      assignee_id: assignee.value,
      status: status.value,
      owner_id: owner.value,
      groups,
      start_date,
      end_date,
      keywords,
      rfp,
      notes,
    };
    if (client && client.value) {
      campaign.client_id = client.value;
    }
    if (status && status.value) {
      campaign.status = status.value;
    }
    if (client && client.value && client.value === null) {
      delete campaign.client_id;
    }

    influencerIds = [];
    selectedInfluencers.forEach((i) => {
      influencerIds.push(i.id);
    });
    // Rails workaround for empty array
    if (influencerIds.length == 0) {
      influencerIds = [""];
    }
    campaign.influencers = influencerIds;

    groupIds = [];
    selectedGroups.forEach((i) => {
      groupIds.push(i.id);
    });
    // Rails workaround for empty array
    if (groupIds.length == 0) {
      groupIds = [""];
    }
    campaign.groups = groupIds;

    if (campaign.keywords.length == 0) {
      campaign.keywords = [""];
    }

    if (isFormTypeEdit && this.props.campaign) {
      APIUtil.updateCampaign(campaign, this.props.campaign.id).then(
        (response) => {
          window.location = `/campaigns/${this.props.campaign.id}`;
        },
        (errors) => {
          this.setState({ errors: errors });
        }
      );
    } else {
      APIUtil.createCampaign(campaign).then(
        ({ campaign }) => {
          window.location = `/campaigns/${campaign.id}`;
        },
        (errors) => {
          this.setState({ errors: errors.responseJSON.errors });
        }
      );
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <span className="error-message">{this.state.error}</span>;
    } else if (this.state.errors && this.state.errors.length > 0) {
      return <span className="error-message">{this.state.errors[0]}</span>;
    }
  }

  addKeyword(e) {
    e.preventDefault();
    const keywords = [...this.state.keywords];
    keywords.push(this.state.keywordInput);
    this.setState({ keywords, keywordInput: "" });
  }

  removeKeyword(index) {
    return (e) => {
      const keywords = [...this.state.keywords];
      keywords.splice(index, 1);
      this.setState({ keywords });
    };
  }

  toggleModal = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  };

  renderKeywords() {
    const { keywords } = this.state;
    if (keywords.length > 0) {
      return keywords.map((keyword, i) => {
        return (
          <div className="group" key={i}>
            <p>{keyword}</p>
            <Button
              buttonStyle="btn--secondary"
              buttonSize="btn--medium"
              onClick={this.removeKeyword(i)}
            >
              Remove
            </Button>
          </div>
        );
      });
    }
  }

  handleKeywordInputChange(e) {
    this.setState({ keywordInput: e.target.value });
  }
  renderKeywordForm() {
    const { status } = this.state;
    if (status && status.value === "running") {
      return (
        <div>
          <label>KEYWORDS</label>
          <br />
          {this.renderKeywords()}
          <form className="group-form">
            <input
              type="text"
              placeholder="Add another keyword (must start with @ or #)"
              value={this.state.keywordInput}
              onChange={this.handleInputChange("keywordInput")}
            ></input>
            <Button onClick={this.addKeyword}>Add</Button>
          </form>
        </div>
      );
    }
  }

  renderRFPInput() {
    const { status } = this.state;
    if (status && status.value === "running") {
      return (
        <React.Fragment>
          <label>RFP</label>
          <input
            type="text"
            value={this.state.rfp}
            onChange={this.handleInputChange("rfp")}
          ></input>
        </React.Fragment>
      );
    }
  }

  renderDateInputs() {
    const { status, mounted } = this.state;
    if (mounted && status && ["running", "complete"].includes(status.value)) {
      return (
        <React.Fragment>
          <label>Campaign Dates</label>
          <br />
          <DatePicker
            selected={
              this.state.start_date
                ? moment(this.state.start_date).toDate()
                : null
            }
            onChange={(value, event) =>
              this.handleDateChange(value, event, "start_date")
            }
            className="date-input"
            onChangeRaw={(e) => {
              e.preventDefault();
            }}
          ></DatePicker>
          <span> TO </span>
          <DatePicker
            selected={
              this.state.end_date ? moment(this.state.end_date).toDate() : null
            }
            onChange={(value, event) =>
              this.handleDateChange(value, event, "end_date")
            }
            className="date-input"
            onChangeRaw={(e) => {
              e.preventDefault();
            }}
          ></DatePicker>
        </React.Fragment>
      );
    }
  }

  deleteCampaign() {
    const { campaign } = this.props;
    APIUtil.updateCampaignStatuses(campaign.id, "deleted").then(
      () => (window.location.href = "/campaigns")
    );
  }

  renderCampaignSettingsHeader() {
    if (this.props.isFormTypeEdit) {
      return (
        <div className="flex s-btwn edit-header">
          <h2>Campaign Settings</h2>
          <div className="edit-buttons-container">
            {this.renderErrors()}
            <Button className="button-save" onClick={this.handleSubmit}>
              Save
            </Button>
          </div>
        </div>
      );
    }
  }

  renderCampaignSettingsDelete() {
    if (this.props.isFormTypeEdit) {
      return (
        <div className="flex s-btwn">
          <Button
            type="button"
            buttonStyle="btn--secondary"
            buttonSize="btn--medium"
            onClick={this.toggleModal}
            className="button-delete"
          >
            Delete Campaign
          </Button>

          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            contentLabel="Campaign Delete Modal"
            className="react-modal"
          >
            <h2>Are you sure you want to delete this campaign?</h2>

            <div className="close" onClick={this.closeModal}>
              <img src={Close} className="img-fluid" />
            </div>

            <div className="react-modal-buttons">
              <Button
                type="button"
                buttonStyle="btn--primary"
                buttonSize="btn--medium"
                onClick={this.deleteCampaign}
              >
                Yes, Delete
              </Button>
              <Button
                type="button"
                buttonStyle="btn--secondary"
                buttonSize="btn--medium"
                onClick={this.closeModal}
              >
                No, cancel
              </Button>
            </div>
          </Modal>
        </div>
      );
    }
  }

  openClientModal() {
    this.setState({ clientModalIsOpen: true });
  }
  closeClientModal() {
    this.setState({ clientModalIsOpen: false, modalError: "" });
  }
  openBrandModal() {
    this.setState({ brandModalIsOpen: true });
  }
  closeBrandModal() {
    this.setState({ brandModalIsOpen: false, modalError: "" });
  }

  createClient(clientState) {
    const client = {
      name: clientState.name,
    };
    this.setState({ modalError: "" });
    APIUtil.createClient(client).then(
      (response) => {
        this.fetchClients(response);
        this.closeClientModal();
      },
      (errors) => {
        if (errors.responseJSON && errors.responseJSON.errors) {
          this.setState({ modalError: errors.responseJSON.errors[0] });
        }
      }
    );
  }

  createBrand(brandState) {
    const brand = {
      name: brandState.name,
      client_id: this.getCurrentClientId,
    };
    this.setState({ modalError: "" });
    APIUtil.createBrand(brand).then(
      (response) => {
        this.fetchBrands(response);
        this.closeBrandModal();
      },
      (errors) => {
        if (errors.responseJSON && errors.responseJSON.errors) {
          this.setState({ modalError: errors.responseJSON.errors[0] });
        }
      }
    );
  }

  handleInfluencerSearchSubmit(query) {
    APIUtil.searchInfluencers(query).then((response) => {
      this.setState({ influencersResult: response.influencers });
    });
  }

  removeSelectedInfluencer(index) {
    const newInfluencers = [...this.state.selectedInfluencers];
    newInfluencers.splice(index, 1);
    return (e) => {
      e.stopPropagation();
      this.setState({ selectedInfluencers: newInfluencers });
    };
  }

  handleGroupSearchSubmit(query) {
    APIUtil.searchGroups(query).then((response) => {
      this.setState({ groupsResult: response.groups });
    });
  }

  render() {
    const { isFormTypeEdit } = this.props;
    return (
      <div className="campaign-form-container">
        <div className="campaign-form">
          {this.renderCampaignSettingsHeader()}
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label>Client (optional)</label>
              <Select
                className={
                  !isFormTypeEdit
                    ? "select-container client-brand"
                    : "select-container"
                }
                classNamePrefix="select"
                options={this.getOptions("clients")}
                value={this.state.client}
                isClearable={!isFormTypeEdit}
                isDisabled={isFormTypeEdit}
                onChange={this.handleSelectChange("client")}
              />
              {!isFormTypeEdit && (
                <div className="add-client-brand">
                  <Button onClick={this.openClientModal}>Add Client</Button>
                </div>
              )}
              <label>Brands</label>
              <Select
                className={
                  !isFormTypeEdit
                    ? "select-container client-brand"
                    : "select-container"
                }
                classNamePrefix="select"
                options={this.getOptions("brands")}
                value={this.state.brand}
                isClearable={!isFormTypeEdit}
                isDisabled={isFormTypeEdit}
                onChange={this.handleSelectChange("brand")}
              />
              {!isFormTypeEdit && (
                <div className="add-client-brand">
                  <Button onClick={this.openBrandModal}>Add Brand</Button>
                </div>
              )}
              <label>Campaign Name</label>
              <input
                className="campaign-name__input"
                type="text"
                value={this.state.name}
                onChange={this.handleNameChange}
              />
              <label>Import Group(s)</label>
              <SearchInput
                options={this.state.groupsResult}
                setSelectedOptions={(selectedGroups) =>
                  this.setState({ selectedGroups })
                }
                onRef={(ref) => (this.searchGroupInput = ref)}
                handleSearch={this.handleGroupSearchSubmit}
              />
              <label>Add Influencer(S)</label>
              <SearchInput
                options={this.state.influencersResult}
                setSelectedOptions={(selectedInfluencers) =>
                  this.setState({ selectedInfluencers })
                }
                selectedOptions={this.state.selectedInfluencers}
                onRef={(ref) => (this.searchInfluencerInput = ref)}
                isInfluencer
                handleSearch={this.handleInfluencerSearchSubmit}
              />
              <label>Salesperson</label>
              <Select
                className="select-container"
                classNamePrefix="select"
                options={this.getOptions("users")}
                value={this.state.owner}
                isClearable
                onChange={this.handleSelectChange("owner")}
              />
              <label>Notes</label>
              <textarea
                onChange={this.handleNotesChange}
                value={this.state.notes}
                rows={4}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label>Workflow Status</label>
              <Select
                className="select-container"
                classNamePrefix="select"
                options={statusOptions}
                value={this.state.status}
                isClearable={false}
                onChange={this.handleSelectChange("status")}
              />
              <label>Campaign Manager</label>
              <Select
                className="select-container"
                classNamePrefix="select"
                options={this.getOptions("users")}
                value={this.state.assignee}
                isClearable
                onChange={this.handleSelectChange("assignee")}
              />
              {this.renderDateInputs()}
              {this.renderKeywordForm()}
              {this.renderRFPInput()}
            </div>
          </div>
          {!isFormTypeEdit && (
            <React.Fragment>
              <div>{this.renderErrors()}</div>
              <Button className="button-save" onClick={this.handleSubmit}>
                Save
              </Button>
            </React.Fragment>
          )}
          {this.renderCampaignSettingsDelete()}
        </div>

        {!isFormTypeEdit && (
          <CreateModal
            isOpen={this.state.clientModalIsOpen}
            onRequestClose={this.closeClientModal}
            closeModal={this.closeClientModal}
            contentLabel="Client Create Form"
            type="CLIENT"
            handleCreate={this.createClient}
            modalError={this.state.modalError}
          />
        )}
        {!isFormTypeEdit && (
          <CreateModal
            isOpen={this.state.brandModalIsOpen}
            onRequestClose={this.closeBrandModal}
            closeModal={this.closeBrandModal}
            contentLabel="Brand Create Form"
            type="BRAND"
            handleCreate={this.createBrand}
            modalError={this.state.modalError}
          />
        )}
      </div>
    );
  }
}
export default CampaignForm;
