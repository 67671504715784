import React from "react";
import { ErrorMessage, useField } from "formik";

// this field enhances performance by not doing as many updates on change/blur
export function useFastField(props) {
  const [field, meta] = useField(props);
  const [value, setValue] = React.useState(field.value);
  const { onBlur, onChange } = field;

  field.value = value;
  field.onChange = (e) => {
    if (e && e.currentTarget) {
      setValue(e.currentTarget.value);
    }
  };
  field.onBlur = (e) => {
    onChange(e);
    onBlur(e);
  };

  return [field, meta];
}

const setStaticText = (value, staticText) => {

  if (!staticText) {
    return value || "";
  }

  if (value && value.startsWith(staticText)) {
    return value;
  }
  return `${staticText}${value || ""}`;
};

const Field = ({ label, ...props }) => {
  // when fields are pre-populated, we need to use the regular field hook, since it calls a rerender to fill the field values
  // otherwise, we use the fast field to prevent performance lag
  const [field, meta] = props.isEdit ? useField(props) : useFastField(props);
  field.value = setStaticText(field.value, props.staticText);

  const renderInput = () =>
    props.textarea ? (
      <textarea
        aria-labelledby={label}
        className={`edit-post-form--input ${meta.error && "error"}`}
        {...field}
        {...props}
      />
    ) : (
      <input
        autoComplete="off"
        type={props.type ?? "text"}
        placeholder={props.placeHolder || ""}
        className={
          props.icon
            ? `input-icon input-icon-${props.icon} edit-post-form--input ${
                meta.error && "edit-post-form--input-error"
              }`
            : `edit-post-form--input ${
                meta.error && "edit-post-form--input-error"
              }`
        }
        style={{
          width: props.width ? props.width : "238px",
          paddingLeft: props.icon ? "40px" : "10px",
        }}
        aria-labelledby={label}
        {...field}
        {...props}
      />
    );

  return (
    <label className="influencer-form--field-label">
      <span
        className={meta.error ? "label error" : "label"}
        // hack, but this is the only label that wraps because of text length
        style={{ width: field.name === "averageEngagementRate" && "200px" }}
      >
        {label}
        <span style={{ color: "red" }}>{props.isRequired && "*"}</span>
      </span>
      {renderInput()}
      {field && field.name ? (
        <span className="form-error-message-container">
          <ErrorMessage name={field.name} />
        </span>
      ) : null}
    </label>
  );
};

export default Field;
