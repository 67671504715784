import React from "react";
import Button from "../Button";

const FormSave = ({ isSubmitting, isValid, onCancel }) => (
  <div className="post-btn-container">
    <Button
      className="save-btn"
      buttonStyle="btn--primary"
      buttonSize="btn--medium"
      type="submit"
      disabled={isSubmitting || !isValid}
    >
      SAVE
    </Button>
    <Button
      className="cancel-btn"
      buttonStyle="btn--secondary"
      buttonSize="btn--medium"
      type="button"
      onClick={onCancel}
    >
      CANCEL
    </Button>
  </div>
);

export default FormSave;
