import React from 'react';
import AddToGroup from './AddToGroup';
import AddToCampaign from './AddToCampaign';
import PlusImage from 'images/plus-white@2x.png';
import MinusImage from 'images/minus@2x.png';

class AddToGroupAndCampaignButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupOpen: false,
      campaignOpen: false
    }
    this.toggleGroupOpen = this.toggleGroupOpen.bind(this);
    this.closeGroupOpen = this.closeGroupOpen.bind(this);
    this.toggleCampaignOpen = this.toggleCampaignOpen.bind(this);
    this.closeCampaignOpen = this.closeCampaignOpen.bind(this);
  }

  toggleGroupOpen() {
    this.setState(({groupOpen}) => ({ groupOpen: !groupOpen, campaignOpen: false }))
    if (this.state.groupOpen) {
      $('body').removeClass('no-scroll')
    } else {
      $('body').addClass('no-scroll')
    }
  }

  closeGroupOpen() {
    this.setState({ groupOpen: false })
    $('body').removeClass('no-scroll')
  }

  toggleCampaignOpen() {
    this.setState(({campaignOpen}) => ({ campaignOpen: !campaignOpen, groupOpen: false }))
    if (this.state.campaignOpen) {
      $('body').removeClass('no-scroll')
    } else {
      $('body').addClass('no-scroll')
    }
  }

  closeCampaignOpen() {
    this.setState({ campaignOpen: false })
    $('body').removeClass('no-scroll')
  }

  renderAddToGroup() {
    return (this.state.groupOpen &&
            <AddToGroup
              influencer={this.props.influencer}
              closeGroupOpen={this.closeGroupOpen}
              closeButton={true}
            />)
  }

  renderAddToGroupButton() {
    let image, buttonClass
    if (this.state.groupOpen) {
      image = <img src={MinusImage} />
      buttonClass = "close-button"
    } else {
      image = <img src={PlusImage} />
      buttonClass = ""
    }
    return (
      (<button className={buttonClass} onClick={this.toggleGroupOpen}>
        {image}
        <span>ADD TO GROUP</span>
      </button>)
    )
  }

  renderAddToCampaign() {
    return (this.state.campaignOpen &&
            <AddToCampaign
              influencer={this.props.influencer}
              closeCampaignOpen={this.closeCampaignOpen}
              closeButton={true}
            />)
  }

  renderAddToCampaignButton() {
    let image, buttonClass
    if (this.state.campaignOpen) {
      image = <img src={MinusImage} />
      buttonClass = "close-button"
    } else {
      image = <img src={PlusImage} />
      buttonClass = ""
    }
    return (
      (<button className={buttonClass} onClick={this.toggleCampaignOpen}>
        {image}
        <span>ADD TO CAMPAIGN</span>
      </button>)
    )
  }

  render() {
    return (
      <React.Fragment>
        <div className="text-right">
          { this.renderAddToGroupButton() }
          { this.renderAddToCampaignButton() }
        </div>
        { this.renderAddToGroup() }
        { this.renderAddToCampaign() }
      </React.Fragment>
    )
  }

}

export default AddToGroupAndCampaignButtons;
