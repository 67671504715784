import React from "react";
import Modal from "react-modal";
import Select from "react-select";
import * as APIUtil from "../../api_util";
import { statusOptions } from "../../globals";
import AddImage from "images/add@2x.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

class CampaignStatusModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.campaign.name,
      status: {
        value: this.props.campaign.status,
        label: this.props.campaign.status.toUpperCase(),
      },
      assignee: {
        value: this.props.campaign.assignee.id,
        label: this.props.campaign.assignee.name.toUpperCase(),
      },
      start_date: "",
      end_date: "",
      keywordInput: "",
      keywords: [],
      rfpNumber: "",
      users: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.addKeyword = this.addKeyword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement("body");
    this.setCampaignForm();
    APIUtil.fetchUsers().then((users) => {
      this.setState({ users });
    });
  }

  setCampaignForm() {
    const { campaign } = this.props;
    this.setState({
      name: campaign.name,
      status: {
        value: campaign.status,
        label: campaign.status.toUpperCase(),
      },
      assignee: {
        value: campaign.assignee.id,
        label: campaign.assignee.name.toUpperCase(),
      },
      start_date: campaign.startDate || undefined,
      end_date: campaign.endDate || undefined,
      // keywordInput: "",
      // keywords: [],
      rfpNumber: campaign.rfp,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { campaign } = this.props;
    if (prevProps.campaign.id !== campaign.id) {
      this.setCampaignForm();
    }
  }

  handleSubmit(e) {
    const {
      name,
      assignee,
      status,
      start_date,
      end_date,
      rfpNumber,
    } = this.state;
    e.preventDefault();
    const campaign = {
      name,
      start_date,
      end_date,
      assignee_id: assignee.value,
      status: status.value,
      rfp: rfpNumber,
    };
    const newCampaign = {
      name,
      start_date,
      end_date,
      assignee: { id: assignee.value, name: assignee.label },
      status: status.value,
    };
    APIUtil.updateCampaign(campaign, this.props.campaign.id).then(
      (response) => {
        this.props.updateCampaign(newCampaign);
        this.props.closeModal();
      },
      (errors) => {
        console.log(errors);
      }
    );
  }

  handleDateChange(value, event, date_type) {
    this.setState({ [date_type]: moment(value).format("YYYY-MM-DD") });
  }

  handleInputChange(input) {
    return (e) => {
      this.setState({ [input]: e.target.value });
    };
  }

  handleSelectChange(category) {
    return (selectedOption) => {
      this.setState({ [category]: selectedOption });
    };
  }

  getOptions(key) {
    const options = this.state[key].map((value) => {
      return { value: value.id, label: value.name };
    });
    return [{ value: null, label: "SELECT" }, ...options];
  }

  addKeyword(e) {
    e.preventDefault();
    const keywords = [...this.state.keywords];
    keywords.push(this.state.keywordInput);
    this.setState({ keywords, keywordInput: "" });
  }

  renderKeywords() {
    const { keywords } = this.state;
    if (keywords.length > 0) {
      return keywords.map((keyword, i) => {
        return (
          <div className="group" key={i}>
            <p>{keyword}</p>
          </div>
        );
      });
    }
  }

  renderKeywordForm() {
    return null;
    // return (
    //   <React.Fragment>
    //     <label>KEYWORDS (HASHTAGS  OR HANDLES)</label>
    //     { this.renderKeywords() }
    //     <form className="group-form" onSubmit={this.addKeyword}>
    //       <input
    //         type="text"
    //         placeholder="Add another keyword (must start with @ or #)"
    //         value={this.state.keywordInput}
    //         onChange={this.handleInputChange('keywordInput')}>
    //       </input>
    //       <button onClick={this.addKeyword}>Add</button>
    //     </form>
    //   </React.Fragment>
    // )
  }

  renderRunningForm() {
    if (this.state.status.value === "running") {
      return (
        <React.Fragment>
          <label>CAMPAIGN DATES</label>
          <DatePicker
            selected={
              this.state.start_date
                ? moment(this.state.start_date).toDate()
                : null
            }
            onChange={(value, event) =>
              this.handleDateChange(value, event, "start_date")
            }
            className="date-input"
            onChangeRaw={(e) => {
              e.preventDefault();
            }}
          ></DatePicker>
          <span> TO </span>
          <DatePicker
            selected={
              this.state.end_date ? moment(this.state.end_date).toDate() : null
            }
            onChange={(value, event) =>
              this.handleDateChange(value, event, "end_date")
            }
            className="date-input"
            onChangeRaw={(e) => {
              e.preventDefault();
            }}
          ></DatePicker>
          {this.renderKeywordForm()}
          <label>RFP NUMBER</label>
          <input
            value={this.state.rfpNumber}
            onChange={this.handleInputChange("rfpNumber")}
          ></input>
        </React.Fragment>
      );
    }
  }

  render() {
    const { campaign, isOpen, onRequestClose, contentLabel } = this.props;
    if (campaign) {
      return (
        <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          contentLabel={contentLabel}
          className="react-modal campaign-status-modal"
        >
          <h1>EDIT</h1>
          <label>CAMPAIGN NAME</label>
          <input
            value={this.state.name}
            onChange={this.handleInputChange("name")}
          ></input>
          <label>WORKFLOW STATUS</label>
          <Select
            className="select-container"
            classNamePrefix="select"
            options={statusOptions}
            value={this.state.status}
            onChange={this.handleSelectChange("status")}
          />
          <label>ASSIGN TO</label>
          <Select
            className="select-container"
            classNamePrefix="select"
            options={this.getOptions("users")}
            value={this.state.assignee}
            onChange={this.handleSelectChange("assignee")}
          />
          {this.renderRunningForm()}
          <button
            className="campaign-status-modal-button"
            onClick={this.handleSubmit}
          >
            UPDATE
          </button>
        </Modal>
      );
    } else {
      return null;
    }
  }
}

export default CampaignStatusModal;
