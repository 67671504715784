import React, { useState } from "react";
import { Formik, Form, validateYupSchema, yupToFormErrors } from "formik";
import * as Yup from "yup";
import FormSave from "./Controls/FormFields/FormSave";
import BasicInfo from "./influencer/form/BasicInfo";
import SocialMedia from "./influencer/form/SocialMedia";
import Interests from "./influencer/form/Interests";
import BrandAffinity from "./influencer/form/BrandAffinity";
import { decamelize, camelizeKeys, decamelizeKeys } from "humps";
import api from "../api";

const InfluencerForm = (props) => {
  const [brandWorkedSearch, setBrandWorkedSearch] = useState("");
  const [brandWantedSearch, setBrandWantedSearch] = useState("");
  const [apiErrors, setApiErrors] = useState(null);
  const [initialValues, setInitialValues] = useState({
    influencerType: "network",
    firstName: "",
    lastName: "",
    gender: "male",
    birthDate: "",
    email: "",
    locationName: "",
    website: "",
    bio: "",
    profilePicture: null,
    instagramUsername: "",
    followerCount: "",
    interestIds: [],
    brandWantedIds: [],
    brandWorkedIds: [],
  });

  React.useEffect(() => {
    if (props.influencer) {
      const influencerValues = camelizeKeys(props.influencer);
      setInitialValues({
        influencerType: influencerValues.influencerType,
        firstName: influencerValues.firstName,
        lastName: influencerValues.lastName,
        gender: influencerValues.gender,
        birthDate: influencerValues.birthDate,
        email: influencerValues.email,
        locationName: influencerValues.locationName,
        website: influencerValues.website,
        bio: influencerValues.bio,
        profilePicture: influencerValues.profilePictureUrl,
        instagramUsername: influencerValues.instagramUsername,
        averageEngagementRate:
          influencerValues.averageEngagementRate &&
          influencerValues.averageEngagementRate * 100 + "%",
        followerCount: influencerValues.followerCount,
        interestIds: influencerValues.interestIds,
        brandWantedIds: influencerValues.brandWantedIds,
        brandWorkedIds: influencerValues.brandWorkedIds,
        facebookUsername: influencerValues.facebookUsername,
        facebookFollowerCount: influencerValues.facebookFollowerCount,
        youtubeUrl: influencerValues.youtubeUrl,
        youtubeFollowerCount: influencerValues.youtubeFollowerCount,
        pinterestUsername: influencerValues.pinterestUsername,
        pinterestFollowerCount: influencerValues.pinterestFollowerCount,
        twitterUsername: influencerValues.twitterUsername,
        twitterFollowerCount: influencerValues.twitterFollowerCount,
        tiktokUrl: influencerValues.tiktokUrl,
        tiktokFollowerCount: influencerValues.tiktokFollowerCount,
      });
    }
  }, [props.influencer]);

  const validationSchema = Yup.object({
    influencerType: Yup.string().required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    gender: Yup.string().required(),
    birthDate: Yup.date().required(),
    email: Yup.string().email().required(),
    locationName: Yup.string().required(),
    website: Yup.string().nullable(true),
    bio: Yup.mixed().nullable(true),
    profilePicture: Yup.mixed().required(),
    instagramUsername: Yup.string()
      .required()
      .matches(/^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{1,29}$/, {
        message: `Your username may be up to 15 characters and contain only letters, numbers, periods, and underscores. Example: @InsyteApp`,
      }),
    followerCount: Yup.number().required(),
    averageEngagementRate: Yup.string().nullable(true),
    facebookUsername: Yup.string()
      .nullable(true)
      .matches(/^[a-zA-Z\d.]{5,}$/, {
        message: `Your username must be at least 5 characters and contain only letters, numbers, and periods. Example: @InsyteApp`,
      }),
    facebookFollowerCount: Yup.number().nullable(true),
    youtubeUrl: Yup.string()
      .nullable(true)
      .matches(
        /^((https):\/\/)(www\.|m\.)youtube\.com\/(channel\/|user\/)[a-zA-Z0-9\-]{1,}/,
        {
          message: `You must enter a valid YouTube URL. Example: https://www.youtube.com/channel/testusername`,
        }
      ),
    youtubeFollowerCount: Yup.number().nullable(true),
    pinterestUsername: Yup.string()
      .nullable(true)
      .matches(/^(\w){3,30}$/, {
        message: `Your username must be between 3-30 characters and can't have spaces, symbols, or punctuation. Example: @InsyteApp`,
      }),
    pinterestFollowerCount: Yup.number().nullable(true),
    twitterUsername: Yup.string()
      .nullable(true)
      .matches(/^(\w){1,15}$/, {
        message: `Your username may be up to 15 characters and can't have spaces, symbols, or punctuation. Example: @InsyteApp`,
      }),
    twitterFollowerCount: Yup.number().nullable(true),
    tiktokUrl: Yup.string()
      .nullable(true)
      .matches(/^(https:\/\/)(www\.)?(tiktok.com\/)(@)([a-zA-Z0-9\._]){3,29}/, {
        message: `You must enter a valid TikTok URL. Example: https://www.tiktok.com/@InsyteApp`,
      }),
    tiktokFollowerCount: Yup.number().nullable(true),
    interests: Yup.mixed(),
    brandWantedIds: Yup.mixed(),
    brandWorkedIds: Yup.mixed(),
  });

  /**
   * Due to user UX, We added @ at the beginning of username inputs,
   * So We need to remvethem when they are being send to the server
   */
  const cleanUserNameValues = (data) => {
    const values = { ...data };
    const usernameFields = [
      "instagramUsername",
      "facebookUsername",
      "pinterestUsername",
      "twitterUsername",
    ];

    for (const field of usernameFields) {
      values[field] =
        values[field] && values[field].startsWith("@")
          ? values[field].replace("@", "")
          : values[field];
    }
    return values;
  };

  const validateForm = (values) => {
    try {
      values = cleanUserNameValues(values);
      validateYupSchema(values, validationSchema, true, values);
    } catch (error) {
      return yupToFormErrors(error);
    }
  };

  const influencerTypes = ["Network", "Non-Exclusive", "Exclusive", "Prestige"];
  const genders = ["Male", "Female", "Other"];

  const handleApiError = (err) => {
    const formLevelError = err.response.status === 422;
    let errors = [];
    if (formLevelError) {
      Object.keys(initialValues).forEach((property) => {
        const key = decamelize(property);
        const error =
          err.response.data.errors[`user.${key}`] ||
          err.response.data.errors[key];
        const errorKey =
          key.charAt(0).toUpperCase() + key.slice(1);
        if (error) {
          errors.push(`${errorKey.replace("_", " ")} ${error[0]}.`);
        }
      });
      if (errors.length > 0) {
        setApiErrors(errors);
      }
    }
  };

  const handleSubmit = (values) => {
    const valuesToSend = {
      ...values,
      averageEngagementRate: parseFloat(values.averageEngagementRate) / 100.0,
    };

    if (props.influencer) {
      api
        .patch(`/influencers/${props.influencer.id}`, {
          influencer: decamelizeKeys(valuesToSend),
        })
        .then((res) => (window.location.href = `/influencers/${res.data.id}`))
        .catch((err) => handleApiError(err));
    } else {
      api
        .post(`/influencers`, {
          influencer: decamelizeKeys(valuesToSend),
        })
        .then((res) => (window.location.href = `/influencers/${res.data.id}`))
        .catch((err) => handleApiError(err));
    }
  };

  return (
    <div className="influencer-form--container">
      <Formik
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={false}
        validate={validateForm}
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ isValid, values }) => (
          <>
            <Form className="influencer-form--form">
              <BasicInfo
                isEdit={Boolean(props.influencer)}
                genders={genders}
                influencerTypes={influencerTypes}
                values={values}
              />
              <div className="influencer-form--divider" />
              <SocialMedia isEdit={Boolean(props.influencer)} />
              <div className="influencer-form--divider" />
              <Interests interests={props.interests} />
              <div className="influencer-form--divider" />
              <BrandAffinity
                brandWanted={{
                  items: props.brands,
                  brandWantedSearch,
                  setBrandWantedSearch,
                }}
                brandWorked={{
                  items: props.brands,
                  brandWorkedSearch,
                  setBrandWorkedSearch,
                }}
              />
              <div className="influencer-form--divider" />
              <div className="influencer-form--section">
                <div className="influencer-form--section-header" />
                <div className="influencer-form--section-content">
                  {apiErrors?.length > 0
                    ? apiErrors.map((error, i) => (
                        <p key={`error-${i}`} className="influencer-form--error-text">{error}</p>
                      ))
                    : null}
                  {!isValid && (
                    <p className="influencer-form--error-text">
                      Please correct the above errors.
                    </p>
                  )}
                  <FormSave
                    onCancel={() => (window.location.href = "/profiles")}
                  />
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default (props) => <InfluencerForm {...props} />;
