import React from "react";
import * as APIUtil from "../../api_util";
import SearchImage from "images/search@2x.png";
import debounce from "lodash/debounce";
import api from "../../api";
import Loading from "../Loading";

class NavbarSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchType: props.search_type,
      query: "",
      searchResults: [],
      isOpen: false,
      error: "",
      isCreatingInfluencer: false,
    };

    this._handleChange = this._handleChange.bind(this);
    this._handleInfluencerSearch = this._handleInfluencerSearch.bind(this);
    this._handleCampaignSearch = this._handleCampaignSearch.bind(this);
    this._handleGroupSearch = this._handleGroupSearch.bind(this);
    this.renderSearchResults = this.renderSearchResults.bind(this);
    this.renderInfluencerResults = this.renderInfluencerResults.bind(this);
    this.renderAddInfluencer = this.renderAddInfluencer.bind(this);
    this.fetchInfluencer = this.fetchInfluencer.bind(this);
    this.handleApiError = this.handleApiError.bind(this);
    this.renderCampaignResults = this.renderCampaignResults.bind(this);
    this.renderGroupResults = this.renderGroupResults.bind(this);
    this._handleOutsideClick = this._handleOutsideClick.bind(this);
    this._handleFocus = this._handleFocus.bind(this);
    this._debouncedSearch = debounce(
      () => this._handleSearch(this.state.query),
      500
    );
  }

  componentDidMount() {
    document.addEventListener("mousedown", this._handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this._handleOutsideClick, false);
  }

  _handleOutsideClick(e) {
    if (this.node.contains(e.target)) {
      return;
    }
    this.setState({ isOpen: false });
  }

  _handleChange(e) {
    const query = e.target.value;
    this.setState({ query, isOpen: true, error: "" }, () => {
      if (this.state.query.length > 1) {
        this._debouncedSearch();
      } else {
        this.setState({ searchResults: [] });
      }
    });
  }

  _handleFocus() {
    if (this.state.query) {
      this.setState({ isOpen: true });
    }
  }

  _handleSearch() {
    const { searchType, query } = this.state;
    if (searchType == "influencers") {
      this._handleInfluencerSearch(query);
    } else if (searchType == "campaigns") {
      this._handleCampaignSearch(query);
    } else if (searchType == "groups") {
      this._handleGroupSearch(query);
    }
  }

  handleKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  _handleInfluencerSearch(input) {
    APIUtil.searchInfluencers(input).then((response) => {
      this.setState({ searchResults: response.influencers });
    });
  }

  _handleCampaignSearch(input) {
    APIUtil.searchCampaigns(input).then((response) => {
      this.setState({ searchResults: response.campaigns });
    });
  }

  _handleGroupSearch(input) {
    APIUtil.searchGroups(input).then((response) => {
      this.setState({ searchResults: response.groups });
    });
  }

  renderSearchResults() {
    if (this.state.isOpen) {
      if (this.state.searchType == "influencers") {
        return (
          <div className="navbar-search-results-div">
            {this.renderAddInfluencer()}
            {this.renderInfluencerResults()}
          </div>
        );
      } else if (this.state.searchType == "campaigns") {
        return (
          <div className="navbar-search-results-div">
            {this.renderCampaignResults()}
          </div>
        );
      } else if (this.state.searchType == "groups") {
        return (
          <div className="navbar-search-results-div">
            {this.renderGroupResults()}
          </div>
        );
      }
    }
  }

  renderInfluencerResults() {
    return this.state.searchResults.map((result, index) => {
      return (
        <div className="navbar-search-result" key={index + 1}>
          <a href={`/influencers/${result.id}`} target="_blank">
            <div className="navbar-search-result-name">{result.full_name}</div>
            <div className="navbar-search-result-username">
              @{result.instagram_username}
            </div>
          </a>
        </div>
      );
    });
  }

  renderAddInfluencer() {
    const ig_accounts = this.state.searchResults.map(
      (result, index) => result.instagram_username
    );
    if (ig_accounts.includes(this.state.query)) {
      return;
    } else {
      const { isCreatingInfluencer } = this.state;
      const createLabel = isCreatingInfluencer ? "Creating" : "Create";
      return (
        <div
          className="navbar-search-result"
          onClick={this.fetchInfluencer}
          key={0}
        >
          <a
            style={{ display: "flex", alignItems: "center" }}
            href="javascript:void(0)"
          >
            {isCreatingInfluencer ? <Loading /> : null}
            <div className="navbar-search-result-create">
              {createLabel} influencer
            </div>
            <div className="navbar-search-result-username">
              @{this.state.query}
            </div>
            <div className="navbar-search-result-error">{this.state.error}</div>
          </a>
        </div>
      );
    }
  }

  fetchInfluencer() {
    const { isCreatingInfluencer } = this.state;  
    if (isCreatingInfluencer) return;

    this.setState({ isCreatingInfluencer: true });
    api
      .post(`/influencers/fetch`, {
        influencer: { instagram_username: this.state.query },
      })
      .then((res) => (window.location.href = `/influencers/${res.data.id}`))
      .catch((err) => this.handleApiError(err))
      .finally(() => this.setState({ isCreatingInfluencer: false }));
  }

  handleApiError(err) {
    const formLevelError = err.response.status === 422;
    if (formLevelError) {
      this.setState({ error: "Influencer wasn't created" });
    }
  }

  renderCampaignResults() {
    return this.state.searchResults.map((result, index) => {
      return (
        <div className="navbar-search-result" key={index}>
          <p>
            <a href={"/campaigns/" + result.id}>{result.name}</a>
          </p>
        </div>
      );
    });
  }

  renderGroupResults() {
    return this.state.searchResults.map((result, index) => {
      return (
        <div className="navbar-search-result" key={index}>
          <p>
            <a href={"/groups/" + result.id}>{result.name}</a>
          </p>
        </div>
      );
    });
  }

  renderSearchPlaceholder() {
    if (this.state.searchType == "influencers") {
      return "SEARCH INFLUENCERS";
    } else if (this.state.searchType == "campaigns") {
      return "SEARCH CAMPAIGNS";
    } else if (this.state.searchType == "groups") {
      return "SEARCH GROUPS";
    }
  }

  render() {
    return (
      <form onKeyPress={this.handleKeyPress} ref={(node) => (this.node = node)}>
        <div className="search-img-container">
          <img src={SearchImage} className="search-img" />
        </div>
        <input
          type="text"
          value={this.state.query}
          placeholder={this.renderSearchPlaceholder()}
          onChange={this._handleChange}
          onFocus={this._handleFocus}
        />
        {this.renderSearchResults()}
      </form>
    );
  }
}

export default NavbarSearch;
