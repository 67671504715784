import ReactOnRails from "react-on-rails";
import ProfileDiscovery from "../bundles/Socialyte/components/profile_discovery/ProfileDiscovery";
import Campaigns from "../bundles/Socialyte/components/campaigns/Campaigns";
import InfluencerAccountStatsGraph from "../bundles/Socialyte/components/InfluencerAccountStatsGraph";
import InfluencerAudienceAgeGraph from "../bundles/Socialyte/components/InfluencerAudienceAgeGraph";
import PostSection from "../bundles/Socialyte/components/PostSection";
import CampaignForm from "../bundles/Socialyte/components/campaigns/CampaignForm";
import AddToGroupAndCampaignButtons from "../bundles/Socialyte/components/AddToGroupAndCampaignButtons";
import Campaign from "../bundles/Socialyte/components/campaigns/Campaign";
import LocationChooser from "../bundles/Socialyte/components/onboarding/LocationChooser";
import NavbarSearch from "../bundles/Socialyte/components/navbar/NavbarSearch";
import PostGallery from "../bundles/Socialyte/components/campaigns/PostGallery";
import Report from "../bundles/Socialyte/components/campaigns/Report";
import CampaignCreators from "../bundles/Socialyte/components/campaigns/CampaignCreators";
import Groups from "../bundles/Socialyte/components/groups/Groups";
import Group from "../bundles/Socialyte/components/groups/Group";
import GroupForm from "../bundles/Socialyte/components/groups/GroupForm";
import UploadMediaModal from "../bundles/Socialyte/components/posts/UploadMediaModal";
import ArchivePost from "../bundles/Socialyte/components/posts/ArchivePost";
import PostForm from "../bundles/Socialyte/components/posts/PostForm";
import InfluencerInterestForm from "../bundles/Socialyte/components/InfluencerInterestForm";
import InfluencerBrandForm from "../bundles/Socialyte/components/InfluencerBrandForm";
import InfluencerEngagementGraph from "../bundles/Socialyte/components/InfluencerEngagementGraph";
import User from "../bundles/Socialyte/components/User/User";
import UserStatus from "../bundles/Socialyte/components/User/UserStatus";
import InfluencerForm from "../bundles/Socialyte/components/InfluencerForm";
import Avatar from "../bundles/Socialyte/components/User/Avatar";
import TableActiveCampaigns from "../bundles/Socialyte/components/campaigns/Table/TableActiveCampaigns";
import TableContractingCampaigns from "../bundles/Socialyte/components/campaigns/Table/TableContractingCampaigns";
import TablePendingCampaigns from "../bundles/Socialyte/components/campaigns/Table/TablePendingCampaigns";
import TableBillsDue from "../bundles/Socialyte/components/bills/Table/TableBillsDue";
import ManagerDashboard from "../bundles/Socialyte/components/ManagerDashboard";
import HelpTooltip from "../bundles/Socialyte/components/HelpTooltip";

ReactOnRails.register({
  InfluencerForm,
  ProfileDiscovery,
  Campaigns,
  InfluencerAccountStatsGraph,
  InfluencerAudienceAgeGraph,
  PostSection,
  CampaignForm,
  AddToGroupAndCampaignButtons,
  Campaign,
  LocationChooser,
  NavbarSearch,
  PostGallery,
  Report,
  CampaignCreators,
  Groups,
  Group,
  GroupForm,
  UploadMediaModal,
  ArchivePost,
  PostForm,
  InfluencerInterestForm,
  InfluencerBrandForm,
  InfluencerEngagementGraph,
  User,
  UserStatus,
  Avatar,
  TableActiveCampaigns,
  TableContractingCampaigns,
  TablePendingCampaigns,
  TableBillsDue,
  ManagerDashboard,
  HelpTooltip,
});
