import React from "react";
import FindAndSelect from "../components/FindAndSelect";

const BrandAffinity = ({ brandWanted, brandWorked }) => (
  <div className="influencer-form--section">
    <div className="influencer-form--section-header">
      <h3 className="edit-post-form--header">Brand Affinity</h3>
    </div>
    <div
      className="influencer-form--section-content"
      style={{ maxWidth: "498px" }}
    >
      <div className="influencer-form--section-row">
        <p
          style={{
            fontSize: "14px",
            lineHeight: "20px",
            marginTop: "10px",
            marginBottom: "16px",
          }}
        >
          Select 3 brands the influencer worked with in the past.
        </p>
      </div>
      <FindAndSelect
        name="brandWorkedIds"
        items={brandWorked.items}
        searchResult={brandWorked.brandWorkedSearch}
        handleSearchResult={brandWorked.setBrandWorkedSearch}
      />
      <div
        className="influencer-form--section-row"
        style={{ marginTop: "40px" }}
      >
        <p
          style={{
            fontSize: "14px",
            lineHeight: "20px",
            marginTop: "10px",
            marginBottom: "16px",
          }}
        >
          Select 3 brands the influencer would love to work with.
        </p>
      </div>
      <FindAndSelect
        name="brandWantedIds"
        items={brandWanted.items}
        searchResult={brandWanted.brandWantedSearch}
        handleSearchResult={brandWanted.setBrandWantedSearch}
      />
    </div>
  </div>
);

export default BrandAffinity;
