import React from "react";
import {
  VictoryChart,
  VictoryZoomContainer,
  VictoryBrushContainer,
  VictoryAxis,
  VictoryArea,
  VictoryLine,
  VictoryLegend
} from "victory";

class InfluencerEngagementGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: {
        engagement: true,
        sponsoredVsOrganic: false,
        photo: false,
        video: false
      },
      data: {
        engagement: props.engagement_rate_data,
        sponsored: props.sponsored_engagement_rate_data,
        organic: props.organic_engagement_rate_data,
        photo: props.photo_engagement_rate_data,
        video: props.video_engagement_rate_data
      },
      currentChartData: []
    };
    this.setTab = this.setTab.bind(this);
    this.sanitizeChartData = this.sanitizeChartData.bind(this);
  }

  initialTabs() {
    return {
      engagement: false,
      sponsoredVsOrganic: false,
      photo: false,
      video: false
    };
  }

  setTab(tab) {
    var tabs = this.initialTabs();
    tabs[tab] = true;
    const newData = this.sanitizeChartData(tab);
    return () =>
      this.setState({
        tabs: tabs,
        currentChartData: newData
      });
  }

  point(post) {
    return {
      x: new Date(post.timestamp),
      y: Math.round(post.engagement_rate * 100)
    };
  }

  sanitizeChartData(tab) {
    let data = [];
    const stateData = this.state.data;
    if (tab === "sponsoredVsOrganic") {
      data = {};
      data["sponsored"] = stateData.sponsored.map(post => {
        return this.point(post);
      });
      data["organic"] = stateData.organic.map(post => {
        return this.point(post);
      });
    } else {
      data = stateData[tab].map(post => {
        return this.point(post);
      });
    }
    return data;
  }

  engagementTickLabelFormat(x) {
    return `${x}%`;
  }

  handleZoom(domain) {
    this.setState({ selectedDomain: domain });
  }

  handleBrush(domain) {
    this.setState({ zoomDomain: domain });
  }

  componentDidMount() {
    this.setState({
      currentChartData: this.sanitizeChartData("engagement")
    });
  }

  renderXAxis() {
    return (
      <VictoryAxis
        style={{
          tickLabels: {
            fontFamily: "Cardo, sans-serif",
            fill: "#8B8B8B",
            fontSize: "11px"
          }
        }}
      />
    );
  }
  renderYAxis() {
    return (
      <VictoryAxis
        dependentAxis
        tickFormat={this.engagementTickLabelFormat}
        style={{
          tickLabels: {
            fontFamily: "Josefin Sans, san-serif",
            fill: "#8B8B8B",
            fontSize: "10px",
            letterSpacing: "2px",
            fontWeight: "bold"
          },
          grid: {
            stroke: "#C9C9C9"
          }
        }}
      />
    );
  }

  renderGraph() {
    if (this.state.tabs.sponsoredVsOrganic) {
      return (
        <VictoryChart width={700} height={350} scale={{ x: "time" }}>
          {this.renderXAxis()}
          {this.renderYAxis()}
          <VictoryLegend
            x={475}
            y={25}
            gutter={10}
            orientation="horizontal"
            colorScale={["black", "#C9C9C9"]}
            data={[{ name: "Sponsored" }, { name: "Organic" }]}
          />
          <VictoryLine data={this.state.currentChartData.sponsored} />
          <VictoryLine
            style={{
              data: { stroke: "#C9C9C9" }
            }}
            data={this.state.currentChartData.organic}
          />
        </VictoryChart>
      );
    }
    return (
      <VictoryChart width={700} height={350} scale={{ x: "time" }}>
        {this.renderXAxis()}
        {this.renderYAxis()}
        <VictoryArea
          style={{
            data: { stroke: "#121212", fill: "#C9C9C9", opacity: ".5" }
          }}
          data={this.state.currentChartData}
        />
      </VictoryChart>
    );
  }

  render() {
    const tabs = this.state.tabs;
    return (
      <div>
        <div className="tabs">
          <div
            className={`tab ${tabs.engagement ? "active" : ""}`}
            onClick={this.setTab("engagement")}
          >
            <span>ENGAGEMENT</span>
          </div>
          <div
            className={`tab ${tabs.sponsoredVsOrganic ? "active" : ""}`}
            onClick={this.setTab("sponsoredVsOrganic")}
          >
            <span>SPONSORED VS. ORGANIC</span>
          </div>
          <div
            className={`tab ${tabs.photo ? "active" : ""}`}
            onClick={this.setTab("photo")}
          >
            <span>PHOTO</span>
          </div>
          <div
            className={`tab ${tabs.video ? "active" : ""}`}
            onClick={this.setTab("video")}
          >
            <span>VIDEO</span>
          </div>
        </div>
        {this.renderGraph()}
      </div>
    );
  }
}

export default InfluencerEngagementGraph;
