import React from "react";
import Button from "./Button";
import ArrowDown from "icons/arrow-down.svg";
import ArrowUp from "icons/arrow-up.svg";

class Dropdown extends React.Component {
  constructor() {
    super();

    this.state = {
      displayMenu: false,
    };

    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener("click", this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu);
    });
  }

  render() {
    return (
      <div className={"btn-dropdown " + this.props.className}>
        <Button
          onClick={this.showDropdownMenu}
          buttonStyle="btn--secondary"
          icon={this.state.displayMenu ? ArrowUp : ArrowDown}
        >
          {this.props.name}
        </Button>

        {this.state.displayMenu && (
          <ul className="btn-dropdown--menu">{this.props.children}</ul>
        )}
      </div>
    );
  }
}

export default Dropdown;
