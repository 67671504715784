import React from "react";
import ExternalLink from "icons/external-link.svg";
import ErrorIcon from "icons/error.svg";
import { prettyNum, prettifyDate } from "../../../util";
import Table from "./Table";
import ArrowUp from "icons/arrow-up.svg";
import ArrowDown from "icons/arrow-down.svg";

const TableStories = (props) => {
  const data = React.useMemo(() =>
    props.posts.map(
      (post) => ({
        postImage: {
          image: post.thumbnail_link || post.media_link,
          link: post.media_link,
        },
        name: post.influencer.full_name,
        postDate: post.timestamp,
        postFollowers: post.influencer.follower_count,
        postReach: post.reach,
        postImpressions: post.impressions,
        postReplies: post.comment_count,
        postSwipeUps: post.swipe_ups,
        subRows: post.subRows,
      }),
      []
    )
  );

  const swipeUpsCol = {
    Header: "Swipe Ups",
    accessor: "postSwipeUps",
    align: "align-right",
    isVisible: false,
    tooltip: "Number of unique click-throughs",
    Cell: (props) => (props && props.value ? prettyNum(props.value) : null),
    Footer: (info) => {
      const rows = info.rows.filter((row) => row.depth === 0);
      const total = React.useMemo(
        () => rows.reduce((sum, row) => row.values.postSwipeUps + sum, 0),
        [rows]
      );

      return prettyNum(total);
    },
  };

  const postRepliesCol = {
    Header: "Replies",
    accessor: "postReplies",
    align: "align-right",
    number: true,
    tooltip: "Direct Messages",
    Cell: (props) => (props && props.value ? prettyNum(props.value) : null),
    Footer: (info) => {
      const rows = info.rows.filter((row) => row.depth === 0);
      const total = React.useMemo(
        () => rows.reduce((sum, row) => row.values.postReplies + sum, 0),
        [rows]
      );

      return prettyNum(total);
    },
  };

  const postImpressionsCol = {
    Header: "Impressions",
    accessor: "postImpressions",
    align: "align-right",
    tooltip: "Total views",
    Cell: (props) => (props && props.value ? prettyNum(props.value) : null),
    Footer: (info) => {
      const rows = info.rows.filter((row) => row.depth === 0);
      const total = React.useMemo(
        () => rows.reduce((sum, row) => row.values.postImpressions + sum, 0),
        [rows]
      );

      return prettyNum(total);
    },
  };

  const postReachCol = {
    Header: "Reach",
    accessor: "postReach",
    align: "align-right",
    tooltip: "Unique views",
    Cell: (props) => (props && props.value ? prettyNum(props.value) : null),
    Footer: (info) => {
      const rows = info.rows.filter((row) => row.depth === 0);
      const total = React.useMemo(
        () => rows.reduce((sum, row) => row.values.postReach + sum, 0),
        [rows]
      );

      return prettyNum(total);
    },
  };

  const expandableButtonCol = {
    id: "expander",
    align: "align-center",
    className: "collapse-button",
    Cell: ({ row }) =>
      row.canExpand ? (
        <span {...row.getToggleRowExpandedProps()}>
          <img
            className="collapse-icon"
            src={row.isExpanded ? ArrowUp : ArrowDown}
          />
        </span>
      ) : null,
  };

  const rawColumns = [
    {
      Header: "",
      accessor: "postImage",
      align: "align-left",
      Cell: (props) => {
        return props && props.value ? (
          <div className="row-media">
            <img src={props?.value?.image} className="row-media-image" />
            {props.value.link == null ? (
              <div
                className="row-media-link"
                title="Media contained copyrighted material and a copy of it could not be saved"
              >
                <img src={ErrorIcon} className="row-media-link-icon" />
              </div>
            ) : (
              <div
                className="row-media-link"
                onClick={() => window.open(props?.value?.link)}
              >
                <img src={ExternalLink} className="row-media-link-icon" />
              </div>
            )}
          </div>
        ) : null;
      },
      Footer: "Total",
    },
    {
      Header: "Influencer",
      accessor: "name",
      align: "align-left",
    },
    {
      Header: "Date",
      accessor: "postDate",
      align: "align-left",
      Cell: (props) =>
        props && props.value ? prettifyDate(props.value) : null,
    },
    {
      Header: "Followers",
      accessor: "postFollowers",
      align: "align-right",
      Cell: (props) => (props && props.value ? prettyNum(props.value) : null),
      Footer: (info) => prettyNum(getFollowers()),
    },
  ];

  if (data.reduce((sum, x) => x.postReach + sum, 0) !== 0) {
    rawColumns.push(postReachCol);
  }

  if (data.reduce((sum, x) => x.postImpressions + sum, 0) !== 0) {
    rawColumns.push(postImpressionsCol);
  }

  if (data.reduce((sum, x) => x.postReplies + sum, 0) !== 0) {
    rawColumns.push(postRepliesCol);
  }

  if (data.reduce((sum, x) => x.postSwipeUps + sum, 0) !== 0) {
    rawColumns.push(swipeUpsCol);
  }

  if (data.some((post) => post.subRows && post.subRows.length > 0)) {
    rawColumns.push(expandableButtonCol);
  }

  const columns = React.useMemo(
    () => [
      {
        id: "stories",
        Header: "",
        Footer: "",
        columns: rawColumns,
      },
    ],
    []
  );

  const getFollowers = () => {
    const influencer = {};
    props.posts.map(
      (post) =>
        (influencer[post.influencer.id] = post.influencer.follower_count),
      []
    );

    return Object.values(influencer).reduce((sum, x) => sum + x, 0);
  };

  const renderRowSubComponent = React.useCallback(({ row }) => {
    return row && row.originalSubRows ? (
      <div className="d-flex">
        {row.originalSubRows.map((subRow) => {
          return (
            <div className="post-metrics-container">
              <div className="metric-title">
                <span>{subRow.title}</span>
              </div>

              {subRow.fields.map((field) => (
                <div className="metric-item">
                  <span>{field.key}</span>
                  <span>{field.value}</span>
                </div>
              ))}
            </div>
          );
        })}
      </div>
    ) : null;
  });

  return (
    <Table
      columns={columns}
      data={data}
      renderRowSubComponent={renderRowSubComponent}
    />
  );
};

export default TableStories;
