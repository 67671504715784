import React from "react";
import { useExpanded, useTable } from "react-table";
import HelpTooltip from "../../HelpTooltip";

const Table = ({ columns, data, hideFooter, renderRowSubComponent }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      expandSubRows: true,
    },
    useExpanded
  );

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr className="table-head" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                width={column.width}
                {...column.getHeaderProps({
                  className: column.align,
                })}
              >
                <div style={{ float: "inherit" }}>
                  {column.tooltip && <HelpTooltip message={column.tooltip} />}
                  {column.render("Header")}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <React.Fragment>
              <tr {...row.getRowProps()} key={`row-table-${i}`}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps({
                        className: cell.column.align,
                      })}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
              {row.isExpanded ? (
                <tr key={`hidden-${i}`} className="expanded-row">
                  <td colSpan={visibleColumns.length}>
                    {renderRowSubComponent({ row })}
                  </td>
                </tr>
              ) : null}
            </React.Fragment>
          );
        })}
      </tbody>
      {!hideFooter && (
        <tfoot>
          {footerGroups.map((group) => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <td
                  {...column.getFooterProps({
                    className: column.align,
                  })}
                >
                  {column.render("Footer")}
                </td>
              ))}
            </tr>
          ))}
        </tfoot>
      )}
    </table>
  );
};

export default Table;
