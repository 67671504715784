import React from "react";
import Select from "react-select";
import * as APIUtil from "../api_util";
import MinusImage from "images/minus@2x.png";

class AddToGroupOrCampaign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      group: null,
      groups: [],
      groupErrors: [],
      groupSaved: false,
      campaign: null,
      campaigns: [],
      campaignErrors: [],
      campaignSaved: false
    };
    this.handleAddToGroup = this.handleAddToGroup.bind(this);
    this.handleAddToCampaign = this.handleAddToCampaign.bind(this);
  }

  componentDidMount() {
    APIUtil.fetchGroups().then(groups => {
      this.setState({ groups: Object.values(groups) });
    });
    APIUtil.fetchCampaigns().then(campaigns => {
      this.setState({ campaigns: Object.values(campaigns) });
    });
    if (this.props.currentCampaign) {
      this.setState({ campaign: this.props.currentCampaign });
    }
    if (this.props.currentGroup) {
      this.setState({ group: this.props.currentGroup });
    }
  }

  getOptions(key) {
    if (this.state[key] && this.state[key].length > 0) {
      const options = this.state[key].map(value => {
        return { value: value.id, label: value.name };
      });
      return [{ value: null, label: "SELECT" }, ...options];
    }
  }
  handleSelectChange(category) {
    return selectedOption => {
      this.setState({ [category]: selectedOption });
    };
  }

  handleAddToGroup(e) {
    const { influencer, setCurrentGroup } = this.props;
    const { group } = this.state;
    if (group) {
      APIUtil.createGroupInfluencer(influencer.id, group.value).then(
        () => {
          setCurrentGroup(group);
          this.setState({ groupSaved: true, groupErrors: [] });
        },
        errors => {
          this.setState({
            groupErrors: errors.responseJSON,
            groupSaved: false
          });
        }
      );
    }
  }

  renderGroupMessage() {
    if (this.state.groupSaved) {
      return <span className="saved">SAVED</span>;
    } else if (this.state.groupErrors.length > 0) {
      return <span className="error">{this.state.groupErrors[0]}</span>;
    }
  }

  handleAddToCampaign(e) {
    const { influencer, setCurrentCampaign } = this.props;
    const { campaign } = this.state;
    if (campaign) {
      APIUtil.createCampaignInfluencer(influencer.id, campaign.value).then(
        () => {
          setCurrentCampaign(campaign);
          this.setState({ campaignSaved: true, campaignErrors: [] });
        },
        errors => {
          this.setState({
            campaignErrors: errors.responseJSON,
            campaignSaved: false
          });
        }
      );
    }
  }

  renderCampaignMessage() {
    if (this.state.campaignSaved) {
      return <span className="saved">SAVED</span>;
    } else if (this.state.campaignErrors.length > 0) {
      return <span className="error">{this.state.campaignErrors[0]}</span>;
    }
  }

  render() {
    const { closeGroupOpen, closeCampaignOpen } = this.props;
    return (
      <React.Fragment>
        <div className="group-container">
          <div className="row">
            <div className="col-6">
              <div className="multi-form-inline-group">
                <h3>ADD TO GROUP</h3>
                <div className="group-selects max-menu-height">
                  <label>
                    <p>GROUP</p>
                    <Select
                      className="select-container"
                      classNamePrefix="select"
                      value={this.state.group}
                      options={this.getOptions("groups")}
                      isClearable
                      onChange={this.handleSelectChange("group")}
                    />
                  </label>
                </div>
                <div className="group-buttons">
                  <button
                    className={`add-button ${this.state.group && "active"}`}
                    onClick={this.handleAddToGroup}
                  >
                    ADD
                  </button>
                  <button className="cancel-button" onClick={closeGroupOpen}>
                    CANCEL
                  </button>
                  {this.renderGroupMessage()}
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="multi-form-inline-group">
                <h3>ADD TO CAMPAIGN</h3>
                <div className="group-selects max-menu-height">
                  <label>
                    <p>CAMPAIGN</p>
                    <Select
                      className="select-container"
                      classNamePrefix="select"
                      value={this.state.campaign}
                      options={this.getOptions("campaigns")}
                      isClearable
                      onChange={this.handleSelectChange("campaign")}
                    />
                  </label>
                </div>
                <div className="group-buttons">
                  <button
                    className={`add-button ${this.state.campaign && "active"}`}
                    onClick={this.handleAddToCampaign}
                  >
                    ADD
                  </button>
                  <button className="cancel-button" onClick={closeCampaignOpen}>
                    CANCEL
                  </button>
                  {this.renderCampaignMessage()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddToGroupOrCampaign;
