import React, { useReducer } from "react";
import Masonry from "react-masonry-component";
import Post from "./Post";

const masonryOptions = {};

class PostGallery extends React.Component {
  renderPosts() {
    const { influencer } = this.props;
    const { user } = this.props;

    return this.props.posts.map((post, index) => {
      return (
        <Post
          key={index}
          post={post}
          influencer={influencer}
          first_name={user.first_name}
          last_name={user.last_name}
        />
      );
    });
  }

  render() {
    return (
      <Masonry className="post-gallery" updateOnEachImageLoad={true}>
        {this.renderPosts()}
      </Masonry>
    );
  }
}

export default PostGallery;
