import React from "react";
import { Field, useFormikContext } from "formik";
import Plus from "icons/plus.svg";

const Upload = ({ form, field }) => {
  const handleChange = (e) => {
    const file = e.currentTarget.files[0];
    const reader = new FileReader();
    const imgTag = document.getElementById("profilePicture");
    imgTag.title = file.name;
    reader.onload = function (evt) {
      imgTag.src = evt.target.result;
      form.setFieldValue(field.name, {
        filename: file.name,
        data: evt.target.result,
      });
    };
    reader.readAsDataURL(file);
  };

  return <input type="file" onChange={(e) => handleChange(e)} />;
};

const UploadImage = ({ name, label, ...props }) => {
  const { values, errors } = useFormikContext();

  return (
    <div className="influencer-form--upload-image-container">
      <label className="influencer-form--upload-image-label">
        {label}
        <span style={{ color: "red" }}>{props.isRequired && "*"}</span>
      </label>

      {values.profilePicture !== null ? (
        <div className="influencer-form--image">
          <Field name={name} component={Upload} />
          <img
            id="profilePicture"
            src={
              values.profilePicture.data
                ? values.profilePicture.data
                : values.profilePicture
            }
          />
        </div>
      ) : (
        <div className="influencer-form--upload-image-inner-container">
          <img id="profilePicture" />
          <Field name={name} component={Upload} />
          <div className="influencer-form--upload-image-icon">
            <img src={Plus} />
          </div>
          <p className="influencer-form--upload-image-context">
            Drop your image here or <span>browse</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default UploadImage;
