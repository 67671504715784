import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import moment from "moment";
import Calendar from "icons/calendar.svg";

const DatePickerField = (props) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  return (
    <div className="edit-post--datepicker-container">
      <label className="edit-post-form--label edit-post--datepicker-label">
        <span className="label">Date</span>
        {props.isManual || props.isEditable ? (
          <>
            <p
              style={{
                fontSize: "12px",
                color: meta.touched && meta.error ? "red" : "black",
              }}
            >
              Date should be between{" "}
              {moment(props.campaignStartDate).format("MMM D, YYYY")} and{" "}
              {moment(props.campaignEndDate).format("MMM D, YYYY")}
            </p>
            <div className="input-group">
              <DatePicker
                {...field}
                {...props}
                startDate={moment(field.value).toDate()}
                dateFormat="MMM d, yyyy"
                selected={(field.value && moment(field.value).toDate()) || null}
                minDate={moment(props.campaignStartDate).toDate()}
                maxDate={moment(props.campaignEndDate).toDate()}
                onChange={(val) => {
                  setFieldValue(field.name, val);
                }}
                className="edit-post-form--input form-control edit-post--datepicker"
              />
              <div className="input-group-append edit-post--datepicker-append">
                <span className={`input-group-text edit-post--datepicker-icon`}>
                  <img src={Calendar} />
                </span>
              </div>
            </div>
          </>
        ) : (
          <p className="edit-post-form--static-input">
            {moment(field.value).format("MMM D, YYYY")}
          </p>
        )}
      </label>
    </div>
  );
};

export default DatePickerField;
