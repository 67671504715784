// fields for posts/stories are organized
// here in a way that reflects the design based on
// whether or not the post/story was pulled from the API
// or created manually
//
// the styling pattern for this is to use flex-wrap
// with the width of each field calculated based off of how many fields in a row there are
// example layout (each field represented):
// 'reach' 'impressions' 'replies' 'shared'
// 'swipe ups'
// 'back' 'forward' 'skip story' 'exit'
// each field in the design has a given length
// based on the number of items in a row
// sometimes rows only have 1 item, but still have a length as if
// there were 3 items in a row. in these cases,
// there's a 'spacer' object that displays an empty field.
// to counteract the accessibility problems this would cause, fields
// that are marked as SPACER have an `aria-hidden=true` attribute
//
// the 'order number' field dictates how the array should be sorted
// this is based off the design

const postFields = (isManual, isEditable) => [
  {
    id: 1,
    name: "reach",
    label: "Reach",
    isManual: isManual,
    isEditable: isEditable,
    span: 3,
    orderNumber: 1,
  },
  {
    id: 2,
    name: "impressions",
    label: "Impression",
    isManual: isManual,
    isEditable: isEditable,
    span: 3,
    orderNumber: 2,
  },
  {
    id: 3,
    name: "commentCount",
    label: "Comments",
    isManual: isManual,
    span: 3,
    orderNumber: isManual ? 4 : 4,
  },
  {
    id: 4,
    name: "likeCount",
    label: "Likes",
    isManual: isManual,
    span: 3,
    orderNumber: isManual ? 5 : 5,
  },
  {
    id: 5,
    name: "saves",
    label: "Saves",
    isManual: isManual,
    isEditable: isEditable,
    span: 3,
    orderNumber: isManual ? 7 : 3,
  },
  {
    id: 6,
    name: "shares",
    label: "Shares",
    isManual: isManual,
    isEditable: isEditable,
    span: 3,
    orderNumber: isManual ? 8 : 7,
  },
];

const storyFields = (isManual) => [
  {
    id: 1,
    name: "reach",
    label: "Reach",
    isManual: isManual,
    span: isManual ? 3 : 4,
    orderNumber: isManual ? 4 : 1,
  },
  {
    id: 2,
    name: "impressions",
    label: "Impression",
    isManual: isManual,
    span: isManual ? 3 : 4,
    orderNumber: isManual ? 5 : 2,
  },
  {
    id: 3,
    name: "commentCount",
    label: "Replies",
    isManual: isManual,
    span: isManual ? 3 : 4,
    orderNumber: isManual ? 2 : 3,
  },
  {
    id: 4,
    name: "swipeUps",
    label: "Swipe Up",
    isManual: true,
    span: 3,
    orderNumber: isManual ? 3 : 5,
  },
  {
    id: 5,
    name: "shares",
    label: "Shares",
    isManual: true,
    span: isManual ? 3 : 4,
    orderNumber: isManual ? 1 : 4,
  },
  {
    id: 8,
    name: "tapsBack",
    label: "Back",
    isManual: true,
    span: 4,
    orderNumber: isManual ? 8 : 9,
  },
  {
    id: 9,
    name: "tapsForward",
    label: "Forward",
    isManual: true,
    span: 4,
    orderNumber: isManual ? 9 : 10,
  },
  {
    id: 10,
    name: "skips",
    label: "Skip Story",
    isManual: true,
    span: 4,
    orderNumber: isManual ? 10 : 11,
  },
  {
    id: 11,
    name: "exits",
    label: "Exits",
    isManual: true,
    span: 4,
    orderNumber: isManual ? 11 : 12,
  },
];

const youtubePostFields = () => [
  {
    id: 1,
    name: "likeCount",
    label: "Likes",
    isManual: true,
    span: 3,
    orderNumber: 4,
  },
  {
    id: 2,
    name: "commentCount",
    label: "Comments",
    isManual: true,
    span: 3,
    orderNumber: 5,
  },
  {
    id: 3,
    name: "impressions",
    label: "Views",
    isManual: true,
    span: 3,
    orderNumber: 2,
  },
];

const igtvPostFields = () => [
  {
    id: 1,
    name: "likeCount",
    label: "Likes",
    isManual: true,
    span: 3,
    orderNumber: 4,
  },
  {
    id: 2,
    name: "commentCount",
    label: "Comments",
    isManual: true,
    span: 3,
    orderNumber: 5,
  },
  {
    id: 3,
    name: "views",
    label: "Views",
    isManual: true,
    span: 3,
    orderNumber: 2,
  },
  {
    id: 4,
    name: "avgPercentageWatched",
    label: "Avg % Watched",
    isManual: true,
    span: 3,
    orderNumber: 3,
  },
];

const igReelPostFields = () => [
  {
    id: 1,
    name: "likeCount",
    label: "Likes",
    isManual: true,
    span: 3,
    orderNumber: 4,
  },
  {
    id: 2,
    name: "commentCount",
    label: "Comments",
    isManual: true,
    span: 3,
    orderNumber: 5,
  },
  {
    id: 3,
    name: "views",
    label: "Views",
    isManual: true,
    span: 3,
    orderNumber: 2,
  },
];

const amplifiedInstagramPostFields = () => [
  {
    id: 1,
    name: "likeCount",
    label: "Likes",
    isManual: true,
    span: 3,
    orderNumber: 4,
  },
  {
    id: 2,
    name: "commentCount",
    label: "Comments",
    isManual: true,
    span: 3,
    orderNumber: 5,
  },
  {
    id: 3,
    name: "views",
    label: "Views",
    isManual: true,
    span: 3,
    orderNumber: 2,
  },
  {
    id: 4,
    name: "impressions",
    label: "Impressions",
    isManual: true,
    span: 3,
    orderNumber: 3,
  },
  {
    id: 5,
    name: "shares",
    label: "Shares",
    isManual: true,
    span: 3,
    orderNumber: 3,
  },
  {
    id: 6,
    name: "reach",
    label: "Reach",
    isManual: true,
    span: 3,
    orderNumber: 3,
  },
  {
    id: 7,
    name: "linksClicks",
    label: "Link Clicks",
    isManual: true,
    span: 3,
    orderNumber: 3,
  },
];

const facebookPostFields = () => [
  {
    id: 1,
    name: "impressions",
    label: "Views / Impressions",
    isManual: true,
    span: 3,
    orderNumber: 2,
  },
  {
    id: 2,
    name: "shares",
    label: "Shares",
    isManual: true,
    span: 3,
    orderNumber: 3,
  },
  {
    id: 3,
    name: "reach",
    label: "Reach",
    isManual: true,
    span: 3,
    orderNumber: 3,
  },
  {
    id: 4,
    name: "likeCount",
    label: "Likes",
    isManual: true,
    span: 3,
    orderNumber: 4,
  },
  {
    id: 5,
    name: "commentCount",
    label: "Comments",
    isManual: true,
    span: 3,
    orderNumber: 5,
  },
  {
    id: 6,
    name: "linksClicks",
    label: "Link Clicks",
    isManual: true,
    span: 3,
    orderNumber: 3,
  },
  {
    id: 7,
    name: "photoClicks",
    label: "Photo Clicks",
    isManual: true,
    span: 3,
    orderNumber: 3,
  },
  {
    id: 8,
    name: "otherClicks",
    label: "Other Clicks",
    isManual: true,
    span: 3,
    orderNumber: 3,
  },
];

const tiktokPostFields = () => [
  {
    id: 1,
    name: "impressions",
    label: "Total Views",
    isManual: true,
    span: 3,
    orderNumber: 4,
  },
  {
    id: 2,
    name: "likeCount",
    label: "Likes",
    isManual: true,
    span: 3,
    orderNumber: 5,
  },
  {
    id: 3,
    name: "commentCount",
    label: "Comments",
    isManual: true,
    span: 3,
    orderNumber: 2,
  },
  {
    id: 4,
    name: "shares",
    label: "Shares",
    isManual: true,
    span: 3,
    orderNumber: 3,
  },
  {
    id: 5,
    name: "totalPlayTime",
    label: "Total Play Time",
    isManual: true,
    span: 3,
    orderNumber: 3,
  },
  {
    id: 6,
    name: "avgWatchTime",
    label: "Avg Watch Time",
    isManual: true,
    span: 3,
    orderNumber: 3,
  },
];

const fields = (isManual, isEditable, isStory, postType) => {
  if (isStory) {
    return storyFields(isManual);
  }

  const options = {
    YoutubePost: youtubePostFields(),
    IgtvPost: igtvPostFields(),
    IgReelPost: igReelPostFields(),
    FacebookPost: facebookPostFields(),
    AmplifiedFacebookPost: facebookPostFields(),
    AmplifiedInstagramPost: amplifiedInstagramPostFields(),
    TiktokPost: tiktokPostFields(),
    default: postFields(isManual, isEditable),
  }

  return (options[postType] || options.default);
}
export default fields;
