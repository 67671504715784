import React from "react";
import merge from "lodash/merge";
import CampaignRow from "./CampaignRow";
import CampaignStatusModal from "./CampaignStatusModal";
import Dropdown from "../Controls/Dropdown";
import DropdownItem from "../Controls/DropdownItem";
import PlusImage from "images/plus@2x.png";
import DownArrowImage from "images/indicator-down@2x.png";
import UpArrowImage from "images/indicator-up@2x.png";
import FilterInactiveImage from "icons/filter-inactive.svg";
import DatePicker from "react-datepicker";
import moment from "moment";
import FilterActiveImage from "icons/filter-active.svg";
import * as APIUtil from "../../api_util";
import Checkbox from "../Checkbox";
import onClickOutside from "react-onclickoutside";
import mapValues from "lodash.mapvalues";
import { selectChecked, getSelectedCount } from "../../util";
import Modal from "react-modal";
import Waypoint from "react-waypoint";

class Campaigns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: {
        all: true,
        discovery: false,
        running: false,
        complete: false,
        archive: false,
      },
      campaigns: {},
      campaignIds: [],
      page: 1,
      checkBoxes: {
        all: false,
      },
      ownerDropdown: false,
      startDate: "",
      endDate: "",
      assignedDropdown: false,
      isActionsOpen: false,
      statusModalIsOpen: false,
      selectedCampaign: null,
      isLoading: false,
      endOfList: false,
      ownerId: null,
      assigneeId: null,
      reportStatus: null,
    };
    this.setCampaignStatus = this.setCampaignStatus.bind(this);
    this.fetchCampaigns = this.fetchCampaigns.bind(this);
    this.toggleActionsDropdown = this.toggleActionsDropdown.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.closeActionDropdown = this.closeActionDropdown.bind(this);
    this.closeStatusModal = this.closeStatusModal.bind(this);
    this.openStatusModal = this.openStatusModal.bind(this);
    this.selectCampaign = this.selectCampaign.bind(this);
    this.updateCampaign = this.updateCampaign.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.toggleOwnerDropdown = this.toggleOwnerDropdown.bind(this);
    this.toggleAssignedDropdown = this.toggleAssignedDropdown.bind(this);
    this.closeOwnerDropdown = this.closeOwnerDropdown.bind(this);
    this.closeAssignedDropdown = this.closeAssignedDropdown.bind(this);
    this.handleOwnerClick = this.handleOwnerClick.bind(this);
    this.handleAssigneeClick = this.handleAssigneeClick.bind(this);
    this._handleOutsideOwnerClick = this._handleOutsideOwnerClick.bind(this);
    this._handleOutsideAssignedClick = this._handleOutsideAssignedClick.bind(
      this
    );
  }

  componentDidMount() {
    document.addEventListener(
      "mousedown",
      this._handleOutsideAssignedClick,
      false
    );
    document.addEventListener(
      "mousedown",
      this._handleOutsideOwnerClick,
      false
    );
    this.setState({ isLoading: true });
    this.fetchCampaigns().then(() => {
      window.scrollTo(0, 0);
      this.setState({ isLoading: false });
    });
  }
  componentWillUnmount() {
    document.removeEventListener(
      "mousedown",
      this._handleOutsideAssignedClick,
      false
    );
    document.removeEventListener(
      "mousedown",
      this._handleOutsideOwnerClick,
      false
    );
  }

  handleDateChange(value, event, date_type) {
    this.setState({
      [date_type]: moment(value).format("YYYY-MM-DD"),
      page: 1
    });
  }

  fetchCampaigns(client, status, owner, assigned, page, sort, startDate, endDate, direction) {
    this.setState({ isLoading: true });
    return APIUtil.fetchCampaigns(
      client,
      status,
      owner,
      assigned,
      page,
      sort,
      startDate,
      endDate,
      direction
    ).then((campaigns) => {
      let checkBoxes = {
        ...mapValues(campaigns, () => false),
        all: false,
      };
      let campaignIds = Object.keys(campaigns);
      let endOfList = campaignIds.length === 0;
      let campaignResult = campaigns;
      if (page && page > 1) {
        checkBoxes = { ...this.state.checkBoxes, ...checkBoxes };
        campaignIds = [...this.state.campaignIds, ...campaignIds];
        campaignResult = { ...this.state.campaigns, ...campaigns };
      }
      this.setState({
        campaigns: campaignResult,
        checkBoxes,
        campaignIds,
        isLoading: false,
        endOfList,
      });
    });
  }

  requestReport() {
    const {startDate, endDate, ownerId, assigneeId} = this.state;
    APIUtil.exportCampaignsReport(startDate, endDate, ownerId, assigneeId)
      .then(
        (response) => this.setState({reportStatus: "success"}),
        (error) => this.setState({reportStatus: "failed"})
    );
  }

  resetFilters() {
    this.setState(
      {
        page: 1,
        ownerId: null,
        assigneeId: null,
      },
      () => window.scrollTo(0, 0)
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { checkBoxes } = this.state;
    if (!prevState.status.all && this.state.status.all) {
      this.fetchCampaigns(null, null, null, null, null, null, this.state.startDate, this.state.endDate).then(() => {
        this.resetFilters();
      });
    }
    if (!prevState.status.discovery && this.state.status.discovery) {
      this.fetchCampaigns(null, "discovery", null, null, null, null, this.state.startDate, this.state.endDate).then(() => {
        this.resetFilters();
      });
    }
    if (!prevState.status.running && this.state.status.running) {
      this.fetchCampaigns(null, "running", null, null, null, null, this.state.startDate, this.state.endDate).then(() => {
        this.resetFilters();
      });
    }
    if (!prevState.status.complete && this.state.status.complete) {
      this.fetchCampaigns(null, "complete", null, null, null, null, this.state.startDate, this.state.endDate).then(() => {
        this.resetFilters();
      });
    }
    if (!prevState.status.archive && this.state.status.archive) {
      this.fetchCampaigns(null, "archive", null, null, null, null, this.state.startDate, this.state.endDate).then(() => {
        this.resetFilters();
      });
    }
    //uncheck 'all'
    if (prevState.checkBoxes.all && !checkBoxes.all) {
      let newCheckBoxes = Object.assign({}, checkBoxes);
      newCheckBoxes = mapValues(newCheckBoxes, () => false);
      newCheckBoxes = Object.assign({}, newCheckBoxes);
      this.setState({ checkBoxes: newCheckBoxes });
    }
    //check 'all'
    if (!prevState.checkBoxes.all && checkBoxes.all) {
      let newCheckBoxes = Object.assign({}, checkBoxes);
      newCheckBoxes = mapValues(newCheckBoxes, () => true);
      newCheckBoxes = Object.assign({}, newCheckBoxes);
      this.setState({ checkBoxes: newCheckBoxes });
    }
  }

  updateCampaign(campaign) {
    const campaignId = this.state.selectedCampaign.id;
    const campaignCopy = merge({}, this.state.campaigns[campaignId]);
    if (campaign.assignee && campaign.assignee.id) {
      delete campaignCopy.assignee;
    }
    const newCampaign = merge({}, campaignCopy, campaign);
    this.setState({
      campaigns: Object.assign({}, this.state.campaigns, {
        [campaignId]: newCampaign,
      }),
    });
  }

  _handleOutsideOwnerClick(e) {
    if (this.ownerRef.contains(e.target)) {
      return;
    }
    this.closeOwnerDropdown();
  }
  _handleOutsideAssignedClick(e) {
    if (this.assignedRef.contains(e.target)) {
      return;
    }
    this.closeAssignedDropdown();
  }
  closeActionDropdown() {
    this.setState({ isActionsOpen: false });
  }
  toggleOwnerDropdown() {
    this.setState({ ownerDropdown: !this.state.ownerDropdown });
  }
  closeOwnerDropdown() {
    this.setState({ ownerDropdown: false });
  }
  closeAssignedDropdown() {
    this.setState({ assignedDropdown: false });
  }
  toggleAssignedDropdown() {
    this.setState({ assignedDropdown: !this.state.assignedDropdown });
  }
  initialStatus() {
    return {
      all: false,
      discovery: false,
      running: false,
      complete: false,
      archive: false,
    };
  }

  getStatus() {
    const statuses = Object.keys(this.state.status);
    for (let i = 0; i < statuses.length; i++) {
      const key = statuses[i];
      if (this.state.status[key]) {
        return key;
      }
    }
  }

  openStatusModal() {
    this.setState({ statusModalIsOpen: true });
  }

  closeStatusModal() {
    this.setState({ statusModalIsOpen: false });
  }

  selectCampaign(selectedCampaign) {
    return (e) => {
      e.stopPropagation();
      this.setState({ selectedCampaign }, this.openStatusModal);
    };
  }

  handleCheck(id) {
    return (e) => {
      e.stopPropagation();
      const newCheckBoxes = Object.assign({}, this.state.checkBoxes);
      newCheckBoxes[id] = !newCheckBoxes[id];
      this.setState({ checkBoxes: newCheckBoxes });
    };
  }

  toggleActionsDropdown(e) {
    e.stopPropagation();
    this.setState(({ isActionsOpen }) => ({ isActionsOpen: !isActionsOpen }));
  }

  setCampaignStatus(status) {
    const newStatus = this.initialStatus();
    newStatus[status] = true;
    return () => this.setState({ status: newStatus });
  }

  renderCampaigns() {
    const { campaignIds, campaigns } = this.state;
    if (campaignIds.length > 0) {
      return campaignIds.map((id, i) => {
        const campaign = campaigns[id];
        return (
          <CampaignRow
            key={i}
            campaign={campaign}
            status={this.getStatus()}
            checked={this.state.checkBoxes[campaign.id]}
            selectCampaign={this.selectCampaign(campaign)}
            handleCheck={this.handleCheck}
          />
        );
      });
    } else {
      if (this.state.isLoading) {
        return <div className="loader"></div>;
      }
      return (
        <div className="row no-results-row">
          <div className="vert-center col-12">
            <p>No campaigns created yet. Create one above!</p>
          </div>
        </div>
      );
    }
  }

  isActive(status) {
    if (this.state.status[status]) {
      return "active";
    }
  }

  updateStatus(status) {
    const checkBoxes = Object.assign({}, this.state.checkBoxes);
    const ids = selectChecked(checkBoxes);
    if (ids.length > 0) {
      return (e) => {
        APIUtil.updateCampaignStatuses(ids, status).then(
          (campaigns) => {
            const newCampaigns = merge({}, this.state.campaigns, campaigns);
            if (status === "deleted") {
              ids.forEach((id) => {
                delete checkBoxes[id];
                delete newCampaigns[id];
              });
              checkBoxes.all = false;
            }
            this.setState({ campaigns: newCampaigns, checkBoxes: checkBoxes });
          },
          (error) => {
            console.log(error);
          }
        );
      };
    }
  }

  renderActionsDropdown() {
    if (this.state.isActionsOpen) {
      return (
        <React.Fragment>
          <div className="dropdown campaign-menu__dropdown">
            <ul>
              <li onClick={this.updateStatus("complete")}>MARK COMPLETE</li>
              <li onClick={this.updateStatus("archive")}>ARCHIVE</li>
              <li onClick={this.updateStatus("deleted")}>DELETE</li>
              <li disabled={this.isDateFilterEmpty()} onClick={() => this.requestReport()}>Generate CSV</li>
            </ul>
          </div>
        </React.Fragment>

      );
    }
  }

  isDateFilterEmpty() {
    if (this.state.startDate === "" && this.state.endDate === ""){
      return true
    }
  }

  renderDateFilter() {
    let status = this.getStatus();
    if (status === "all") {
      status = null;
    }
    const { ownerId, assigneeId, page } = this.state;
    return (
      <React.Fragment>
        <label className="date-filter-label">Date range - From: </label>
        <br />
        <DatePicker
          selected={
            this.state.startDate
              ? moment(this.state.startDate).toDate()
              : null
          }
          onChange={(value, event) => {
              this.handleDateChange(value, event, "startDate");
              this.fetchCampaigns(null, status, ownerId, assigneeId, 1, null, moment(value).format("YYYY-MM-DD"), this.state.endDate);
            }
          }
          className="date-input-filter"
          onChangeRaw={(e) => {
            e.preventDefault();
          }}
        ></DatePicker>
        <span className="date-filter-label"> TO </span>
        <DatePicker
          selected={
            this.state.endDate ? moment(this.state.endDate).toDate() : null
          }
          onChange={(value, event) => {
              this.handleDateChange(value, event, "endDate");
              this.fetchCampaigns(null, status, ownerId, assigneeId, 1, null, this.state.startDate, moment(value).format("YYYY-MM-DD"));
            }
          }
          className="date-input-filter"
          onChangeRaw={(e) => {
            e.preventDefault();
          }}
        ></DatePicker>
      </React.Fragment>
    );
  }

  renderAlert() {
    const options = {
      success: (
        <div className="alert alert-dark alert-dismissible fade show report-alert" role="alert">
          <strong>Great! </strong> You will receive an email with the report.
          <button type="button" className="close" aria-label="Close" onClick={() => this.setState({reportStatus: null})}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ),
      failed: (
        <div className="alert alert-danger alert-dismissible fade show report-alert" role="alert">
          <strong>Upps!</strong> Something goes wrong.
          <button type="button" className="close" aria-label="Close" onClick={() => this.setState({reportStatus: null})}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ),
      default: null
    }
    return options[this.state.reportStatus] || options.default;
  }

  renderUsers(userType) {
    let handleUserClick = this.handleAssigneeClick;
    if (userType === "owner") {
      handleUserClick = this.handleOwnerClick;
    }
    return this.props.users.map((user) => {
      let active = (userType === "owner" && this.state.ownerId === user.id) ||
                   (userType === "assigned" && this.state.assigneeId === user.id);

      return (
        <li onClick={handleUserClick(user)} key={user.id} className={active ? 'active' : ''}>
          {this.getUserName(user)}
        </li>
      );
    });
  }
  getUserName(user) {
    return `${user.first_name} ${user.last_name.charAt(0)}.`;
  }
  handleOwnerClick(user) {
    return () => {
      let status = this.getStatus();
      let page = 1;
      if (status === "all") {
        status = null;
      }

      this.setState(
        {
          ownerDropdown: false,
          ownerId: user.id,
          assigneeId: null,
          page,
        },
        () => this.fetchCampaigns(null, status, user.id, null, page, null, this.state.startDate, this.state.endDate)
      );
    };
  }
  handleAssigneeClick(user) {
    return () => {
      let status = this.getStatus();
      let page = 1;
      if (status === "all") {
        status = null;
      }

      this.setState(
        {
          assignedDropdown: false,
          ownerId: null,
          assigneeId: user.id,
          page,
        },
        () => this.fetchCampaigns(null, status, null, user.id, page, null, this.state.startDate, this.state.endDate)
      );
    };
  }
  renderUserDropdown(userType) {
    const { users } = this.props;
    let userState = this.state.assignedDropdown;
    if (userType === "owner") {
      userState = this.state.ownerDropdown;
    }
    if (users && userState) {
      return (
        <div className="dropdown campaign-menu__dropdown user-menu__dropdown">
          <ul>{this.renderUsers(userType)}</ul>
        </div>
      );
    }
  }
  renderWaypoint() {
    const { isLoading, page, endOfList } = this.state;
    let status = this.getStatus();
    if (status === "all") {
      status = null;
    }
    if (!isLoading && !endOfList) {
      return (
        <Waypoint
          onEnter={({ currentPosition }) =>
            this.setState({ page: page + 1 }, () => {
              const { ownerId, assigneeId, page } = this.state;
              this.fetchCampaigns(null, status, ownerId, assigneeId, this.state.page, null, this.state.startDate, this.state.endDate);
            })
          }
        ></Waypoint>
      );
    }
  }
  renderTable() {
    const { checkBoxes } = this.state;
    const status = this.getStatus();
    switch (status) {
      case "discovery":
        return (
          <div className="campaign-table socialyte-table">
            <div className="row campaign-head-row socialyte-head-row">
              <div className="col-name flex-2">
                <Checkbox
                  handleCheck={this.handleCheck("all")}
                  checked={checkBoxes.all}
                  label="CLIENT/BRAND"
                />
              </div>
              <div className="col-name flex-4">CAMPAIGN</div>
              <div className="col-name flex-5">KEYWORDS</div>
              <div className="col-name flex-2"># OF INFLUENCERS</div>
              <div className="col-name flex-2">ASSIGNED</div>
            </div>
            {this.renderCampaigns()}
          </div>
        );
      case "running":
        return (
          <div className="campaign-table socialyte-table">
            <div className="row campaign-head-row socialyte-head-row">
              <div className="col-name flex-2">
                <Checkbox
                  handleCheck={this.handleCheck("all")}
                  checked={checkBoxes.all}
                  label="CLIENT/BRAND"
                />
              </div>
              <div className="col-name flex-3">CAMPAIGN</div>
              <div className="col-name flex-2">START DATE</div>
              <div className="col-name flex-2">END DATE</div>
              <div className="col-name flex-3">DETAILS</div>
              <div className="col-name flex-2">ASSIGNED</div>
            </div>
            {this.renderCampaigns()}
          </div>
        );
      default:
        return (
          <div className="campaign-table socialyte-table">
            <div className="row campaign-head-row socialyte-head-row">
              <div className="col-name col-2">
                <Checkbox
                  handleCheck={this.handleCheck("all")}
                  checked={checkBoxes.all}
                  label="CLIENT/BRAND"
                />
              </div>
              <div className="col-name col-4">CAMPAIGN</div>
              <div
                className="col-name col-2"
                onClick={this.toggleStatusDropdown}
                ref={(ref) => (this.statusRef = ref)}
              >
                <span>STATUS</span>
              </div>
              <div
                className="col-name col-2 pointer"
                onClick={this.toggleOwnerDropdown}
                ref={(ref) => (this.ownerRef = ref)}
              >
                <span>SALESPERSON</span>
                <img
                  src={this.state.ownerId ? FilterActiveImage : FilterInactiveImage}
                  className="arrow-img col-arrow-img"
                />
                {this.renderUserDropdown("owner")}
              </div>
              <div
                className="col-name col-2 pointer"
                onClick={this.toggleAssignedDropdown}
                ref={(ref) => (this.assignedRef = ref)}
              >
                <span>CAMPAIGN MNGR</span>
                <img
                  src={
                    this.state.assigneeId ? FilterActiveImage : FilterInactiveImage
                  }
                  className="arrow-img col-arrow-img"
                />
                {this.renderUserDropdown("assigned")}
              </div>
            </div>
            {this.renderCampaigns()}
          </div>
        );
    }
  }

  render() {
    return (
      <div className="campaign-container" onClick={this.closeActionDropdown}>
        <div className="campaign-nav sub-header">
          <ul className="sub-header-content">
            <li
              className={this.isActive("all")}
              onClick={this.setCampaignStatus("all")}
            >
              ALL
            </li>
            <li
              className={this.isActive("discovery")}
              onClick={this.setCampaignStatus("discovery")}
            >
              DISCOVERY
            </li>
            <li
              className={this.isActive("running")}
              onClick={this.setCampaignStatus("running")}
            >
              RUNNING
            </li>
            <li
              className={this.isActive("complete")}
              onClick={this.setCampaignStatus("complete")}
            >
              COMPLETE
            </li>
            <li
              className={this.isActive("archive")}
              onClick={this.setCampaignStatus("archive")}
            >
              ARCHIVE
            </li>
          </ul>
        </div>
        <div className="campaign-menu table-menu">
          <div
            className="campaign-actions campaign-menu__button"
            onClick={this.toggleActionsDropdown}
          >
            <div className="campaign-menu__button__content">
              <span>
                SELECTED ({getSelectedCount(this.state.checkBoxes)})&nbsp;
              </span>
              <img
                className="arrow-img"
                src={this.state.isActionsOpen ? UpArrowImage : DownArrowImage}
              />
            </div>
            {this.renderActionsDropdown()}
          </div>
          <div className="campaign-menu__button">
            <a href="/campaigns/new">
              <div className="campaign-menu__button__content">
                <span>ADD A CAMPAIGN &nbsp;</span>
                <img className="plus-img" src={PlusImage} />
              </div>
            </a>
          </div>
          {this.renderDateFilter()}
        </div>
        {this.renderAlert()}
        {this.renderTable()}
        {this.renderWaypoint()}
        {this.state.selectedCampaign && (
          <CampaignStatusModal
            campaign={this.state.selectedCampaign}
            isOpen={this.state.statusModalIsOpen}
            onRequestClose={this.closeStatusModal}
            closeModal={this.closeStatusModal}
            updateCampaign={this.updateCampaign}
            contentLabel="Campaign Update Form"
          />
        )}
      </div>
    );
  }
}

export default onClickOutside(Campaigns);
