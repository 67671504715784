import React from "react";
import { FieldArray, useField, useFormikContext } from "formik";

const MultiSelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const { values } = useFormikContext();

  const trimNameIfFinance = (name) =>
    name && name.includes("Finance")
      ? name.replace("/ Credit Cards", "")
      : name;

  return (
    <FieldArray
      name={props.name}
      render={(arrayHelpers) => (
        <div
          style={{
            display: "flex",
            width: "498px",
            flexWrap: "wrap",
          }}
        >
          {props.items.map((item, i) => (
            <>
              <input
                {...field}
                {...props}
                key={i}
                className="influencer-form--checkbox"
                type="checkbox"
                id={`${props.name}[${i}]`}
                name={`${props.name}[${i}]`}
                value={item.id}
                checked={values[props.name].includes(item.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    arrayHelpers.push(item.id);
                  } else {
                    arrayHelpers.remove(values[props.name].indexOf(item.id));
                  }
                }}
              />
              <label htmlFor={`${props.name}[${i}]`}>
                {trimNameIfFinance(item.name)}
                <div className="checkmark" />
              </label>
            </>
          ))}
        </div>
      )}
    />
  );
};

export default MultiSelect;
