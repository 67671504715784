import React from "react";

const DropdownItem = (props) => (
  <li
    className="btn-dropdown--menu--item"
    onClick={props.onClick}
    target={props.target}
  >
    {props.name}
  </li>
);

export default DropdownItem;
