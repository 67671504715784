import React from "react";

const User = (props) => {
  return (
    <div className="user-container">
      <div className="user-name">{props.name}</div>
      {props.children}
    </div>
  );
};

export default User;
