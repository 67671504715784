import React from "react";
import HelpIcon from "icons/help.svg";
import ReactTooltip from "react-tooltip";


class HelpTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return(
        <span className="help-tooltip" data-tip={this.props.message}>
          <img src={HelpIcon} />
          <ReactTooltip
            className="status-tooltip"
            place="bottom"
            type="dark"
            effect="solid"
            multiline={true}
          />
        </span>
    )
  };
}

export default HelpTooltip;
