import React from "react";
import Manual from "icons/manual.svg";
import Error from "icons/error.svg";
import ReactTooltip from "react-tooltip";

// TODO: Reuse this from backend
const ERROR = [
  "has_not_oauthed",
  "missing_permissions",
  "no_business_accounts",
  "changed_password",
  "flagged_account",
  "removed_insyte",
  "reauth_required",
  "other_error",
];

const UserStatus = ({ isManual, status, message }) => {
  const hasError = ERROR.includes(status) && status;
  const checkMessage = isManual ? "Manual Added" : message;

  return (
    (isManual || hasError) && (
      <span className="status" data-tip={checkMessage}>
        {isManual ? <img src={Manual} /> : hasError && <img src={Error} />}

        <ReactTooltip
          className="status-tooltip"
          place="bottom"
          type="dark"
          effect="solid"
          multiline={true}
        />
      </span>
    )
  );
};

export default UserStatus;
