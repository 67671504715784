export const prettifyDate = (date, dateOptions) => {
  const options = dateOptions || {
    month: "short",
    day: "2-digit",
    year: "numeric",
  };

  if (date) {
    let newDate = new Date(date);
    newDate = newDate.toLocaleDateString("en-US", options);
    return newDate;
  } else {
    return "N/A";
  }
};

// checkBoxes is an object
export const selectChecked = (checkBoxes) => {
  const noAll = Object.assign({}, checkBoxes);
  if (checkBoxes.all) {
    delete noAll.all;
  }
  const ids = Object.keys(noAll).filter((key) => checkBoxes[key]);
  return ids;
};

export const getSelectedCount = (checkBoxes) => {
  let count = 0;
  Object.keys(checkBoxes).forEach((key) => {
    if (key !== "all" && checkBoxes[key]) {
      count++;
    }
  });
  return count;
};

export const isNull = (x) => x === null;

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const prettyNum = (x, options) => {
  if (!x) return 0;
  let num;
  if (options) {
    num = x.toLocaleString(undefined, options);
  } else {
    num = x.toLocaleString();
  }
  return num;
};

export const prettyCurrency = (x) => {
  return x && "$" + x;
};

export const goBack = (e) => {
  e.stopPropagation();
  e.preventDefault();
  history.back();
};

export function toFormatPercentage(number, digits = 3) {
  return (number * 100).toFixed(digits) + " %";
}

export const getMetricLabels = (key) => {
  if (!key) return "";
  const options = {
    saves: "Saves",
    shares: "Shares",
    taps_back: "Back",
    taps_forward: "Forward",
    skips: "Skip Story",
    exits: "Exits",
    links_clicks: "Link Clicks",
    photo_clicks: "Photo Clicks",
    other_clicks: "Other Clicks",
    post_clicks: "Post Clicks",
    total_play_time: "Total Play Time",
    avg_watch_time: "Avg Watch Time",
    total_views: "Total Views",
  };
  return options[key] || key;
};
