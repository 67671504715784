import React from "react";
import CloseImage from "images/modal-close@2x.png";
import Modal from "react-modal";
import Button from "../Controls/Button";
import ExternalLink from "icons/external-link.svg";
import api from "../../api";
import ReactPlayer from "react-player";

class UploadMediaModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };
  }

  componentDidMount() {
    Modal.setAppElement("body");
  }

  toggleModal = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  };

  closeModal = () => this.setState({ modalIsOpen: false });

  archivePost = () => {
    const { campaign_id, influencer_id, post_id } = this.props;
    const influencerPage = `/campaigns/${campaign_id}/influencers/${influencer_id}`;

    this.setState({ modalIsOpen: false });

    api
      .put(`${influencerPage}/posts/${post_id}/archive`)
      .then(() => (window.location.href = influencerPage));
  };

  render() {
    return (
      <div>
        <a onClick={this.toggleModal}>
          <span>Archive</span>
        </a>

        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Upload Media Modal"
          className="react-modal campaign-status-modal archive-post-modal"
        >
          <h2>Are you sure you want to archive it?</h2>

          <div className="close" onClick={this.closeModal}>
            <img src={CloseImage} className="img-fluid" />
          </div>

          <div className="archive-post-modal-content">
            <div className="archive-post-modal-content-img">
              {this.props?.type === "YoutubePost" ? (
                <ReactPlayer url={this.props.link} width="100%" />
              ) : (
                <img src={this.props.image}></img>
              )}
              <div
                className="archive-post-modal-content-link"
                onClick={() => window.open(this.props.link)}
              >
                <img src={ExternalLink} className="img-external-link" />
              </div>
            </div>
            <div className="archive-post-modal-content-info">
              <h3>{this.props.date}</h3>
              <p>{this.props.influencer_name}</p>
            </div>
          </div>

          <div className="archive-post-modal-buttons">
            <Button
              type="button"
              buttonStyle="btn--primary"
              buttonSize="btn--medium"
              onClick={this.archivePost}
            >
              Yes, archive
            </Button>
            <Button
              type="button"
              buttonStyle="btn--secondary"
              buttonSize="btn--medium"
              onClick={this.closeModal}
            >
              No, cancel
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default UploadMediaModal;
