import React from "react";
import Select from "react-select";
import * as APIUtil from "../api_util";

class InfluencerBrandForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brands: [],
      brandsWorked: [],
      brandsWanted: [],
      suggested: "",
      submitted: false,
      saved: false
    };
  }

  componentDidMount() {
    const { brands, savedBrandsWanted, savedBrandsWorked } = this.props;
    if (brands) {
      this.setState({ brands });
    }
    if (savedBrandsWorked) {
      const brandsWorked = [];
      savedBrandsWorked.forEach(brand => {
        const brandOption = {
          value: brand.id,
          label: brand.name
        };
        brandsWorked.push(brandOption);
      });
      this.setState({ brandsWorked });
    }
    if (savedBrandsWanted) {
      const brandsWanted = [];
      savedBrandsWanted.forEach(brand => {
        const brandOption = {
          value: brand.id,
          label: brand.name
        };
        brandsWanted.push(brandOption);
      });
      this.setState({ brandsWanted });
    }
  }

  getWorkedOptions = () => {
    if (this.state.brandsWorked.length < 3) {
      return this.getOptions();
    } else {
      return [
        {
          label: "Reached max items",
          value: undefined,
          isDisabled: true
        }
      ];
    }
  };

  getWantedOptions = () => {
    if (this.state.brandsWanted.length < 3) {
      return this.getOptions();
    } else {
      return [
        {
          label: "Reached max items",
          value: undefined,
          isDisabled: true
        }
      ];
    }
  };

  getOptions = () => {
    return this.state.brands.map(brand => {
      return { value: brand.id, label: brand.name };
    });
  };

  handleSelectWorked = brands => {
    if (!brands) {
      this.setState({ brandsWorked: [] });
    } else {
      this.setState({ brandsWorked: brands });
    }
  };
  handleSelectWanted = brands => {
    if (!brands) {
      this.setState({ brandsWanted: [] });
    } else {
      this.setState({ brandsWanted: brands });
    }
  };

  handleSuggestedInputChange = e => {
    this.setState({ suggested: e.target.value });
  };

  handleSubmit = () => {
    const { influencer, formType } = this.props;
    const { brandsWorked, brandsWanted, suggested } = this.state;
    const brandsData = {
      influencer: {
        brands: {
          worked: brandsWorked,
          wanted: brandsWanted
        }
      }
    };
    brandsData.influencer.brands.suggested = suggested;
    this.setState({ submitted: true });
    if (formType && formType === "edit") {
      APIUtil.updateInfluencerBrands(influencer.id, brandsData).then(() => {
        this.setState({ saved: true });
      });
    } else {
      if (brandsWorked.length === 3 && brandsWanted.length === 3) {
        APIUtil.createInfluencerBrands(influencer.id, brandsData).then(() => {
          window.location.href = "/signup-thank-you";
        });
      }
    }
  };

  renderMessage() {
    const { brandsWorked, brandsWanted, submitted, saved } = this.state;
    if (submitted && (brandsWorked.length < 3 || brandsWanted.length < 3)) {
      return (
        <p className="onboarding-error">Please select 3 brands per section.</p>
      );
    }
    if (saved) {
      return <p className="cardo mt-2">Brands Updated!</p>;
    }
  }

  renderWorkedSelect() {
    const { formType } = this.props;
    if (formType && formType === "edit") {
      return (
        <Select
          className="select-container"
          classNamePrefix="select"
          options={this.getWorkedOptions()}
          value={this.state.brandsWorked}
          isMulti
          isSearchable
          onChange={this.handleSelectWorked}
          placeholder="Search Brands"
        />
      );
    } else {
      return (
        <Select
          className="select-container"
          classNamePrefix="select"
          options={this.getWorkedOptions()}
          value={this.state.brandsWorked}
          isMulti
          isSearchable
          onChange={this.handleSelectWorked}
          placeholder="Search Brands"
        />
      );
    }
  }

  renderWantedSelect() {
    return (
      <Select
        className="select-container"
        classNamePrefix="select"
        options={this.getWantedOptions()}
        value={this.state.brandsWanted}
        isMulti
        isSearchable
        onChange={this.handleSelectWanted}
        placeholder="Search Brands"
      />
    );
  }

  render() {
    const { formType } = this.props;
    return (
      <div className="brand-affinity-form">
        <div className="brand-affinity-form__section">
          <p className="brand-affinity-form__desc">
            Top three brands you’ve worked with in the past. (Required)
          </p>
          {this.renderWorkedSelect()}
        </div>
        <div className="brand-affinity-form__section">
          <p className="brand-affinity-form__desc">
            Top three brands you’d love to work with. (Required)
          </p>
          {this.renderWantedSelect()}
        </div>
        {formType !== "edit" && (
          <div className="brand-affinity-form__section">
            <p className="brand-affinity-form__desc">
              Don’t see what you’re looking for? You can suggest multiple brands separated by comma.
            </p>
            <input
              className="suggest-brand-input"
              value={this.state.suggested}
              placeholder="TYPE BRAND NAME"
              onChange={this.handleSuggestedInputChange}
            />
          </div>
        )}
        <button onClick={this.handleSubmit} className="btn">
          {formType === "edit" ? "SAVE" : "NEXT"}
        </button>
        {this.renderMessage()}
      </div>
    );
  }
}

export default InfluencerBrandForm;
