import React from "react";
import Checkbox from "../Checkbox";
import Select from "react-select";

class ProfileFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      influencerTab: true,
      keywordOptions: [],
      influencerLocationOptions: [],
      audienceLocationOptions: [],
    };
    this.setInfluncerTab = this.setInfluncerTab.bind(this);
    this.retrieveKeywords = this.retrieveKeywords.bind(this);
    this.retrieveInfluencerLocations = this.retrieveInfluencerLocations.bind(
      this
    );
    this.retrieveAudienceLocations = this.retrieveAudienceLocations.bind(this);
  }

  setInfluncerTab(influencerTab) {
    return () => this.setState({ influencerTab });
  }

  renderFilters() {
    const { filters, handleInputChange, handleSelectChange } = this.props;
    const options = [
      { value: "<18", label: "<18" },
      { value: "18-24", label: "18-24" },
      { value: "25-34", label: "25-34" },
      { value: "35-44", label: "35-44" },
      { value: "45-54", label: "45-54" },
      { value: "55-65", label: "55-64" },
      { value: ">65", label: "65+" },
    ];

    const multiOptionsStyles = {
      clearIndicator: (base, { isMulti }) =>
        isMulti ? { display: "none" } : { ...base },
      control: (base, { selectProps }) => {
        if (selectProps.value && selectProps.value.length > 0) {
          return { ...base, height: "auto" };
        } else {
          return { ...base };
        }
      },
    };

    const creatorTypeOptions = [
      { label: "Network", value: "network" },
      { label: "Non-Exclusive", value: "non_exclusive" },
      { label: "Exclusive", value: "exclusive" },
      { label: "Prestige", value: "prestige" },
    ];

    const socialMediaOptions = [
      { label: "Facebook", value: "with_facebook" },
      { label: "YouTube", value: "with_youtube" },
      { label: "Twitter", value: "with_twitter" },
      { label: "Pinterest", value: "with_pinterest" },
      { label: "TikTok", value: "with_tiktok" },
    ]

    if (this.state.influencerTab) {
      return (
        <form className="profile-filters__form">
          <div className="filters-section">
            <label className="form-section">
              <p>LOCATION</p>
              <Select
                className="select-container no-indicator"
                classNamePrefix="select"
                value={filters.influencer_location}
                isClearable
                onChange={handleSelectChange("influencer_location")}
                onInputChange={this.retrieveInfluencerLocations}
                options={this.state.influencerLocationOptions}
              />
            </label>
            <div className="form-section">
              <p>GENDER</p>
              <Checkbox
                label="FEMALE"
                name="female"
                isFilter={true}
                checked={filters.female}
                handleCheck={handleInputChange}
              />
              <Checkbox
                label="MALE"
                name="male"
                isFilter={true}
                checked={filters.male}
                handleCheck={handleInputChange}
              />
              <Checkbox
                label="OTHER"
                name="other"
                isFilter={true}
                checked={filters.other}
                handleCheck={handleInputChange}
              />
            </div>
            <label className="form-section">
              <p>AGE</p>
              <Select
                className="select-container"
                classNamePrefix="select"
                value={filters.age}
                isClearable
                onChange={handleSelectChange("age")}
                options={options}
              />
            </label>
            <div className="form-section">
              <p>TYPE</p>
              {creatorTypeOptions.map(({ label, value }) => (
                <Checkbox
                  key={value}
                  label={label}
                  name={value}
                  isFilter={true}
                  checked={filters[value]}
                  handleCheck={handleInputChange}
                />
              ))}
            </div>
          </div>
          <div className="filters-section">
            <label className="form-section">
              <p>FOLLOWER SIZE</p>
              <input
                type="number"
                name="follower_size_min"
                value={filters.follower_size_min}
                onChange={handleInputChange}
              />{" "}
              K TO
              <input
                type="number"
                name="follower_size_max"
                value={filters.follower_size_max}
                onChange={handleInputChange}
              />{" "}
              K
            </label>
            <label className="form-section">
              <p>AVG IMPRESSIONS</p>
              <input
                type="number"
                name="impressions_min"
                value={filters.impressions_min}
                onChange={handleInputChange}
              />{" "}
              K TO
              <input
                type="number"
                name="impressions_max"
                value={filters.impressions_max}
                onChange={handleInputChange}
              />{" "}
              K
            </label>
            <label className="form-section">
              <p>AVG % REACH</p>
              <input
                type="number"
                name="reach_rate_min"
                value={filters.reach_rate_min}
                onChange={handleInputChange}
                min="0"
                max="100"
              />{" "}
              % TO
              <input
                type="number"
                name="reach_rate_max"
                value={filters.reach_rate_max}
                onChange={handleInputChange}
                min="0"
                max="100"
              />{" "}
              %
            </label>
            <label className="form-section">
              <p>AVG ENGAGEMENT RATE</p>
              <input
                type="number"
                name="engagement_rate_min"
                value={filters.engagement_rate_min}
                onChange={handleInputChange}
                min="0"
                max="100"
              />{" "}
              % TO
              <input
                type="number"
                name="engagement_rate_max"
                value={filters.engagement_rate_max}
                onChange={handleInputChange}
                min="0"
                max="100"
              />{" "}
              %
            </label>
          </div>
          <div className="filters-section keywords-filter-section">
            <label className="form-section">
              <p>KEYWORDS</p>
              <Select
                className="select-container no-indicator"
                classNamePrefix="select"
                onChange={handleSelectChange("keywords")}
                isMulti
                styles={multiOptionsStyles}
                onInputChange={this.retrieveKeywords}
                options={this.state.keywordOptions}
              />
            </label>
          </div>
          <div className="filters-section social-media-filters-section">
            <label className="form-section">
              <p>SOCIAL MEDIA</p>
              {socialMediaOptions.map(({ label, value }) => (
                <Checkbox
                  key={value}
                  customClassName='social-media-checkbox'
                  label={label}
                  name={value}
                  isFilter={true}
                  checked={filters[value]}
                  handleCheck={handleInputChange}
                />
              ))}
            </label>
          </div>
        </form>
      );
    } else {
      return (
        <form className="profile-filters__form audience-form">
          <label className="gender-label">
            <p>FEMALE</p>
            <input
              type="number"
              name="audience_female"
              placeholder="0"
              value={filters.audience_female}
              onChange={handleInputChange}
              min="0"
              max="100"
            />{" "}
            %
          </label>
          <label className="gender-label">
            <p>MALE</p>
            <input
              type="number"
              name="audience_male"
              placeholder="0"
              value={filters.audience_male}
              onChange={handleInputChange}
              min="0"
              max="100"
            />{" "}
            %
          </label>
          <label>
            <p>AGE</p>
            <Select
              className="select-container"
              classNamePrefix="select"
              value={filters.audience_age}
              isClearable
              onChange={handleSelectChange("audience_age")}
              options={options}
            />
          </label>
          <div>
            <label>
              <p>LOCATION</p>
              <Select
                className="select-container no-indicator auto-height"
                classNamePrefix="select"
                onChange={handleSelectChange("audience_locations")}
                onInputChange={this.retrieveAudienceLocations}
                isMulti
                options={this.state.audienceLocationOptions}
                styles={multiOptionsStyles}
              />
            </label>
          </div>
          {filters.audience_locations.length > 0 && (
            <label className="gender-label">
              <input
                type="number"
                name="audience_location_percentage"
                placeholder="0"
                value={filters.audience_location_percentage}
                onChange={handleInputChange}
                min="0"
                max="100"
              />{" "}
              %
            </label>
          )}
        </form>
      );
    }
  }

  setInfluencerActive() {
    if (this.state.influencerTab) return "active";
  }

  setAudienceActive() {
    if (!this.state.influencerTab) return "active";
  }

  retrieveKeywords(input) {
    if (input.length >= 2) {
      $.ajax({
        method: "GET",
        dataType: "json",
        contentType: "application/json",
        url: "/api/v1/influencers/keyword_search",
        data: { search_term: input },
      }).then((response) => {
        this.setState({
          keywordOptions: response.results,
        });
      });
    }
  }

  retrieveInfluencerLocations(input) {
    if (input.length >= 2) {
      $.ajax({
        method: "GET",
        dataType: "json",
        contentType: "application/json",
        url: "/api/v1/influencers/google_autocomplete",
        data: {
          search_term: input,
        },
      }).then((response) => {
        this.setState({
          influencerLocationOptions: response.results,
        });
      });
    }
  }

  retrieveAudienceLocations(input) {
    if (input.length >= 2) {
      $.ajax({
        method: "GET",
        dataType: "json",
        contentType: "application/json",
        url: "/api/v1/influencers/audience_location_search",
        data: {
          search_term: input,
        },
      }).then((response) => {
        this.setState({
          audienceLocationOptions: response.results,
        });
      });
    } else {
      this.setState({
        audienceLocationOptions: [],
      });
    }
  }

  render() {
    return (
      <div className="profile-filters">
        <div className="filters-header">
          <h4>FILTERS</h4>
          <div className="filters-tabs">
            <div
              className={`tab influencer-tab ${this.setInfluencerActive()}`}
              onClick={this.setInfluncerTab(true)}
            >
              INFLUENCER
            </div>
            <div
              className={`tab audience-tab ${this.setAudienceActive()}`}
              onClick={this.setInfluncerTab(false)}
            >
              AUDIENCE
            </div>
          </div>
        </div>
        <div className="filters-form-container">{this.renderFilters()}</div>
      </div>
    );
  }
}

export default ProfileFilters;
