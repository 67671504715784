import React from 'react';
import Select from 'react-select';
import Async, { makeAsyncSelect } from 'react-select/async';
import * as APIUtil from "./../api_util";
import TablePendingCampaigns from './campaigns/Table/TablePendingCampaigns.jsx'
import TableActiveCampaigns from './campaigns/Table/TableActiveCampaigns.jsx'
import TableContractingCampaigns from './campaigns/Table/TableContractingCampaigns.jsx'
import TableBillsDue from './bills/Table/TableBillsDue.jsx'
import debounce from 'lodash/debounce'

class ManagerDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contractingCampaigns: [],
      pendingCampaigns: [],
      activeCampaigns: [],
      billsDue: [],
      isEmpty: false,
      isLoading: false,
    };
    this.loadInfluencerDashboard = this.loadInfluencerDashboard.bind(this);
    this._debouncedSearch = debounce(this.searchInfluencer, 500);
  }

  searchInfluencer(query, callback) {
    APIUtil.searchInfluencers(query).then((response) => {
      let a = response.influencers.map((influencer) => {
        return {
          value: influencer.id,
          label: influencer.full_name + " @" + influencer.instagram_username
        }
      });
      callback(a);
    });
  }

  loadInfluencerDashboard(influencer) {
    this.setState({
      contractingCampaigns: [],
      pendingCampaigns:     [],
      activeCampaigns:      [],
      billsDue:             [],
      isEmpty:              false,
    });

    if (!influencer) {
      return;
    }

    this.setState({ isLoading: true });

    APIUtil.getInfluencerDashboards(influencer.value).then(
      (dashboards) => {
        let isEmpty = dashboards.contracting_campaigns.length == 0 &&
                      dashboards.pending_campaigns.length == 0 &&
                      dashboards.active_campaigns.length == 0 &&
                      dashboards.bills_due.length == 0;

        this.setState({
          contractingCampaigns: dashboards.contracting_campaigns,
          pendingCampaigns:     dashboards.pending_campaigns,
          activeCampaigns:      dashboards.active_campaigns,
          billsDue:             dashboards.bills_due,
          isEmpty:              isEmpty,
          isLoading:            false
        });
      }
    );
  }

  renderContractingCampaigns() {
    if (this.state.contractingCampaigns.length > 0) {
      return(
        <div className="dashboard-table">
          <TableContractingCampaigns campaigns={this.state.contractingCampaigns} hideFooter={true}/>
        </div>
      );
    }
  }

  renderPendingCampaigns() {
    if (this.state.pendingCampaigns.length > 0) {
      return(
        <div className="dashboard-table">
          <TablePendingCampaigns campaigns={this.state.pendingCampaigns} hideFooter={true}/>
        </div>
      );
    }
  }

  renderActiveCampaigns() {
    if (this.state.activeCampaigns.length > 0) {
      return(
        <div className="dashboard-table">
          <TableActiveCampaigns campaigns={this.state.activeCampaigns} hideFooter={true}/>
        </div>
      );
    }
  }

  renderBillsDue() {
    if (this.state.billsDue.length > 0) {
      return(
        <div className="dashboard-table">
          <TableBillsDue bills={this.state.billsDue} hideFooter={true}/>
        </div>
      );
    }
  }

  renderEmptyDashboard() {
    if (this.state.isEmpty) {
      return(
        <div className="empty-dashboards-container">
          This influencer does not have any information.
        </div>
      );
    }
  }

  renderLoading() {
    if (this.state.isLoading) return <div className="loader"></div>;
  }

  render() {
    return (
      <div>
        <div className="influencer-form">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label>Find Influencer</label>
            <Async
              className="select-container"
              classNamePrefix="select"
              cacheOptions
              defaultOptions={[]}
              isClearable
              className="basic-single"
              classNamePrefix="select"
              name="search"
              loadOptions={this._debouncedSearch}
              onChange={this.loadInfluencerDashboard}
              />
          </div>
        </div>
        <div>{this.renderLoading()}</div>
        <div>{this.renderContractingCampaigns()}</div>
        <div>{this.renderPendingCampaigns()}</div>
        <div>{this.renderActiveCampaigns()}</div>
        <div>{this.renderBillsDue()}</div>
        <div>{this.renderEmptyDashboard()}</div>
      </div>
    );
  }
}

export default ManagerDashboard;
