import React from "react";
import * as APIUtil from "../api_util";

class InfluencerInterestForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      interests: {},
      saved: false,
      error: ""
    };
  }
  componentDidMount() {
    const { interests, savedInterests } = this.props;
    if (interests) {
      const interestState = {};
      interests.forEach(interest => {
        interestState[interest.name] = false;
      });
      this.setState({ interests: interestState }, () => {
        if (savedInterests) {
          const interestState = Object.assign({}, this.state.interests);
          savedInterests.forEach(interest => {
            interestState[interest.name] = true;
          });
          this.setState({ interests: interestState });
        }
      });
    }
  }

  toggleInterest = interest => {
    return () => {
      const newInterestState = Object.assign({}, this.state.interests);
      newInterestState[interest] = !newInterestState[interest];
      this.setState({ interests: newInterestState });
    };
  };

  renderInterests() {
    return Object.keys(this.state.interests).map(interest => {
      return (
        <div
          key={interest}
          onClick={this.toggleInterest(interest)}
          className={`interest-option${
            this.state.interests[interest] ? " picked" : ""
          }`}
        >
          <p>{interest.toUpperCase()}</p>
        </div>
      );
    });
  }

  renderSavedMessage() {
    if (this.state.saved) {
      return <p className="cardo mt-2">Interests Updated!</p>;
    }
  }

  handleSubmit = () => {
    const { influencer, user, formType } = this.props;
    const selectedInterests = Object.keys(this.state.interests).filter(
      interest => {
        return this.state.interests[interest];
      }
    );
    const data = { influencer: { interests: selectedInterests } };
    if (formType && formType === "edit") {
      APIUtil.updateInfluencerInterests(influencer.id, data).then(() => {
        this.setState({ saved: true });
      });
    } else {
      APIUtil.createInfluencerInterests(influencer.id, data)
        .then(() => {
          window.location.href = `/user/brands`;
        })
        .catch(error => {
          if (error.responseJSON && error.responseJSON.message) {
            this.setState({ error: error.responseJSON.message });
          }
        });
    }
  };

  render() {
    const { formType } = this.props;
    return (
      <div className="interest-form">
        {this.state.error && !formType && (
          <div className="alert alert-danger">{this.state.error}</div>
        )}
        <div className="interest-options-container">
          <p className="brand-affinity-form__desc">
            Please select all categories you would promote.
          </p>
          {this.renderInterests()}
        </div>
        <button onClick={this.handleSubmit} className="btn">
          {formType === "edit" ? "SAVE" : "NEXT"}
        </button>
        {this.renderSavedMessage()}
      </div>
    );
  }
}

export default InfluencerInterestForm;
