import React from "react";
import { prettyNum, prettifyDate, toFormatPercentage } from "../../../util";
import ReactPlayer from "react-player";
import Table from "./Table";
import ArrowUp from "icons/arrow-up.svg";
import ErrorIcon from "icons/error.svg";
import ArrowDown from "icons/arrow-down.svg";
import ExternalLink from "icons/external-link.svg";

const TableYoutubePosts = (props) => {
  const data = React.useMemo(() =>
    props.post.map(
      (post) => ({
        postImage: {
          image: post.permalink,
          link: post.permalink,
        },
        name: post.influencer.full_name,
        postDate: post.timestamp,
        postSubscribers: post.subscribers,
        postViews: post.views,
        postEngagement: post.engagement,
        postEngagementRate: post.engagement_rate,
        subRows: post.subRows,
      }),
      []
    )
  );

  const postViewsCol = {
    Header: "Views",
    accessor: "postViews",
    align: "align-right",
    Cell: (props) => (props && props.value ? prettyNum(props.value) : null),
    tooltip: "Total views",
    Footer: (info) => {
      const rows = info.rows.filter((row) => row.depth === 0);
      const total = React.useMemo(
        () => rows.reduce((sum, row) => row.values.postViews + sum, 0),
        [rows]
      );

      return prettyNum(total);
    },
  };

  const postEngagementCol = {
    Header: "Engagement",
    accessor: "postEngagement",
    align: "align-right",
    number: true,
    Cell: (props) => (props && props.value ? prettyNum(props.value) : null),
    tooltip: "Likes + comments",
    Footer: (info) => {
      const rows = info.rows.filter((row) => row.depth === 0);
      const total = React.useMemo(
        () => rows.reduce((sum, row) => row.values.postEngagement + sum, 0),
        [rows]
      );

      return prettyNum(total);
    },
  };

  const postEngagementRateCol = {
    Header: "Eng. Rate",
    accessor: "postEngagementRate",
    align: "align-right",
    tooltip: "Engagements as a percentage of subscribers",
    Cell: (props) =>
      props && props.value ? toFormatPercentage(props.value) : null,
    Footer: (info) => {
      /** Filter basic row (without collapsed ones) */
      const rows = info.rows.filter((row) => row.depth === 0);
      return toFormatPercentage(getAverage(rows));
    },
  };

  const expandableButtonCol = {
    id: "expander",
    align: "align-center",
    className: "collapse-button",
    Cell: ({ row }) =>
      row.canExpand ? (
        <span {...row.getToggleRowExpandedProps()}>
          <img
            className="collapse-icon"
            src={row.isExpanded ? ArrowUp : ArrowDown}
          />
        </span>
      ) : null,
  };

  const rawColumns = [
    {
      Header: "",
      accessor: "postImage",
      align: "align-left",
      Cell: (props) => {
        return props?.value.image ? (
          <div className="row-media">
            <div className="player-container" />
            <ReactPlayer url={props?.value.image} width="100%" />
            {props.value.link == null ? (
              <div
                className="row-media-link"
                title="Media contained copyrighted material and a copy of it could not be saved"
              >
                <img src={ErrorIcon} className="row-media-link-icon" />
              </div>
            ) : (
              <div
                style={{ zIndex: 2 }}
                className="row-media-link"
                onClick={() => window.open(props?.value.link)}
              >
                <img src={ExternalLink} className="row-media-link-icon" />
              </div>
            )}
          </div>
        ) : null;
      },
      Footer: "Total",
    },
    {
      Header: "Influencer",
      accessor: "name",
      align: "align-left",
    },
    {
      Header: "Date",
      accessor: "postDate",
      align: "align-left",
      Cell: (props) =>
        props && props.value ? prettifyDate(props.value) : null,
    },
    {
      Header: "Subscribers",
      accessor: "postSubscribers",
      align: "align-right",
      Cell: (props) => (props && props.value ? prettyNum(props.value) : null),
    },
  ];

  if (data.reduce((sum, x) => x.postViews + sum, 0) !== 0) {
    rawColumns.push(postViewsCol);
  }

  if (data.reduce((sum, x) => x.postEngagement + sum, 0) !== 0) {
    rawColumns.push(postEngagementCol);
  }

  rawColumns.push(postEngagementRateCol);

  if (data.some((post) => post.subRows && post.subRows.length > 0)) {
    rawColumns.push(expandableButtonCol);
  }

  const columns = React.useMemo(
    () => [
      {
        id: "posts",
        Header: () => null,
        Footer: () => null,
        columns: rawColumns,
      },
    ],
    []
  );

  const getAverage = (numbers) => {
    return (
      numbers.reduce(
        (sum, row) => sum + parseFloat(row.values.postEngagementRate),
        0
      ) / numbers.length
    );
  };

  const renderRowSubComponent = React.useCallback(({ row }) => {
    return row && row.originalSubRows ? (
      <div className="d-flex">
        {row.originalSubRows.map((subRow) => {
          return (
            <div className="post-metrics-container">
              <div className="metric-title">
                <span>{subRow.title}</span>
              </div>

              {subRow.fields.map((field) => (
                <div className="metric-item">
                  <span>{field.key}</span>
                  <span>{field.value}</span>
                </div>
              ))}
            </div>
          );
        })}
      </div>
    ) : null;
  });

  return (
    <Table
      columns={columns}
      data={data}
      renderRowSubComponent={renderRowSubComponent}
    />
  );
};

export default TableYoutubePosts;
