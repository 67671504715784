import React from "react";
import PropTypes from "prop-types";
import MagnifyGlassImage from "images/magnify-glass-dark@2x.png";
import Cross from "icons/cross.svg";
import debounce from 'lodash/debounce';

class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      isOpen: false,
      options: props.options,
      selectedOptions: [],
    };
    this.input;
    this._selectOption = this._selectOption.bind(this);
    this._focus = this._focus.bind(this);
    this._removeSelectedOption = this._removeSelectedOption.bind(this);
    this._handleInputChange = this._handleInputChange.bind(this);
    this._handleOutsideClick = this._handleOutsideClick.bind(this);
    this._handleFocus = this._handleFocus.bind(this);
    this._debouncedSearch =  debounce(() => this.props.handleSearch(this.state.value), 500);
  }

  componentDidMount() {
    if (this.props.onRef != null) {
      this.props.onRef(this.input);
    }
    document.addEventListener("mousedown", this._handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this._handleOutsideClick, false);
  }

  _handleOutsideClick(e) {
    if (this.node.contains(e.target)) {
      return;
    }
    this.setState({ isOpen: false });
  }

  componentDidUpdate(prevProps, prevState) {
    const { options, handleSearch, setSelectedOptions, selectedOptions } = this.props;

    if (options.length !== prevProps.options.length) {
      this.setState({ options });
    }
    if (selectedOptions && selectedOptions.length !== prevProps.selectedOptions.length) {
      this.setState({ selectedOptions })
    }
    if (this.state.selectedOptions.length !== prevState.selectedOptions.length) {
      setSelectedOptions(this.state.selectedOptions);
    }
  }

  _focus() {
    if (this.input && this.input.focus) {
      this.input.focus();
    }
  }
  _handleFocus() {
    if (this.state.value && !this.state.isOpen) {
      this.setState({ isOpen: true });
    }
  }

  renderDropdown() {
    const { isOpen } = this.state;
    if (isOpen) {
      return (
        <div className="dropdown influencer-dropdown">
          <ul>{this.renderOptions()}</ul>
        </div>
      );
    }
  }

  _selectOption(option) {
    const newOptions = [...this.state.selectedOptions];
    let exists = false;
    for (let i = 0; i < newOptions.length; i++) {
      if (newOptions[i].id === option.id) {
        exists = true;
        break;
      }
    }
    if (!exists) {
      newOptions.push(option);
    }
    return (e) => {
      e.preventDefault();
      this.setState({
        isOpen: false,
        value: "",
        selectedOptions: newOptions,
      });
    };
  }

  renderOptions() {
    const { options } = this.props;
    const { isInfluencer } = this.props;
    if (options.length > 0) {
      return options.map((option, i) => {
        if (option.name) {
          return (
            <li
              key={i}
              onClick={this._selectOption(option)}
              className="flex pointer"
            >
              <div className="vert-center">
                {isInfluencer ? (
                  <span>{option.full_name}</span>
                ) : (
                  <span>{option.name}</span>
                )}
              </div>
            </li>
          );
        }
      });
    } else {
      return (
        <li>
          <div className="vert-center">
            <div className="no-result">No Result</div>
          </div>
        </li>
      );
    }
  }

  renderMultiValues() {
    const { isInfluencer } = this.props;
    const { selectedOptions } = this.state;
    if (selectedOptions.length > 0) {
      return selectedOptions.map((option, i) => {
        return (
          <div key={i} className="multi-value-container">
            <div className="multi-value">
              {isInfluencer ? (
                <div className="multi-value__label">
                  (
                  <div className="profile-image-container">
                    <img src={option.profile_picture_url} />
                  </div>
                  <div className="name-container">{option.full_name}</div>
                </div>
              ) : (
                <div className="multi-value__label">
                  <div className="name-container">{option.name}</div>
                </div>
              )}

              <div
                className="multi-value__remove"
                onClick={this._removeSelectedOption(i)}
              >
                <img className="small-icon" src={Cross} />
              </div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="search-input__indicator vert-center">
          <div className="search-image-container">
            <img src={MagnifyGlassImage} />
          </div>
        </div>
      );
    }
  }

  _removeSelectedOption(index) {
    const newOptions = [...this.state.selectedOptions];
    newOptions.splice(index, 1);
    return (e) => {
      e.stopPropagation();
      this.setState({ selectedOptions: newOptions });
      // this.props.setSelectedOptions(newOptions);
    };
  }

  searchPlaceHolder() {
    if (!this.state.selectedOptions.length > 0) {
      return "Search";
    }
  }
  _handleInputChange(e) {
    const query = e.target.value;
    if (query != "") {
      this.setState({ value: query, isOpen: true }, () =>
        this._debouncedSearch()
      );
    } else {
      this.setState({ value: query, isOpen: false });
    }
  }

  render() {
    const {
      options,
      setSelectedOptions,
      onRef,
      isInfluencer,
      handleSearch,
      selectedOptions,
      ...props
    } = this.props;
    return (
      <div className="form-multi-search" ref={(node) => (this.node = node)}>
        <div className="flex">
          <div className="search-input" onClick={this._focus}>
            {this.renderMultiValues()}
            <input
              tabIndex="0"
              ref={(ref) => (this.input = ref)}
              placeholder={this.searchPlaceHolder()}
              value={this.state.value}
              onChange={this._handleInputChange}
              onFocus={this._handleFocus}
              {...props}
            ></input>
            {this.renderDropdown()}
          </div>
        </div>
      </div>
    );
  }
}
SearchInput.propTypes = {
  options: PropTypes.array.isRequired,
  setSelectedOptions: PropTypes.func.isRequired,
  isInfluencer: PropTypes.bool,
  onRef: PropTypes.func,
  handleSearch: PropTypes.func.isRequired,
  selectedOptions: PropTypes.array,
};

export default SearchInput;
