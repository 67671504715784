import React from "react";
import SocialyteStar from "icons/socialyte-star.svg";
import ReactTooltip from "react-tooltip";

const staredTypes = {
  prestige: "Prestige",
  exclusive: "Exclusive",
  non_exclusive: "Non-exclusive",
};

const Avatar = ({ influencer, size }) => {
  const starLabel = staredTypes[influencer.influencer_type];

  if (size === "small") {
    return (
      <div className="profile-image-small">
        <div className="profile-image-small-container">
          <img src={influencer.profile_picture_url} />
        </div>

        {starLabel && (
          <div>
            <div className="profile-image-small-container-type" data-tip={starLabel}>
              <img
                className="profile-image-small-container-icon"
                src={SocialyteStar}
              />
            </div>
            <ReactTooltip
              className="status-tooltip"
              place="bottom"
              type="dark"
              effect="solid"
            />
          </div>
        )}
      </div>
    );
  } else if (size === "large") {
    return (
      <div className="profile__image-container">
        <img src={influencer.profile_picture_url} />
        {starLabel && (
          <div>
            <div className="profile-type-container" data-tip={starLabel}>
              <img className="profile-type-icon" src={SocialyteStar} />
            </div>
            <ReactTooltip
              className="status-tooltip"
              place="bottom"
              type="dark"
              effect="solid"
            />
          </div>
        )}
      </div>
    );
  }
};

export default Avatar;
