import React from "react";
import { VictoryChart, VictoryAxis, VictoryArea } from "victory";
import HelpTooltip from "./HelpTooltip";

class InfluencerAccountStatsGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minGraphLength: 5,
      tabs: {
        followers: false,
        page_views: false,
        impressions: false,
        reach: false,
      },
      data: {
        followers: this.props.follower_count_data || [],
        page_views: this.props.profile_views_data || [],
        impressions: this.props.impressions_data || [],
        reach: this.props.reach_data || [],
      },
      currentChartData: [],
    };
    this.setTab = this.setTab.bind(this);
    this.sanitizeChartData = this.sanitizeChartData.bind(this);
  }

  initialTabs() {
    return {
      followers: false,
      page_views: false,
      impressions: false,
      reach: false,
    };
  }

  setTab(tab) {
    const tabs = this.initialTabs();
    tabs[tab] = true;
    const newData = this.sanitizeChartData(tab);
    this.setState({
      tabs: tabs,
      currentChartData: newData,
    });
  }

  sanitizeChartData(tab) {
    const originalData = this.state.data[tab];
    const options = {
      followers: originalData.map((point) => {
        return { x: new Date(point.timestamp), y: point.follower_count };
      }),
      page_views: originalData.map((point) => {
        return { x: new Date(point.timestamp), y: point.profile_views };
      }),
      impressions: originalData.map((point) => {
        return { x: new Date(point.timestamp), y: point.impressions };
      }),
      reach: originalData.map((point) => {
        return { x: new Date(point.timestamp), y: point.reach };
      }),
    };
    return options[tab] || [];
  }

  defaultTickLabelFormat(x) {
    if (x > 1000000) {
      return `${x / 1000000}M`;
    } else if (x > 1000) {
      return `${x / 1000}K`;
    } else {
      return x;
    }
  }

  getDefaultTab() {
    const { data, minGraphLength } = this.state;

    const tabDataKey = Object.entries(data).find(
      ([_, items]) => items?.length > minGraphLength
    );
    return tabDataKey ? tabDataKey[0] : null;
  }

  componentDidMount() {
    const defaultTab = this.getDefaultTab();

    if (defaultTab) {
      this.setTab(defaultTab);
    }
  }

  followersDomain() {
    if (!this.state.tabs.followers) return null;
    const { follower_count_data } = this.props;
    const followerCount = follower_count_data.map((obj) => obj.follower_count);

    let max = followerCount[0] || 0;
    let min = followerCount[0] || 1;

    followerCount.forEach((count) => {
      if (count > max) max = count;
      else if (count < min) min = count;
    });
    return { y: [min, max] };
  }

  render() {
    const {
      minGraphLength,
      tabs,
      data: { followers, page_views, impressions, reach },
    } = this.state;

    return (
      <div>
        <div className="tabs">
          {followers.length > minGraphLength ? (
            <div
              className={`tab ${tabs.followers ? "active" : ""}`}
              onClick={() => this.setTab("followers")}
            >
              <span>FOLLOWERS</span>
            </div>
          ) : null}
          {impressions.length > minGraphLength ? (
            <div
              className={`tab ${tabs.impressions ? "active" : ""}`}
              onClick={() => this.setTab("impressions")}
            >
              <HelpTooltip message="Total Views" />
              <span>IMPRESSIONS</span>
            </div>
          ) : null}
          {reach.length > minGraphLength ? (
            <div
              className={`tab ${tabs.reach ? "active" : ""}`}
              onClick={() => this.setTab("reach")}
            >
              <HelpTooltip message="Unique Views" />
              <span>REACH</span>
            </div>
          ) : null}
          {page_views.length > minGraphLength ? (
            <div
              className={`tab ${tabs.page_views ? "active" : ""}`}
              onClick={() => this.setTab("page_views")}
            >
              <HelpTooltip message="Unique Profile Page Views" />
              <span>PAGE VIEWS</span>
            </div>
          ) : null}
        </div>
        <VictoryChart width={700} height={350} scale={{ x: "time" }}>
          <VictoryAxis
            style={{
              tickLabels: {
                fontFamily: "Cardo, sans-serif",
                fill: "#8B8B8B",
                fontSize: "11px",
              },
            }}
          />
          <VictoryAxis
            dependentAxis
            tickFormat={this.defaultTickLabelFormat}
            style={{
              tickLabels: {
                fontFamily: "Josefin Sans, san-serif",
                fill: "#8B8B8B",
                fontSize: "10px",
                letterSpacing: "2px",
                fontWeight: "bold",
              },
              grid: {
                stroke: "#C9C9C9",
              },
            }}
          />
          <VictoryArea
            style={{
              data: { stroke: "#121212", fill: "#C9C9C9", opacity: ".5" },
            }}
            data={this.state.currentChartData}
            domain={this.followersDomain()}
          />
        </VictoryChart>
      </div>
    );
  }
}

export default InfluencerAccountStatsGraph;
