import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';

class LocationChooser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      city: props.city,
      lat: props.lat,
      lng: props.lng,
      searchOptions: {
        types: ['(regions)']
      }
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.resetLocationInputs = this.resetLocationInputs.bind(this)
  }

  handleChange(city) {
    if (city == '') {
      this.resetLocationInputs()
    } else {
      this.setState({ city });
    }
  };
 
  handleSelect(city) {
    this.setState({ city })
    geocodeByAddress(city)
      .then(results => getLatLng(results[0]))
      .then(latLng => this.setState({lat: latLng["lat"], lng: latLng["lng"]}))
      .catch(error => this.resetLocationInputs);
  };
 
  resetLocationInputs() {
    this.setState({
      city: '',
      lat: null,
      lng: null
    })
  }

  render() {
    const { city, lat, lng } = this.state

    var cityInput
    var latInput
    var lngInput

    // For now implies that a person picked a location from the Google dropdown
    if (city != '' && lat != null && lng != null) {
      cityInput = <input type="hidden" name="influencer[location_name]" value={city}/>
      latInput = <input type="hidden" name="influencer[latitude]" value={lat}/>
      lngInput = <input type="hidden" name="influencer[longitude]" value={lng}/>
    }

    var mapUrl = "https://maps.googleapis.com/maps/api/staticmap?center=40.714728,-73.998672&zoom=12&size=400x400&key=" + this.props.googleApiKey
    var fontClass = this.props.onAdminPage ? "" : "josefin"

    return (
      <div>
        <PlacesAutocomplete
          value={this.state.city}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          searchOptions={this.state.searchOptions}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div style={{position: "relative"}}>
              <label className={fontClass}>City*</label>
              <input
                {...getInputProps({
                  placeholder: 'Search Places',
                  className: 'form-control ' + fontClass,
                })}
              />
              <div className={suggestions.length > 0 ? "autocomplete-dropdown-container bordered" : "autocomplete-dropdown-container"}>
                {loading && <div className="loading-div">Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item suggestion-item--active ' + fontClass
                    : 'suggestion-item ' + fontClass;
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        {cityInput}
        {latInput}
        {lngInput}
      </div>
    )
  }
}

export default LocationChooser;
