import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useField, useFormikContext } from "formik";

const CityDropdown = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldError, setFieldTouched, setFieldValue } = useFormikContext();

  const handleError = (err) => {
    setFieldError(field.name, err);
  };

  const handleChange = (location) => {
    setFieldTouched(field.name);
    setFieldValue(field.name, location);
  };

  const handleSelect = (location) => {
    geocodeByAddress(location)
      .then((res) => {
        getLatLng(res[0])
          .then((latLng) => {
            setFieldValue('latitude', latLng['lat']);
            setFieldValue('longitude', latLng['lng']);
          }
        )
      })
      .then((latLng) => {
        setFieldValue(field.name, location);
      })
      .catch((err) => setFieldError(field.name, err));
  };

  return (
    <label className="influencer-form--field-label">
      <span className="label">
        {label}
        <span style={{ color: "red" }}>{props.isRequired && "*"}</span>
      </span>
      <PlacesAutocomplete
        name={name}
        id={name}
        {...props}
        value={props.values[field.name]}
        onChange={(val) => handleChange(val)}
        onSelect={(val) => handleSelect(val)}
        onError={(err) => handleError(err)}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                className: "location-dropdown",
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggstion-item";
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#fff", cursor: "pointer" };
                return (
                  <div
                    className="dropdown-item"
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </label>
  );
};

export default CityDropdown;
