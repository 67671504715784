import React from "react";
import merge from "lodash/merge";
import GroupRow from "./GroupRow";
import AddToCampaignModal from "./AddToCampaignModal";
import Plus from "icons/plus.svg";
import Button from "../Controls/Button";
import * as APIUtil from "../../api_util";
import Checkbox from "../Checkbox";
import Dropdown from "../Controls/Dropdown";
import DropdownItem from "../Controls/DropdownItem";
import onClickOutside from "react-onclickoutside";
import mapValues from "lodash.mapvalues";
import { selectChecked, getSelectedCount } from "../../util";
import Modal from "react-modal";

class Groups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      checkBoxes: {
        all: false,
      },
      groups: {},
      isActionsOpen: false,
      addModalIsOpen: false,
      selectedGroup: null,
      isLoading: false,
    };
    this.fetchGroups = this.fetchGroups.bind(this);
    this.toggleActionsDropdown = this.toggleActionsDropdown.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.closeActionDropdown = this.closeActionDropdown.bind(this);
    this.deleteSelectedGroups = this.deleteSelectedGroups.bind(this);
    this.closeAddModal = this.closeAddModal.bind(this);
    this.openAddModal = this.openAddModal.bind(this);
  }

  componentDidMount() {
    this.fetchGroups().then(() => window.scrollTo(0, 0));
  }

  fetchGroups(page) {
    this.setState({ isLoading: true });
    return APIUtil.fetchGroups(page).then((groups) => {
      const checkBoxes = {
        ...mapValues(groups, () => false),
        all: false,
      };
      this.setState({ groups, checkBoxes, isLoading: false });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { checkBoxes } = this.state;
    //uncheck 'all'
    if (prevState.checkBoxes.all && !checkBoxes.all) {
      let newCheckBoxes = Object.assign({}, checkBoxes);
      newCheckBoxes = mapValues(newCheckBoxes, () => false);
      newCheckBoxes = Object.assign({}, newCheckBoxes);
      this.setState({ checkBoxes: newCheckBoxes });
    }
    //check 'all'
    if (!prevState.checkBoxes.all && checkBoxes.all) {
      let newCheckBoxes = Object.assign({}, checkBoxes);
      newCheckBoxes = mapValues(newCheckBoxes, () => true);
      newCheckBoxes = Object.assign({}, newCheckBoxes);
      this.setState({ checkBoxes: newCheckBoxes });
    }
  }

  handleClickOutside() {
    this.closeActionDropdown();
  }
  closeActionDropdown() {
    this.setState({ isActionsOpen: false });
  }

  openAddModal() {
    const checkBoxes = Object.assign({}, this.state.checkBoxes);
    const ids = selectChecked(checkBoxes);
    if (ids.length > 0) {
      this.setState({ addModalIsOpen: true });
    }
  }
  closeAddModal() {
    this.setState({ addModalIsOpen: false });
  }

  handleCheck(id) {
    return (e) => {
      e.stopPropagation();
      const newCheckBoxes = Object.assign({}, this.state.checkBoxes);
      newCheckBoxes[id] = !newCheckBoxes[id];
      this.setState({ checkBoxes: newCheckBoxes });
    };
  }

  toggleActionsDropdown(e) {
    e.stopPropagation();
    this.setState(({ isActionsOpen }) => ({ isActionsOpen: !isActionsOpen }));
  }

  renderGroups() {
    if (Object.values(this.state.groups).length > 0) {
      return Object.values(this.state.groups).map((group, i) => {
        return (
          <GroupRow
            key={i}
            group={group}
            checked={this.state.checkBoxes[group.id]}
            handleCheck={this.handleCheck}
          />
        );
      });
    } else {
      if (this.state.isLoading) {
        return <div className="loader"></div>;
      }
      return (
        <div className="row no-results-row">
          <div className="vert-center col-12">
            <p>No groups created yet. Create one above!</p>
          </div>
        </div>
      );
    }
  }

  renderActionsDropdown() {
    if (this.state.isActionsOpen) {
      return (
        <div className="dropdown campaign-menu__dropdown">
          <ul>
            <li onClick={this.openAddModal}>ADD TO CAMPAIGN</li>
            <li onClick={this.deleteSelectedGroups}>DELETE</li>
          </ul>
        </div>
      );
    }
  }

  deleteSelectedGroups() {
    const checkBoxes = Object.assign({}, this.state.checkBoxes);
    const ids = selectChecked(checkBoxes);
    if (ids.length > 0) {
      APIUtil.deleteGroups(ids).then(
        (groups) => {
          const newGroups = merge({}, this.state.groups, groups);
          ids.forEach((id) => {
            delete checkBoxes[id];
            delete newGroups[id];
          });
          checkBoxes.all = false;
          this.setState({ groups: newGroups, checkBoxes: checkBoxes });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  renderTable() {
    const { checkBoxes } = this.state;
    return (
      <div className="campaign-table socialyte-table">
        <div className="row campaign-head-row socialyte-head-row">
          <div className="col-name flex-1">
            <Checkbox
              handleCheck={this.handleCheck("all")}
              checked={checkBoxes.all}
            />
          </div>
          <div className="col-name flex-5">GROUP</div>
          <div className="col-name flex-3"># OF INFLUENCERS</div>
          <div className="col-name flex-3">DATE CREATED</div>
          <div className="col-name flex-3">OWNER</div>
        </div>
        {this.renderGroups()}
      </div>
    );
  }

  render() {
    const checkBoxes = Object.assign({}, this.state.checkBoxes);
    const ids = selectChecked(checkBoxes);
    return (
      <div className="campaign-container" onClick={this.closeActionDropdown}>
        <div className="campaign-nav sub-header"></div>
        <div className="campaign-menu table-menu">
          <Dropdown
            className="table-menu-button"
            name={"Selected (" + getSelectedCount(checkBoxes) + ")"}
          >
            <DropdownItem
              name="Add to Campaign"
              onClick={this.openAddModal}
            ></DropdownItem>
            <DropdownItem
              name="Delete"
              onClick={this.deleteSelectedGroups}
            ></DropdownItem>
          </Dropdown>

          <Button
            onClick={() => (window.location.href = "/groups/new")}
            type="button"
            icon={Plus}
            buttonStyle="btn--secondary"
            buttonSize="btn--medium"
            className="table-menu-button"
          >
            Add a Group
          </Button>
        </div>
        {this.renderTable()}
        {ids && (
          <AddToCampaignModal
            groupIds={ids}
            isOpen={this.state.addModalIsOpen}
            onRequestClose={this.closeAddModal}
            closeModal={this.closeAddModal}
            contentLabel="Add To Campaign Form"
          />
        )}
      </div>
    );
  }
}

export default onClickOutside(Groups);
