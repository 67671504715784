import React from "react";
import Select from "react-select";
import * as APIUtil from "../../api_util";
import AddImage from "images/add@2x.png";
import MagnifyGlassImage from "images/magnify-glass-dark@2x.png";
import CreateModal from "../CreateModal";
import SearchInput from "../SearchInput";
import Button from "../Controls/Button";

class GroupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brand: null,
      client: null,
      name: "",
      brands: [],
      clients: [],
      errors: [],
      groupInput: "",
      mounted: false,
      error: "",
      influencersResult: [],
      selectedInfluencers: [],
      clientModalIsOpen: false,
      brandModalIsOpen: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.fetchClients = this.fetchClients.bind(this);
    this.fetchBrands = this.fetchBrands.bind(this);
    this.getCurrentClientId = this.getCurrentClientId.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);
    this.openClientModal = this.openClientModal.bind(this);
    this.closeClientModal = this.closeClientModal.bind(this);
    this.openBrandModal = this.openBrandModal.bind(this);
    this.closeBrandModal = this.closeBrandModal.bind(this);
    this.handleInfluencerSearchSubmit = this.handleInfluencerSearchSubmit.bind(
      this
    );
    this.createClient = this.createClient.bind(this);
    this.createBrand = this.createBrand.bind(this);
  }

  fetchClients(newClient) {
    if (typeof newClient === "undefined") {
      APIUtil.fetchClients().then(clients => {
        this.setState({ clients });
      });
    } else {
      APIUtil.fetchClients().then(clients => {
        this.setState({
          clients: clients,
          client: {
            label: newClient.name,
            value: newClient.id
          }
        });
      });
    }
  }

  fetchBrands(newBrand) {
    if (typeof newBrand === "undefined") {
      APIUtil.fetchBrands().then(brands => {
        this.setState({ brands });
      });
    } else {
      APIUtil.fetchBrands(this.state.client).then(brands => {
        this.setState({
          brands: brands,
          brand: {
            label: newBrand.name,
            value: newBrand.id
          }
        });
      });
    }
  }

  getCurrentClientId() {
    if (this.state.client != null) {
      return this.state.client.value;
    } else {
      return null;
    }
  }

  componentDidMount() {
    const { isFormTypeEdit, group } = this.props;
    this.fetchClients();
    this.fetchBrands();
    if (isFormTypeEdit && group) {
      let brand, client, name, selectedInfluencers;
      if (group.client) {
        client = this.getGroupSelectObject("client");
      }
      if (group.brand) {
        brand = this.getGroupSelectObject("brand");
      }
      name = group.name;
      selectedInfluencers = group.influencers;
      this.setState({
        client,
        brand,
        name,
        selectedInfluencers
      });
    }
    this.setState({ mounted: true });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.mounted &&
        this.state.mounted &&
        ((prevState.client &&
          prevState.client.value &&
          this.state.client &&
          this.state.client.value &&
          prevState.client.value !== this.state.client.value) ||
          (!prevState.client &&
            this.state.client &&
            this.state.client.value))) ||
      (prevState.client && !this.state.client)
    ) {
      this.setState({ brand: null }, () => {
        APIUtil.fetchBrands(this.state.client).then(brands => {
          this.setState({ brands, brand: null });
        });
      });
    }
    if (
      !prevState.mounted &&
      this.state.mounted &&
      this.state.client &&
      this.state.client.value
    ) {
      APIUtil.fetchBrands(this.state.client).then(brands => {
        this.setState({ brands });
      });
    }
  }

  openClientModal() {
    this.setState({ clientModalIsOpen: true });
  }
  closeClientModal() {
    this.setState({ clientModalIsOpen: false });
  }
  openBrandModal() {
    this.setState({ brandModalIsOpen: true });
  }
  closeBrandModal() {
    this.setState({ brandModalIsOpen: false });
  }

  getGroupSelectObject(key) {
    const { group } = this.props;
    if (group) {
      return { label: group[key].name, value: group[key].id };
    }
  }

  getOptions(key) {
    const options = this.state[key].map(value => {
      return { value: value.id, label: value.name };
    });
    return options;
  }

  handleSelectChange(category) {
    return selectedOption => {
      this.setState({ [category]: selectedOption });
    };
  }

  handleInfluencerSearchSubmit(query) {
    APIUtil.searchInfluencers(query).then(response => {
      this.setState({ influencersResult: response.influencers });
    });
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  handleSubmit(e) {
    const { name, client, brand, selectedInfluencers } = this.state;
    const { isFormTypeEdit, currentUser } = this.props;
    let influencerIds;
    e.preventDefault();
    e.stopPropagation();
    if (!name) {
      this.setState({ error: "Please enter group name." });
      return;
    }
    const group = {
      name
    };
    if (client && client.value) {
      group.client_id = client.value;
    } else {
      group.client_id = null;
    }
    if (brand && brand.value) {
      group.brand_id = brand.value;
    } else {
      group.brand_id = null;
    }
    if (client && client.value && client.value === null) {
      delete group.client_id;
    }
    if (currentUser && currentUser.id) {
      group.creator_id = currentUser.id;
    }
    influencerIds = [];
    selectedInfluencers.forEach(i => {
      influencerIds.push(i.id);
    });
    // Rails workaround for empty array
    if (influencerIds.length == 0) {
      influencerIds = [""];
    }
    group.influencers = influencerIds;
    if (isFormTypeEdit && this.props.group) {
      APIUtil.updateGroup(group, this.props.group.id).then(
        response => {
          window.location = `/groups/${this.props.group.id}`;
        },
        errors => {
          this.setState({ errors: errors });
        }
      );
    } else {
      APIUtil.createGroup(group).then(
        ({group}) => {
          window.location = `/groups/${group.id}`;
        },
        errors => {
          this.setState({ errors: errors });
        }
      );
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <span className="error-message">{this.state.error}</span>;
    } else if (this.state.errors && this.state.errors.length > 0) {
      return <span className="error-message">{this.state.errors[0]}</span>;
    }
  }

  renderGroupSettingsHeader() {
    if (this.props.isFormTypeEdit) {
      return (
        <div className="flex s-btwn">
          <h1>GROUP SETTINGS</h1>
          <div className="edit-buttons-container">
            {this.renderErrors()}
            <Button onClick={this.handleSubmit}>SAVE</Button>
            <Button onClick={this.deleteGroup}>Delete Group</Button>
          </div>
        </div>
      );
    }
  }

  deleteGroup() {
    const { group } = this.props;
    APIUtil.deleteGroups([group.id]).then(
      group => {
        window.location = "/groups";
      },
      errors => {
        this.setState({ errors: errors });
      }
    );
  }

  createClient(clientState) {
    const client = {
      name: clientState.name
    };
    APIUtil.createClient(client).then(response => {
      this.fetchClients(response);
      this.closeClientModal();
    });
  }

  createBrand(brandState) {
    const brand = {
      name: brandState.name,
      client_id: this.getCurrentClientId
    };
    APIUtil.createBrand(brand).then(response => {
      this.fetchBrands(response);
      this.closeBrandModal();
    });
  }

  render() {
    const { isFormTypeEdit } = this.props;
    return (
      <div className="campaign-form-container">
        <div className="campaign-form">
          {this.renderGroupSettingsHeader()}
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label>GROUP NAME</label>
              <input
                className="campaign-name__input"
                type="text"
                value={this.state.name}
                onChange={this.handleNameChange}
              />
              <label>Client (optional)</label>
              <Select
                className="select-container client-brand"
                classNamePrefix="select"
                options={this.getOptions("clients")}
                value={this.state.client}
                onChange={this.handleSelectChange("client")}
                isClearable={true}
                backspaceRemovesValue={false}
              />
              <div className="add-client-brand">
                <Button onClick={this.openClientModal}>Add Client</Button>
              </div>
              <label>Brand (optional)</label>
              <Select
                className="select-container client-brand"
                classNamePrefix="select"
                options={this.getOptions("brands")}
                value={this.state.brand}
                onChange={this.handleSelectChange("brand")}
                isClearable={true}
                backspaceRemovesValue={false}
              />
              <div className="add-client-brand">
                <Button onClick={this.openBrandModal}>Add Brand</Button>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label>ADD INFLUENCER(S)</label>
              <SearchInput
                options={this.state.influencersResult}
                setSelectedOptions={selectedInfluencers =>
                  this.setState({ selectedInfluencers })
                }
                selectedOptions={this.state.selectedInfluencers}
                onRef={ref => (this.searchInfluencerInput = ref)}
                isInfluencer
                handleSearch={this.handleInfluencerSearchSubmit}
              />
            </div>
          </div>
          {!isFormTypeEdit && (
            <React.Fragment>
              <div>{this.renderErrors()}</div>
              <Button onClick={this.handleSubmit}>SAVE</Button>
            </React.Fragment>
          )}
        </div>
        <CreateModal
          isOpen={this.state.clientModalIsOpen}
          onRequestClose={this.closeClientModal}
          closeModal={this.closeClientModal}
          contentLabel="Client Create Form"
          type="CLIENT"
          handleCreate={this.createClient}
        />
        <CreateModal
          isOpen={this.state.brandModalIsOpen}
          onRequestClose={this.closeBrandModal}
          closeModal={this.closeBrandModal}
          contentLabel="Brand Create Form"
          type="BRAND"
          handleCreate={this.createBrand}
        />
      </div>
    );
  }
}

export default GroupForm;
