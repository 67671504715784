import React from "react";
import { prettifyDate, prettyCurrency } from "../../../util";
import Table from "../../campaigns/Table/Table";

class TableBillsDue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  columns() {
    return [
      {
        Header: "RFP",
        accessor: "campaign_rfp",
        align: "align-left",
        width: "5%",
      },
      {
        Header: "Campaign",
        accessor: "campaign",
        align: "align-left",
        width: "9%",
      },
      {
        Header: "Bill Due",
        accessor: "due_at",
        align: "align-left",
        width: "4%",
        Cell: (props) => prettifyDate(props.value),
      },
      {
        Header: "Total Bill",
        accessor: "total",
        align: "align-left",
        width: "4%",
        Cell: (props) => prettyCurrency(props.value),
      },
      {
        Header: "Type",
        accessor: "campaign_type",
        align: "align-left",
        width: "7%",
      },
      {
        Header: "Campaign Manager",
        accessor: "campaign_manager",
        align: "align-left",
        width: "4%",
      },
      {
        Header: "Terms",
        accessor: "terms",
        align: "align-left",
        width: "5%",
      },
    ];
  }

  render() {
    console.log(this.props)
    return (
      <div>
        {this.props.bills.length ? (
          <div>
            <div class="view-header">
              <div class="view-content">BILLS DUE</div>
            </div>
            <div className="report-bills-due">
              <Table columns={this.columns()} data={this.props.bills} hideFooter={true} />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
};

export default TableBillsDue;
